import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.translation.json';
import pl from './locales/pl.translation.json';
import zh_CN from './locales/zh_CN.translation.json';
import zh_TW from './locales/zh_TW.translation.json';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
    zh_CN: {
      translation: zh_CN,
    },
    zh_TW: {
      translation: zh_TW,
    },
  },
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
