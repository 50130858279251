import React, { useContext, useMemo, useState } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import { ValueType } from '@src/screens/routette/types';
import { View, Pressable, Text } from 'react-native';
import { BoardContext } from '../../provider/board';
import { useBetService } from '@src/store/hooks';
import ChipToken from '../mask-chip/chips';
import WinningMark from './winning-mark';
import useStyles from './styles.css';
import Diamond from './Diamond';

const getBlackRed = (type: number) => {
  return type === ValueType.BLACK || type === ValueType.RED;
};

const FirstItem = ({ item, isFirst, isLast }: any) => {
  const styles = useStyles();
  const isBlack = item.type === ValueType.BLACK;
  const [selfFocus, setSelFocus] = useState(false);
  const { onHoverItem, onPlaceBet } = useBetService();
  const isBlackRed = useMemo(() => getBlackRed(item.type), [item.type]);
  const { box_width, box_height } = useContext(BoardContext);
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem(item);
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  return (
    <View
      style={[
        styles.v_item_container,
        {
          width: box_width * 2,
          height: box_height,
        },
        isFirst && styles.borderTopLeft,
        isLast && styles.borderBottomLeft,
      ]}
    >
      <Pressable
        style={[
          styles.btn_item,
          isFirst && styles.borderTopLeft,
          isLast && styles.borderBottomLeft,
          selfFocus && styles.active_style,
        ]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          {isBlackRed ? (
            <Diamond color={isBlack ? 'black' : 'red'} />
          ) : (
            <Text
              allowFontScaling
              numberOfLines={1}
              selectable={false}
              style={[styles.text, { fontSize: 0.47 * box_height }]}
            >
              {item.placeholder}
            </Text>
          )}
          <ChipToken cell={item} BoxSize={box_height} />
        </View>
      </Pressable>
      {showWinStatus && item.valueSplit.includes(winNumber) && (
        <WinningMark
          {...{
            isFirst,
            isLast,
            animateStyle: {
              width: box_width * 2,
              height: box_height,
            },
          }}
        />
      )}
    </View>
  );
};

export default FirstItem;
