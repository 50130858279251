import React, { useContext } from 'react';
import { FlatList, View } from 'react-native';
import { MaskChipData } from '../../mockdata/table';
import { BoardContext } from '../../provider/board';
import useStyles from './styles.css';
import Items from './Items';

export const numColumns = 24;

const MaskChip = () => {
  const styles = useStyles();
  const { box_width, box_height } = useContext(BoardContext);

  return (
    <View
      style={[
        styles.container,
        {
          top: box_height * 0.25,
          right: box_width * 0.25,
        },
      ]}
    >
      <FlatList
        data={MaskChipData}
        numColumns={numColumns}
        showsVerticalScrollIndicator={false}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item, index }) => {
          return <Items key={index.toString()} index={index} item={item} />;
        }}
      />
    </View>
  );
};

export default MaskChip;
