import { all, fork } from 'redux-saga/effects';

import authWatcherSaga from './auth.sagas';
import rouletteWatcherSaga from './roulette.sagas';

function* rootSaga() {
  yield all([fork(authWatcherSaga), fork(rouletteWatcherSaga)]);
}

export default rootSaga;
