import React, { useContext, useState } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import WinningMark from '../first-column/winning-mark';
import { useBetService } from '@src/store/hooks';
import { View, Pressable } from 'react-native';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import ChipToken from '../mask-chip/chips';
import { TableContext } from '../provider';
import useStyles from './styles.css';

const SecondItem = ({ item }: any) => {
  const styles = useStyles();
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const screenSize = useContext(TableContext);
  const [selfFocus, setSelFocus] = useState(false);
  const winNumber = useAppSelector(selectWinNumber);
  const { onHoverItem, onPlaceBet } = useBetService();
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem(item);
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  React.useEffect(() => {
    boxWidth.value = withTiming(screenSize.box_width, { duration: 500 });
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      width: boxWidth.value,
      height: boxHeight.value * 4,
    };
  }, []);

  const textStyle = useAnimatedStyle(() => {
    return {
      fontSize: 0.47 * boxHeight.value,
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.v_item_container,
        animateStyle,
        selfFocus && styles.active_style,
      ]}
    >
      <Pressable
        style={[styles.btn_item]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <Animated.Text
            allowFontScaling
            numberOfLines={1}
            selectable={false}
            style={[styles.text, textStyle]}
          >
            {item.placeholder}
          </Animated.Text>
          <ChipToken cell={item} boxSize={screenSize.box_width} />
        </View>
      </Pressable>

      {showWinStatus && item.valueSplit.includes(winNumber) && (
        <WinningMark
          {...{
            animateStyle,
          }}
        />
      )}
    </Animated.View>
  );
};

export default SecondItem;
