import React from 'react';
import HoverProvider from './hover-provider';
import RacetrackProvider from './provider';
import MainScreen from './screen';

const Racetrack = () => {
  return (
    <RacetrackProvider>
      <HoverProvider>
        <MainScreen />
      </HoverProvider>
    </RacetrackProvider>
  );
};

export default Racetrack;
