import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

export const CANVAS_WIDTH = 491.67;
export const CANVAS_HEIGHT = 167.22;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    },
    disable: { display: 'none' },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
