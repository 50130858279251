import React from 'react';
import { View, FlatList } from 'react-native';
import { FirstColumnData } from '@src/utils/mockdata/Table';
import FirstItem from './FirstItem';

const FirstColumn = () => {
  return (
    <View>
      <FlatList
        scrollEnabled={false}
        data={FirstColumnData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => {
          return (
            <FirstItem
              key={index.toString()}
              item={item}
              isFirst={index === 0}
              isLast={index === FirstColumnData.length - 1}
            />
          );
        }}
      />
    </View>
  );
};

export default FirstColumn;
