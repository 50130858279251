// DUCKS pattern
import { createAction, createSlice, nanoid } from '@reduxjs/toolkit';
import { PlacedChip, Item, ValueType } from '@src/screens/routette/types';
import type { RootState } from '@src/store';
import { BetSave } from '../types';
import _ from 'lodash';

export interface BetState {
  betAmount: number;
  totalbet: number;
  chipmask: any;
  chiptotal: any;
  hoverItem: Item;
  minBet: number;
  maxBet: number;
  isMaxReach: boolean;
  error: string;
}

const initialState: BetState = {
  betAmount: 1,
  totalbet: 0,
  chipmask: [],
  chiptotal: new Map(),
  hoverItem: {},
  minBet: 0,
  maxBet: 100,
  isMaxReach: false,
} as BetState;

// Slice
export const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    setMinMaxBet: (state, action) => {
      state.minBet = Number(action.payload.minBet);
      state.maxBet = Number(action.payload.maxBet);
    },

    betAmount: (state, action) => {
      state.betAmount = action.payload;
    },

    onPlaceBet: (state, action) => {
      let sum = 0;
      const item = action.payload;
      const maxBet = state.maxBet;
      const totalbet = state.totalbet + state.betAmount;
      let chiptotal = state.chiptotal;
      let chipmask: any = state.chipmask;

      if (totalbet > maxBet) {
        return;
      }

      if (['searies58', 'orphelins', 'series023', 'spiel'].includes(item.key)) {
        item.output.forEach((value: any) => {
          let currentChip = {} as PlacedChip;
          currentChip.item = value;
          currentChip.total =
            value.type === ValueType.QUAD_SPLIT
              ? state.betAmount * 2
              : state.betAmount;

          if (chiptotal.get(value.key) !== undefined) {
            currentChip.total += chiptotal.get(value.key).total;
          }
          chiptotal.set(value.key, currentChip);
        });
      } else if (item.key === 'racetrackno') {
        item.output.forEach((value: any) => {
          let currentChip = {} as PlacedChip;
          currentChip.item = value;
          currentChip.total = state.betAmount;

          if (chiptotal.get(value.key) !== undefined) {
            currentChip.total += chiptotal.get(value.key).total;
          }
          chiptotal.set(value.key, currentChip);
        });
      } else {
        let currentChip = {} as PlacedChip;
        currentChip.item = item;
        currentChip.total = state.betAmount;

        if (chiptotal.get(item.key) !== undefined) {
          currentChip.total += chiptotal.get(item.key).total;
        }
        chiptotal.set(item.key, currentChip);
      }

      chiptotal.forEach((value: any) => {
        sum += value.total;
      });
      chipmask.push({ id: nanoid(), bet: state.betAmount, item });

      state.totalbet = sum;
      state.chipmask = chipmask;
      state.chiptotal = chiptotal;
      state.isMaxReach = false;
    },

    onDoubleBet: (state) => {
      let totalbet = 0;
      let maxBet = state.maxBet;
      let chiptotal = state.chiptotal;
      let chipmask: any = state.chipmask;
      const doubleTotal = state.totalbet * 2;

      if (_.isEmpty(chipmask)) {
        return;
      }

      if (doubleTotal > maxBet) {
        return;
      }

      chiptotal.forEach((value: PlacedChip) => {
        let currentChip = value as PlacedChip;
        currentChip.total *= 2;
      });

      chiptotal.forEach((value: any) => {
        totalbet += value.total;
      });

      chipmask.push({ id: nanoid(), isDouble: true });

      state.totalbet = totalbet;
      state.chipmask = chipmask;
      state.chiptotal = chiptotal;
    },

    onUndoBet: (state, action) => {
      let totalbet = 0;
      let chiptotal = state.chiptotal;
      let chipmask: any = state.chipmask;

      if (_.isEmpty(chipmask)) {
        return;
      }

      const lastItem: any = action.payload;

      if (lastItem.isDouble) {
        chiptotal.forEach((value: PlacedChip) => {
          let currentChip = value as PlacedChip;
          currentChip.total /= 2;
        });
      } else if (
        ['searies58', 'orphelins', 'series023', 'spiel'].includes(
          lastItem.item.key
        )
      ) {
        lastItem.item.output.forEach((value: any) => {
          let currentChip = {} as PlacedChip;
          currentChip.item = value;
          currentChip.total = state.betAmount;
          let lastbet =
            value.type === ValueType.QUAD_SPLIT
              ? lastItem.bet * 2
              : lastItem.bet;

          if (chiptotal.get(value.key) !== undefined) {
            currentChip.total = chiptotal.get(value.key).total - lastbet;
          }

          if (currentChip.total === 0) {
            chiptotal.delete(value.key);
          } else {
            chiptotal.set(value.key, currentChip);
          }
        });
      } else if (lastItem.item.key === 'racetrackno') {
        lastItem.item.output.forEach((value: any) => {
          let currentChip = {} as PlacedChip;
          currentChip.item = value;
          currentChip.total = state.betAmount;

          if (chiptotal.get(value.key) !== undefined) {
            currentChip.total = chiptotal.get(value.key).total - lastItem.bet;
          }

          if (currentChip.total === 0) {
            chiptotal.delete(value.key);
          } else {
            chiptotal.set(value.key, currentChip);
          }
        });
      } else {
        let currentChip = {} as PlacedChip;
        currentChip.item = lastItem.item;
        currentChip.total = state.betAmount;

        if (chiptotal.get(lastItem.item.key) !== undefined) {
          currentChip.total =
            chiptotal.get(lastItem.item.key).total - lastItem.bet;
        }

        if (currentChip.total === 0) {
          chiptotal.delete(lastItem.item.key);
        } else {
          chiptotal.set(lastItem.item.key, currentChip);
        }
      }

      chipmask.splice(-1);
      chiptotal.forEach((value: any) => {
        totalbet += value.total;
      });

      state.totalbet = totalbet;
      state.chipmask = chipmask;
      state.chiptotal = chiptotal;
      state.isMaxReach = false;
    },

    onDeleteAllBet: (state) => {
      const chipmask: any = state.chipmask;

      if (_.isEmpty(chipmask)) {
        return;
      }

      state.totalbet = 0;
      state.chipmask = [];
      state.chiptotal.clear();
      state.isMaxReach = false;
    },

    onHoverItem: (state, action) => {
      state.hoverItem = action.payload;
    },

    onSetError: (state, action) => {
      state.error = action.payload;
    },

    saveBets: () => {},
  },
});

// Actions
export const betActions = {
  setMinMaxBet: betSlice.actions.setMinMaxBet,
  betAmount: betSlice.actions.betAmount,
  onPlaceBet: betSlice.actions.onPlaceBet,
  onDoubleBet: betSlice.actions.onDoubleBet,
  onUndoBet: betSlice.actions.onUndoBet,
  onDeleteAllBet: betSlice.actions.onDeleteAllBet,
  onHoverItem: betSlice.actions.onHoverItem,
  onSetError: betSlice.actions.onSetError,
  saveBets: createAction(`${betSlice.name}/saveBets`, (params: BetSave) => ({
    payload: params,
  })),
};

// Selectors
export const selectedBetAmount = (state: RootState) => state.bet.betAmount;
export const selectedChipTotal = (state: RootState) => state.bet.chiptotal;
export const selectedChipMask = (state: RootState) => state.bet.chipmask;
export const selectedTotalBet = (state: RootState) => state.bet.totalbet;
export const selectedHoverItem = (state: RootState) => state.bet.hoverItem;
export const selectedErrorMessage = (state: RootState) => state.bet.error;

// Reducer
export default betSlice.reducer;
