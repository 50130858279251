// DUCKS pattern
import type { RootState } from '@src/store';
import { createSlice } from '@reduxjs/toolkit';

interface StateValue {
  orientation: string;
}

const initialState: StateValue = {
  orientation: 'portrait',
};

// Slice
export const appstateSlice = createSlice({
  name: 'appstate',
  initialState,
  reducers: {
    setOrientation(state, action) {
      state.orientation = action.payload;
    },
  },
});

// Actions
export const appstateActions = {
  setOrientation: appstateSlice.actions.setOrientation,
};

// Selectors
export const selectedOrientation = (state: RootState) =>
  state.appstate.orientation === 'landscape';

// Reducer
export default appstateSlice.reducer;
