import React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import useStyles from './styles.css';
import Language from './language';
import Header from './header';
import Login from './login';
// import Girl from './Girl';

const SignIn = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <SafeAreaView />
      <Header />
      <View style={styles.v_body}>
        <Login />
      </View>
      <Language />
      <View style={styles.v_height} />
    </View>
  );
};

export default SignIn;
