import React from 'react';
import { ConvertedChips } from '@src/utils/mockdata/Chips';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedChipTotal } from '@src/store/slices';
import LocalImage from './Image';

const Token = ({ x, y, cellkey, size }: any) => {
  const chiptotal = useAppSelector(selectedChipTotal);

  const { amount, source } = React.useMemo(() => {
    const currentItemChips = chiptotal.get(cellkey);
    let computed = 0;

    if (currentItemChips !== undefined) {
      if (currentItemChips.total !== 0) {
        computed = currentItemChips.total;
      }
    }

    const chip_source = ConvertedChips(computed);

    return { amount: computed, source: chip_source };
  }, [chiptotal]);

  if (amount <= 0) {
    return null;
  }

  return (
    <LocalImage
      amount={amount}
      x={x}
      y={y}
      width={size}
      height={size}
      src={source}
    />
  );
};

export default Token;
