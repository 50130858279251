import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    v_flex_row: { flex: 1, flexDirection: 'row' },
  },
  {
    [DEVICE_SIZES.MD]: {},
  }
);

export default useStyles;
