import React, { useState, useCallback, createContext } from 'react';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import { selectStopBetting } from '@root/src/store/slices';

interface ChipContextValue {
  showChips: boolean;
  onChangeShowChips: () => void;
}

const ChipContext = createContext<ChipContextValue>({
  showChips: false,
  onChangeShowChips: () => {},
});

interface ChipProviderProps {
  children: React.ReactElement;
}

const ChipProvider = ({ children }: ChipProviderProps) => {
  const [showChips, setShowChips] = useState(false);
  const stopBetting = useAppSelector(selectStopBetting);

  const onChangeShowChips = useCallback(() => {
    setShowChips(!showChips);
  }, [showChips]);

  React.useEffect(() => {
    if (stopBetting) {
      setShowChips(false);
    }
  }, [showChips, stopBetting]);

  const value = {
    showChips,
    onChangeShowChips,
  };

  return <ChipContext.Provider value={value}>{children}</ChipContext.Provider>;
};

export { ChipProvider, ChipContext };
