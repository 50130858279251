import React, { useMemo, createContext } from 'react';
import { multiply, divide, subtract } from '@src/utils/computation-helper';
import { TOP_HEIGHT } from '../../top/win-records/styles.css';
import { BOTTOM_HEIGHT } from '../../../bottom/styles.css';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectStopBetting } from '@src/store/slices';
import { useWindowDimensions } from 'react-native';

const offset = TOP_HEIGHT + BOTTOM_HEIGHT + 100;

interface ContextValue {
  box_width: number;
  box_height: number;
}

const TableContext = createContext<ContextValue>({} as ContextValue);

interface BoardProviderProps {
  children: React.ReactElement;
}

const TableProvider = ({ children }: BoardProviderProps) => {
  const { width, height } = useWindowDimensions();
  const stopBetting = useAppSelector(selectStopBetting);

  const value = useMemo(() => {
    const smallScreen = multiply(multiply(height, 0.54), 0.8);
    const bigScreen = subtract(height, offset);

    let canvas_width = multiply(width, 0.79);
    let canvas_height = bigScreen;
    let box_width = divide(canvas_width, 6.5);
    let box_height = divide(canvas_height, 14);

    // let canvas_width = multiply(smallScreen, 0.7);
    // let canvas_height = multiply(bigScreen, 0.68);
    // let box_width = divide(canvas_width, 6.5);
    // let box_height = divide(canvas_height, 14);

    if (stopBetting) {
      canvas_width = multiply(smallScreen, 0.7);
      canvas_height = multiply(bigScreen, 0.68);
      box_width = divide(canvas_width, 6.5);
      box_height = divide(canvas_height, 14);
    }

    return {
      box_width,
      box_height,
    };
  }, [stopBetting, width, height]);

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export { TableProvider, TableContext };
