import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    v_main_wrapper: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    disable: { display: 'none' },
    container: {
      // flex: 1,
    },
    v_body: {
      // flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },

    // ZERO STYLES
    v_zero: {
      right: 0,
      borderWidth: 1,
      alignSelf: 'flex-end',
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      borderColor: colors.transparent,
    },
    active_style: { borderWidth: 2, borderColor: colors.white },
    v_zero_center: {
      flex: 1,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      borderColor: colors.brown2,
      backgroundColor: colors.green,
    },
    v_wrap_text: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    zero_text: {
      fontSize: 12,
      color: colors.white,
      fontFamily: 'Roboto-Bold',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
