import { Asset } from 'expo-asset';
import { ImageRequireSource } from 'react-native';
interface imageType {
  // AUTH
  girl: ImageRequireSource;
  'home-bg': ImageRequireSource;
  'login-box': ImageRequireSource;
  'login-button': ImageRequireSource;
  ctg_logo: ImageRequireSource;
  logo_header: ImageRequireSource;
  mask_bg1: ImageRequireSource;
  mask_bg2: ImageRequireSource;

  // LOBBY
  'btn-bg': ImageRequireSource;
  'btn-logout': ImageRequireSource;
  'lobby-bg': ImageRequireSource;
  'name-bg': ImageRequireSource;
  cb_language: ImageRequireSource;
  'table-lobby': ImageRequireSource;

  // ROULETT
  'roulette-bg': ImageRequireSource;
  'icon-gear': ImageRequireSource;
  'flags-en': ImageRequireSource;
  'flags-cn': ImageRequireSource;
  'flags-th': ImageRequireSource;
  'bet-red': ImageRequireSource;
  'bet-black': ImageRequireSource;
  'chip-1': ImageRequireSource;
  'chip-5': ImageRequireSource;
  'chip-25': ImageRequireSource;
  'chip-100': ImageRequireSource;
  ic_table: ImageRequireSource;
  ic_racetrack: ImageRequireSource;
  diamond_red: ImageRequireSource;
  diamond_black: ImageRequireSource;
}

const images: imageType = {
  // AUTH
  girl: require('./auth/girl.png'),
  'home-bg': require('./auth/home-bg.png'),
  'login-box': require('./auth/login-box.png'),
  'login-button': require('./auth/login-button.png'),
  ctg_logo: require('./auth/logo.png'),
  logo_header: require('./auth/logo_header.png'),
  mask_bg1: require('./auth/mask_bg1.png'),
  mask_bg2: require('./auth/mask_bg2.png'),

  // LOBBY
  'btn-bg': require('./lobby/btn-bg.png'),
  'btn-logout': require('./lobby/btn-logout.png'),
  'lobby-bg': require('./lobby/lobby-bg.jpg'),
  'name-bg': require('./lobby/name-bg.png'),
  cb_language: require('./lobby/cb_language.png'),
  'table-lobby': require('./lobby/table-lobby.png'),

  // ROULETT
  'roulette-bg': require('./roulette/roulette-bg.png'),
  'icon-gear': require('./roulette/icon-gear.png'),
  'flags-en': require('./roulette/flags-en.png'),
  'flags-cn': require('./roulette/flags-cn.png'),
  'flags-th': require('./roulette/flags-th.png'),
  'bet-red': require('./roulette/bet-red.png'),
  'bet-black': require('./roulette/bet-black.png'),
  'chip-1': require('./roulette/chip-1.png'),
  'chip-5': require('./roulette/chip-5.png'),
  'chip-25': require('./roulette/chip-25.png'),
  'chip-100': require('./roulette/chip-100.png'),
  ic_table: require('./roulette/ic_table.png'),
  ic_racetrack: require('./roulette/ic_racetrack.png'),
  diamond_red: require('./roulette/diamond/diamond_red.png'),
  diamond_black: require('./roulette/diamond/diamond_black.png'),
};

// image preloading
export const imageAssets = Object.keys(images).map((key) =>
  Asset.fromModule(images[key as keyof imageType]).downloadAsync()
);
export default images;
