import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

const TranslateYAnimated = ({
  children,
  visible,
  style,
  duration,
  outputRange,
}: any) => {
  const animated = useRef(new Animated.Value(0)).current;

  const translateX = animated.interpolate({
    inputRange: [0, 1],
    outputRange: outputRange || [0, 500],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    Animated.timing(animated, {
      toValue: visible ? 1 : 0,
      duration: duration || 300,
      useNativeDriver: false,
    }).start();
  }, [visible]);

  return (
    <Animated.View
      style={[
        style,
        {
          transform: [{ translateX }],
        },
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default TranslateYAnimated;
