/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Image, View } from 'react-native';
import BoardProvider from './provider/board';
import RacetrackProvider from './provider/racetrack';
import useStyles from './styles.css';
import images from '@assets/images';
import Board from './board';
import Racetrack from './racetrack';
import Test from './racetrack/layer-middle/text';

function Tables() {
  const styles = useStyles();

  return (
    <BoardProvider>
      <RacetrackProvider>
        <View style={styles.table_container}>
          <Racetrack />
          <View style={{ height: 20 }} />
          <Board />
          <Image
            style={styles.i_mask}
            source={images.mask_bg2}
            resizeMode="contain"
          />
        </View>
      </RacetrackProvider>
    </BoardProvider>
  );
}

export default Tables;
