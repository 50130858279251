import React from 'react';
import { View } from 'react-native';
import RightPanel from './right-panel';
import useStyles from './styles.css';
import LeftPanel from './left-panel';

const Roulette = () => {
  const styles = useStyles();

  return (
    <View style={styles.v_flex_row}>
      <LeftPanel />
      <RightPanel />
    </View>
  );
};

export default Roulette;
