import React, { useState, useCallback, createContext } from 'react';
import * as Flashphoner from '@flashphoner/websdk';
import Config from '@root/src/utils/Config';

export const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
export const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;

interface ContextValue {
  session: any;
  camera: number;
  showSettings: boolean;
  onRefreshStreaming: () => void;
  onChangeSettingsState: () => void;
  onChangeCameraState: (val: number) => void;
}

export const LeftPanelContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const LeftPanelProvider = ({ children }: ProviderProps) => {
  const [camera, setCamera] = useState<number>(1);
  const [session, setSession] = useState<any>(null);
  const [showSettings, setShowSettings] = useState(false);

  const onChangeCameraState = useCallback(
    (val: number) => {
      console.log('val', val);
      setCamera(val);
    },
    [camera]
  );

  const onChangeSettingsState = useCallback(() => {
    setShowSettings(!showSettings);
  }, [showSettings]);

  const onRefreshStreaming = useCallback(() => {
    session && session.disconnect();
  }, [session]);

  const value = {
    camera,
    session,
    showSettings,
    onRefreshStreaming,
    onChangeSettingsState,
    onChangeCameraState,
  };

  const connect = () => {
    Flashphoner.createSession({ urlServer: Config.API_STREAM_URL })
      .on(SESSION_STATUS.ESTABLISHED, (result: any) => {
        setSession(result);
      })
      .on(SESSION_STATUS.DISCONNECTED, () => {
        setSession(null);
        connect();
      })
      .on(SESSION_STATUS.FAILED, () => {
        setSession(null);
        connect();
      });
  };

  React.useEffect(() => {
    onRefreshStreaming();
  }, [camera]);

  React.useEffect(() => {
    try {
      Flashphoner.init({});
      connect();
    } catch (error) {
      console.log('Flashphoner error', error);
    }
  }, []);

  return (
    <LeftPanelContext.Provider value={value}>
      {children}
    </LeftPanelContext.Provider>
  );
};

export default LeftPanelProvider;
