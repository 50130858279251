import React, { useContext } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import { View, Pressable, Text } from 'react-native';
import { ValueType } from '@src/screens/routette/types';
import { selectedHoverItem } from '@src/store/slices';
import { BoardContext } from '../../provider/board';
import { Black } from '@src/utils/mockdata/Table';
import { useBetService } from '@src/store/hooks';
import WinningMark from './winning-mark';
import useStyles from './styles.css';

const ThirdItem = ({ item }: any) => {
  const styles = useStyles();
  const isFirst = item.type === ValueType.STREET_1_12;
  const isLast = item.type === ValueType.STREET_3_12;
  const hoverItem = useAppSelector(selectedHoverItem);
  const { onHoverItem, onPlaceBet } = useBetService();
  const { box_width, box_height } = useContext(BoardContext);
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem(item);
  };

  const onHoverOut = () => {
    onHoverItem({});
  };

  const isEven = React.useMemo(() => {
    return Black.includes(item.value);
  }, [item]);

  const isFocus = React.useMemo(() => {
    if (hoverItem.value && item.value) {
      return hoverItem.value === item.value;
    }

    if (hoverItem.valueSplit) {
      return hoverItem.valueSplit.includes(item.value);
    }

    return false;
  }, [hoverItem, item]);

  return (
    <View
      style={[
        styles.v_item_container,

        isFirst && styles.radiusBottomLeft,
        isLast && styles.radiusBottomRight,
        {
          width: box_width,
          height: box_height + box_height / 2,
        },
      ]}
    >
      <Pressable
        style={[
          styles.btn_item,
          isEven && styles.bg_black,
          isFirst && styles.radiusBottomLeft,
          isLast && styles.radiusBottomRight,
          isFocus && styles.active_style,
        ]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <Text
            allowFontScaling
            numberOfLines={1}
            selectable={false}
            style={[
              styles.text,
              {
                fontSize: 0.55 * box_width,
              },
            ]}
          >
            {item.placeholder || item.value}
          </Text>
          {showWinStatus && winNumber === item.value && (
            <WinningMark boxHeight={box_width} />
          )}
        </View>
      </Pressable>
    </View>
  );
};

export default ThirdItem;
