import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { RootStackParamList, RootStackOptionsValue } from './NavigatorTypes';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectAuthLoggedIn } from '@src/store/slices';

// SCREEN
import SignIn from '@src/screens/auth/signin';
import WinNumber from '@src/components/popups/win-number';
import Roulette from '@src/screens/routette';
import TestWebsocket from './testwebsocket';
import TestWebsocket2 from './socket_connection_test';

const RootStack = createStackNavigator<RootStackParamList>();

const RootStackOptions: RootStackOptionsValue = {
  title: 'CTGaming',
  headerShown: false,
  cardStyle: { backgroundColor: 'transparent' },
};

const Routes = () => {
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const screenComponents = React.useMemo(() => {
    if (isLoggedIn) {
      return (
        <>
          <RootStack.Screen name="Roulette" component={Roulette} />
        </>
      );
    } else {
      return (
        <>
          <RootStack.Screen name="SignIn" component={SignIn} />
          {/* <RootStack.Screen name="Roulette" component={Roulette} /> */}
        </>
      );
    }
  }, [isLoggedIn]);

  return (
    <RootStack.Navigator screenOptions={RootStackOptions}>
      {screenComponents}
    </RootStack.Navigator>
  );
};

export default Routes;
