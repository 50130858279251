import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import IconButton from './IconButton';
import { RouletteContext } from '../../RouletteProvider';

const Settings = () => {
  const styles = useStyles();
  const { onCameraView } = React.useContext(RouletteContext);

  return (
    <View style={styles.container}>
      <IconButton onPress={onCameraView} name={'videocam'} />
    </View>
  );
};

export default Settings;
