import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { Dimensions } from 'react-native';
import { colors } from 'theme';

export const WINNING_HEIGHT = 25;
export const ADDITIONAL_HEIGHT = 15;
export const TOP_HEIGHT = 50;

const SCREEN_WIDTH = Dimensions.get('window').width;
const SIZE = 25;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '100%',
      height: TOP_HEIGHT,
      paddingHorizontal: 50,
      justifyContent: 'center',
    },
    v_wrapper: { width: '100%', height: SIZE },

    // ITEMS
    v_item: {
      width: SIZE,
      height: SIZE,
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_item_active: {
      width: SIZE,
      borderRadius: SIZE / 2,
      backgroundColor: colors.black,
    },
    t_number: {
      fontFamily: 'DMSans',
      color: colors.white,
      fontSize: 12,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
