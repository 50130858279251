import React, { createContext } from 'react';
import { pickNearest } from '@root/src/utils/mockdata/Racetrack';

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  hover: any;
  onSetHover: (value: any) => void;
}

export const HoverContext = createContext<ContextValue>({} as ContextValue);

const HoverProvider = ({ children }: ProviderProps) => {
  const [hover, setHover] = React.useState<any>({});
  const onSetHover = React.useCallback(
    (value: any) => {
      if (value.key && value.valueSplit) {
        setHover(value);
      } else if (value.key) {
        const result = pickNearest(value.key);
        setHover(result);
      } else {
        setHover(value);
      }
    },
    [hover]
  );

  return (
    <HoverContext.Provider value={{ hover, onSetHover }}>
      {children}
    </HoverContext.Provider>
  );
};

export default HoverProvider;
