import { Text, View } from 'react-native';
import Package from '@root/package.json';
import useStyles from './styles.css';
import React from 'react';

const Version = () => {
  const styles = useStyles();

  return (
    <View style={styles.drawer_content_footer}>
      <Text style={styles.copyRightStyle}>Version {Package.version}</Text>
    </View>
  );
};

export default Version;
