import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import Video from '../videoplayer/video';
import { RouletteContext } from '../RouletteProvider';
import { useAppStateService } from '@root/src/store/hooks';

const TopScreen = () => {
  const styles = useStyles();
  const { isLandscape } = useAppStateService();
  const { cameraview } = React.useContext(RouletteContext);

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.v_videoscreen,
          isLandscape && styles.v_fullscreen,
          cameraview && styles.displaynone,
        ]}
      >
        <Video active style={styles.absolute} id="remoteVideo" />
      </View>
      <View style={[styles.container_v2, isLandscape && styles.displaynone]}>
        <View style={styles.top_height} />
        <View style={styles.v_bigscreen} />
        <View style={styles.bottom_height} />
      </View>
    </View>
  );
};

export default TopScreen;
