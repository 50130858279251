import React from 'react';
import { Modal, View, Text } from 'react-native';
import { useAuthService } from '@src/store/hooks';
import useStyles from './styles.css';
import { Button01 } from '../button';

type SessionProps = {
  visible: boolean;
};

const SessionExpired = (props: SessionProps) => {
  const styles = useStyles();
  const { onLogout } = useAuthService();

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={'none'}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <View style={styles.container}>
        <View style={styles.v_modal}>
          <Text style={styles.t_expired}>Session expired</Text>
          <Button01
            label="Okay"
            onPress={onLogout}
            style={styles.btn_okay}
            labelStyle={styles.btn_label}
          />
        </View>
      </View>
    </Modal>
  );
};

export default SessionExpired;
