import React, { useMemo } from 'react';
import { View, Image } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedChipTotal } from '@src/store/slices';
import { ConvertedChips } from '@src/utils/mockdata/Chips';
import useStyles from './styles.css';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

const ChipToken = ({ cell, boxSize }: any) => {
  const styles = useStyles();
  const chiptotal = useAppSelector(selectedChipTotal);

  const { amount, source } = useMemo(() => {
    const currentItemChips = chiptotal.get(cell.key);
    let computed = 0;

    if (currentItemChips !== undefined) {
      if (currentItemChips.total !== 0) {
        computed = currentItemChips.total;
      }
    }

    const chip_source = ConvertedChips(computed);

    return { amount: computed, source: chip_source };
  }, [chiptotal]);

  const boxHeight = useSharedValue(0);

  React.useEffect(() => {
    boxHeight.value = withTiming(boxSize, { duration: 500 });
  }, [boxSize]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: boxHeight.value / 2,
      height: boxHeight.value / 2,
      borderRadius: boxHeight.value,
    };
  }, []);

  const textStyle = useAnimatedStyle(() => {
    return {
      fontSize: boxHeight.value * 0.3,
    };
  }, []);

  if (amount <= 0) {
    return null;
  }

  return (
    <Animated.View
      pointerEvents="none"
      style={[styles.v_chip_container, animatedStyle]}
    >
      <Image style={styles.i_chip} source={source} resizeMode="stretch" />
      <View style={[styles.v_bet_container]}>
        <Animated.Text
          selectable={false}
          style={[styles.t_bet_amount, textStyle]}
        >
          {amount}
        </Animated.Text>
      </View>
    </Animated.View>
  );
};

export default ChipToken;
