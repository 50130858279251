import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { betActions, selectedChipTotal } from '../slices/bet.slice';

// Types
import { selectUserID, selectedAuthToken, selectStopBetting } from '../slices';
import _ from 'lodash';

type ServiceOperators = {
  user: any;
  stopBetting: boolean;
  onSaveBets: (params: any) => void;
};

export const useSaveBetService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserID);
  const stopBetting = useAppSelector(selectStopBetting);
  const token = useAppSelector(selectedAuthToken);
  const chiptotal = useAppSelector(selectedChipTotal);

  return {
    user,
    stopBetting,
    onSaveBets: useCallback(
      (item: any) => {
        const params = {
          tableID: '3',
          ...item,
          ...user,
          isPlayerRequest: true,
        };
        params.postedBy = user.playerID;
        params.token = token;

        const betArray: any = [];
        chiptotal.forEach((value: any) => {
          betArray.push({
            key: value.item.key,
            type: value.item.type,
            value: value.item.value || value.item.valueSplit,
            betAmountTotal: value.total,
          });
        });
        if (!_.isEmpty(betArray)) {
          params.betArray = betArray;
          dispatch(betActions.saveBets(params));
        }
      },
      [dispatch, user, token, chiptotal]
    ),
  };
};

export default useSaveBetService;
