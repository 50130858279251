export enum ValueType {
  NUMBER = 0, // Single 0-36 = x36 Reward
  NUMBERS_1_12 = 1, // 1ST 12 = x3 Reward
  NUMBERS_2_12 = 2, // 2ST 12 = x3 Reward
  NUMBERS_3_12 = 3, // 3ST 12 = x3 Reward
  NUMBERS_1_3 = 4, // STRAIGHT Ex. 1,2,3 = x12 Reward
  NUMBERS_1_6 = 5, // SPLIT STRAIGHT Ex. 1,2,3,4,5,6 = x6 Reward
  STREET_1_12 = 6, // STREET STRAIGHT Ex. 3,6,9,12,15,18,21,24,27,30,33,36 = x3 Reward
  STREET_2_12 = 7, // STREET STRAIGHT Ex. 2,5,8,11,14,17,20,23,26,29,32,35 = x3 Reward
  STREET_3_12 = 8, // STREET STRAIGHT Ex. 1,4,7,10,13,16,19,22,25,28,31,34 = x3 Reward
  DOUBLE_SPLIT = 9, // SPLIT TRIPLE Ex 1,2 = x18 Reward
  QUAD_SPLIT = 11, // SPLIT QUADRUPLE = x9 Reward
  NUMBERS_1_18 = 12, // 1 - 18 = x2 Reward
  EVEN = 13, // EVEN NUMBERS = x2 Reward
  ODD = 14, // ODD NUMBERS = x2 Reward
  RED = 15, // ALL RED = x2 Reward
  BLACK = 16, // ALL BLACK = x2 Reward
  NUMBERS_19_36 = 17, // 19 - 36 = x2 Reward
}

export interface Item {
  key?: string;
  type?: ValueType;
  value?: number;
  valueSplit?: number[];
}

export interface PlacedChip {
  item: Item;
  // type: ValueType;
  // bet: number;
  total: number;
}

export type rouletteData = {
  numbers: number[];
};

export type RouletteWrapperState = {
  rouletteData: rouletteData;
  number: WheelNumber;
  chipsData: ChipsData;
  winners: Winner[];
  username: string;
  endTime: number;
  progressCountdown: number;
  time_remaining: number;
  stage: GameStages;
  history: number[];
};

export type Winner = {
  username: string;
  sum: number;
};

export type ChipsData = {
  selectedChip: any;
  placedChips: any;
};

export type WheelNumber = {
  next: any;
};

export enum GameStages {
  PLACE_BET,
  NO_MORE_BETS,
  WINNERS,
  NONE,
}
export type GameData = {
  stage: GameStages;
  time_remaining: number;
  value: number;
  wins: Winner[];
  history: number[];
};

export type Layout = {
  isActive: boolean;
  bigScreen: number;
  smallScreen: number;
  height: number;
  width: number;
  box_width: number;
  box_height: number;
};
