export const ChipImages: any = {
  // 0.15: require('@assets/images/roulette/chips/chip-0.15.png'),
  // 0.35: require('@assets/images/roulette/chips/chip-0.35.png'),
  // 0.75: require('@assets/images/roulette/chips/chip-0.75.png'),
  // 1.5: require('@assets/images/roulette/chips/chip-1.5.png'),
  // 3.5: require('@assets/images/roulette/chips/chip-3.5.png'),
  // 7.5: require('@assets/images/roulette/chips/chip-7.5.png'),
  // 15: require('@assets/images/roulette/chips/chip-15.png'),
  // 35: require('@assets/images/roulette/chips/chip-35.png'),
  // 75: require('@assets/images/roulette/chips/chip-75.png'),

  1: require('@assets/images/roulette/chip-1.png'),
  2: require('@assets/images/roulette/chips/chip-0.35.png'),
  5: require('@assets/images/roulette/chip-5.png'),
  10: require('@assets/images/roulette/chips/chip-1.5.png'),
  15: require('@assets/images/roulette/chips/chip-3.5.png'),
  20: require('@assets/images/roulette/chips/chip-7.5.png'),
  25: require('@assets/images/roulette/chip-25.png'),
  50: require('@assets/images/roulette/chips/chip-35.png'),
  75: require('@assets/images/roulette/chips/chip-75.png'),
  100: require('@assets/images/roulette/chip-100.png'),
};

export const ConvertedChips = (amount: number) => {
  if (amount >= 100) {
    return ChipImages[100];
  } else if (amount >= 25) {
    return ChipImages[25];
  } else if (amount >= 5) {
    return ChipImages[5];
  }
  return ChipImages[1];
};

export const NewChips = [
  {
    amount: 1,
    icon: require('@assets/images/roulette/chip-1.png'),
  },
  {
    amount: 5,
    icon: require('@assets/images/roulette/chip-5.png'),
  },
  {
    amount: 25,
    icon: require('@assets/images/roulette/chip-25.png'),
  },
  {
    amount: 100,
    icon: require('@assets/images/roulette/chip-100.png'),
  },
];

const Chips = [
  {
    amount: 1,
    icon: ChipImages[1],
  },
  {
    amount: 2,
    icon: ChipImages[2],
  },
  {
    amount: 5,
    icon: ChipImages[5],
  },
  {
    amount: 10,
    icon: ChipImages[10],
  },
  {
    amount: 15,
    icon: ChipImages[15],
  },
  {
    amount: 20,
    icon: ChipImages[20],
  },
  {
    amount: 25,
    icon: ChipImages[25],
  },
  {
    amount: 50,
    icon: ChipImages[50],
  },
  {
    amount: 75,
    icon: ChipImages[75],
  },
  {
    amount: 100,
    icon: ChipImages[100],
  },
];

export default Chips;
