import React, { useCallback, useEffect, useState } from 'react';
import { useUserService } from './store/hooks';
import SessionExpired from './components/popups/session-expired';
import { selectAuthLoggedIn } from './store/slices';
import { useAppSelector } from './store/hooks/hooks';

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  onErrorMessage: (value: string) => void;
}

export const AppContext = React.createContext<ContextValue>({} as ContextValue);

const AppProvider = ({ children }: AuthProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const { isSessionExpired, onFetchUserDetails } = useUserService();

  const onErrorMessage = useCallback(setError, [error]);

  useEffect(() => {
    if (isLoggedIn) {
      onFetchUserDetails();
    }
  }, [isLoggedIn]);

  return (
    <AppContext.Provider value={{ onErrorMessage }}>
      {children}
      <SessionExpired visible={isSessionExpired} />
    </AppContext.Provider>
  );
};

export default AppProvider;
