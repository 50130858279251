import colors from '@assets/colors';
import React from 'react';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import { Red } from '@src/utils/mockdata/Table';

const Item = ({ item, isActive }: any) => {
  const styles = useStyles();
  const isZero = item.winNumber === 0;
  const isRed = React.useMemo(() => {
    return Red.includes(item.winNumber);
  }, [item.winNumber]);

  const addStyle2 = [
    styles.v_item_active,
    isRed && { backgroundColor: colors.red },
  ];

  return (
    <View style={[styles.v_item, isActive && addStyle2]}>
      <Text
        style={[
          styles.t_number,
          isRed && !isActive && { color: colors.red },
          isZero && { color: colors.green },
        ]}
      >
        {item.winNumber}
      </Text>
    </View>
  );
};

export default Item;
