import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Splash from '@src/components/loading';

import Linking from './Linking';

import Routes from './Routes';

const AppNavigator = () => {
  return (
    <NavigationContainer linking={Linking} fallback={<Splash isLoading />}>
      <Routes />
    </NavigationContainer>
  );
};

export default AppNavigator;
