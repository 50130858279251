/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { NewChips } from '@src/utils/mockdata/Chips';
import { View, FlatList } from 'react-native';
import useStyles from './styles.css';
import ChipItem from './chip-item';

const ChipCoins = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <FlatList
        horizontal
        data={NewChips}
        showsVerticalScrollIndicator={false}
        keyExtractor={(_, index) => index.toString()}
        ItemSeparatorComponent={() => <View style={styles.v_separator} />}
        renderItem={({ item, index }) => {
          return <ChipItem key={index.toString()} item={item} />;
        }}
      />
    </View>
  );
};

export default ChipCoins;
