import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      backgroundColor: colors.colorPrimary,
    },
    flex1: {
      flex: 1,
      zIndex: 3,
      display: 'flex',
    },
    v_flex_row: { flex: 1, flexDirection: 'row' },
    v_body: {
      zIndex: 2,
      width: '100%',
      height: '100%',
    },
    v_login: {
      top: '8%',
      left: '4.5%',
      width: '42%',
      height: '84.62963%',
      minWidth: 450,
      minHeight: 470,
      marginBottom: '10%',
    },
    i_login_bg: { width: '100%', height: '100%', position: 'absolute' },
    i_logo: { width: '35%', height: 200 },
    v_login_middle: {
      zIndex: 2,
      width: '100%',
      height: '100%',
      paddingHorizontal: 50,
    },
    v_girl: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '60.46875%',
      height: '93.88889%',
    },
    i_girl: { width: '100%', height: '100%' },
    signInCenter: { width: '100%', alignItems: 'center', marginTop: 30 },
    v_field: { marginTop: 20 },
    t_support: {
      position: 'absolute',
      bottom: 30,
      fontSize: 16,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      textAlign: 'left',
      color: colors.white,
    },
    mt20: { height: 20 },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      v_login: {
        width: '91%',
        minWidth: 0,
      },
      v_login_middle: { paddingHorizontal: 100 },
      v_girl: { width: 300, height: 300, zIndex: 3 },
    },
    [DEVICE_SIZES.XS]: {
      v_login: {
        width: '91%',
        minWidth: 0,
      },
      v_login_middle: { paddingHorizontal: 20 },
      v_girl: { width: 200, height: 200, zIndex: 3 },
    },
  }
);

export default useStyles;
