import React, { useMemo, createContext } from 'react';
import { useWindowDimensions } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectStopBetting } from '@src/store/slices';
import { multiply, subtract } from '@src/utils/computation-helper';
import { TOP_HEIGHT } from '../../top/win-records/styles.css';
import { BOTTOM_HEIGHT } from '../../../bottom/styles.css';

const offset = TOP_HEIGHT + BOTTOM_HEIGHT + 100;

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  bigScreen: number;
  canvas_width: number;
  canvas_height: number;
  box_width: number;
  box_height: number;
  spaces: number;
}

export const RacetrackContext = createContext<ContextValue>({} as ContextValue);

const RacetrackProvider = ({ children }: ProviderProps) => {
  const { height } = useWindowDimensions();
  const stopBetting = useAppSelector(selectStopBetting);
  const value = useMemo(() => {
    const bigScreen = subtract(height, offset);
    const smallScreen = multiply(bigScreen, 0.68);
    let canvas_width = bigScreen;

    if (stopBetting) {
      canvas_width = smallScreen;
    }

    const canvas_height = canvas_width * 0.37;
    const box_width = canvas_width * 0.05481 - 0.5;
    const box_height = canvas_height * 0.2139 - 0.5;
    const spaces = canvas_width * 0.0047;

    return {
      bigScreen,
      canvas_width,
      canvas_height,
      box_width,
      box_height,
      spaces,
    };
  }, [stopBetting]);

  return (
    <RacetrackContext.Provider value={value}>
      {children}
    </RacetrackContext.Provider>
  );
};

export default RacetrackProvider;
