import React, { useContext } from 'react';
import { FlatList } from 'react-native';
import useStyles from './styles.css';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { MaskChipData } from '@src/utils/mockdata/Table';
import MaskChipItem from './Items';
import { TableContext } from '../provider';
const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const MaskChip = () => {
  const styles = useStyles();
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const screenSize = useContext(TableContext);

  React.useEffect(() => {
    boxWidth.value = withTiming(screenSize.box_width, { duration: 500 });
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      top: -boxHeight.value * 0.25,
      right: boxWidth.value * 0.25,
    };
  }, []);

  return (
    <Animated.View style={[styles.container, animatedStyle]}>
      <AnimatedFlatList
        data={MaskChipData}
        numColumns={6}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item, index }) => {
          const isOdd = index % 2 !== 0;
          return (
            <MaskChipItem key={index.toString()} item={item} isOdd={isOdd} />
          );
        }}
      />
    </Animated.View>
  );
};

export default MaskChip;
