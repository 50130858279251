import React, { useContext, useMemo, useState } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import { ValueType } from '@src/screens/routette/types';
import { View, Pressable } from 'react-native';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useBetService } from '@src/store/hooks';
import { TableContext } from '../provider';
import ChipToken from '../mask-chip/chips';
import WinningMark from './winning-mark';
import useStyles from './styles.css';
import Diamond from './Diamond';

const getBlackRed = (type: number) => {
  return type === ValueType.BLACK || type === ValueType.RED;
};

const FirstItem = ({ item, isFirst, isLast }: any) => {
  const styles = useStyles();
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const screenSize = useContext(TableContext);
  const isBlack = item.type === ValueType.BLACK;
  const [selfFocus, setSelFocus] = useState(false);
  const { onHoverItem, onPlaceBet } = useBetService();
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);
  const isBlackRed = useMemo(() => getBlackRed(item.type), [item.type]);

  const onHoverIn = () => {
    onHoverItem(item);
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  React.useEffect(() => {
    boxWidth.value = withTiming(screenSize.box_width, { duration: 500 });
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      width: boxWidth.value,
      height: boxHeight.value * 2,
    };
  }, []);

  const textStyle = useAnimatedStyle(() => {
    return {
      fontSize: 0.47 * boxHeight.value,
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.v_item_container,
        animateStyle,
        isFirst && styles.borderTopLeft,
        isLast && styles.borderBottomLeft,
        selfFocus && styles.active_style,
      ]}
    >
      <Pressable
        style={[
          styles.btn_item,
          isFirst && styles.borderTopLeft,
          isLast && styles.borderBottomLeft,
          // opacityStyle,
        ]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={[styles.v_wrap_text]}>
          {isBlackRed ? (
            <Diamond isBlack={isBlack} />
          ) : (
            <Animated.Text
              allowFontScaling
              numberOfLines={1}
              selectable={false}
              style={[styles.text, textStyle]}
            >
              {item.placeholder}
            </Animated.Text>
          )}
          <ChipToken cell={item} boxSize={screenSize.box_width} />
        </View>
      </Pressable>

      {showWinStatus && item.valueSplit.includes(winNumber) && (
        <WinningMark
          {...{
            isFirst,
            isLast,
            animateStyle,
          }}
        />
      )}
    </Animated.View>
  );
};

export default FirstItem;
