import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginTop: 30,
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_separator: { width: 15 },

    // CHIP ITEM
    btn_token: {
      width: 53,
      height: 53,
      borderRadius: 53 / 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_token: {
      width: 45,
      height: 45,
      borderRadius: 45 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
    },
    btn_active: {
      // shadowColor: 'yellow',
      // shadowOffset: { width: 1, height: 1 },
      // shadowOpacity: 5.6,
      // shadowRadius: 10,
      // elevation: 5,
      borderWidth: 2,
      borderColor: colors.blue,
    },
    v_bet_container: { position: 'absolute', zIndex: 1 },
    t_bet_amount: {
      fontSize: 16,
      color: colors.white,
      fontFamily: 'DMSans-Bold',
    },
    i_chip: { width: '100%', height: '100%' },

    // HIDDEN BUTTON
    v_hidden_button: {
      width: '84%',
      height: '100%',
      backgroundColor: 'transparent',
      position: 'absolute',
      left: 0,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
