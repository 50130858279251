import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import FirstColumn from './first-column';
import SecondColumn from './second-column';
import ThirdColumn from './third-column';
import ThirdRow from './third-row';
import Zero from './zero';
import { RouletteContext } from '../../RouletteProvider';
import { TableProvider } from './provider';

const TableView = () => {
  const styles = useStyles();
  const { isRecetrack } = React.useContext(RouletteContext);

  return (
    <TableProvider>
      <View style={[styles.v_main_wrapper, isRecetrack && styles.disable]}>
        <View style={[styles.container]}>
          <Zero />
          <View style={styles.v_body}>
            <FirstColumn />
            <SecondColumn />
            <ThirdColumn />
          </View>
          <ThirdRow />
        </View>
      </View>
    </TableProvider>
  );
};

export default TableView;
