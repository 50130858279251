import React from 'react';
import { View, FlatList } from 'react-native';
import { SecondColumnData } from '@src/utils/mockdata/Table';
import SecondItem from './SecondItem';

const SecondColumn = () => {
  return (
    <View>
      <FlatList
        scrollEnabled={false}
        data={SecondColumnData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => {
          return <SecondItem key={index.toString()} item={item} />;
        }}
      />
    </View>
  );
};

export default SecondColumn;
