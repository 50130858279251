import React from 'react';
import { Text, View } from 'react-native';
import { useBetService } from '@src/store/hooks';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedChipMask } from '@src/store/slices';
import useStyles from './styles.css';
import colors from '@assets/colors';
import IconButton from './button';
import _ from 'lodash';

const MiddleOptions = () => {
  const styles = useStyles();
  const chipmask = useAppSelector(selectedChipMask);
  const { onUndoBet, onDeleteAllBet, onDoubleBet } = useBetService();
  const onUndo = () => {
    const lastEntry = _.last(chipmask);
    onUndoBet(lastEntry);
  };

  return (
    <View style={styles.container}>
      <IconButton onPress={onUndo} name={'redo'} style={styles.ab_redo} />
      <IconButton onPress={onDoubleBet}>
        <Text selectable={false} style={styles.ab_t_x2}>
          x2
        </Text>
      </IconButton>
      <IconButton onPress={onUndo} name={'replay'} color={colors.blue} />
      <IconButton
        style={styles.ab_delete}
        onPress={onDeleteAllBet}
        color={colors.red}
        name={'close'}
      />
    </View>
  );
};

export default MiddleOptions;
