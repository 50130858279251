import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import { RacetrackContext } from './provider';
import LayerBottom from './layer-bottom';
import LayerMiddle from './layer-middle';
import { Stage } from 'react-konva';
import LayerTop from './layer-top';
import LayerLeft from './layer-left';
import LayerRight from './layer-right';
import { RouletteContext } from '../../RouletteProvider';

const MainScreen = () => {
  const styles = useStyles();
  const { isRecetrack } = React.useContext(RouletteContext);
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);

  return (
    <View
      style={[
        styles.container,
        { width: canvas_width, height: canvas_height },
        !isRecetrack && styles.disable,
      ]}
    >
      <Stage width={canvas_width} height={canvas_height}>
        <LayerLeft />
        <LayerTop />
        <LayerMiddle />
        <LayerBottom />
        <LayerRight />
      </Stage>
    </View>
  );
};

export default React.memo(MainScreen);
