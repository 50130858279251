import React from 'react';
import { Group, Image, Text } from 'react-konva';
import useImage from 'use-image';

const LocalImage = (props: any) => {
  const [image] = useImage(props.src);
  const imageRef = React.useRef<any>();
  const groupRef = React.useRef<any>();
  const textRef = React.useRef<any>();

  React.useEffect(() => {
    groupRef.current?.to({
      x: props.x,
      y: props.y,
      width: props.width,
      height: props.height,
      duration: 0.4,
    });
    imageRef.current?.to({
      width: props.width,
      height: props.height,
      duration: 0.4,
    });
    textRef.current?.to({
      width: props.width,
      height: props.height,
      duration: 0.4,
    });
  }, [props]);

  return (
    <Group ref={groupRef}>
      <Image
        ref={imageRef}
        width={props.width}
        height={props.height}
        image={image}
      />
      <Text
        text={props.amount}
        fontSize={props.fontSize || 7}
        fill={'white'}
        align="center"
        width={props.width}
        height={props.height}
        verticalAlign="middle"
        fontFamily="DMSans-Bold"
      />
    </Group>
  );
};

export default LocalImage;
