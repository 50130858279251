import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { appstateActions, selectedOrientation } from '../slices/appstate.slice';
import { getOrientaion } from '@root/src/utils/appstate-helper';

type ServiceOperators = {
  isLandscape: boolean;
  updateOrientaion: (payload: string) => void;
};

export const useAppStateService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    isLandscape: useAppSelector(selectedOrientation),
    updateOrientaion: useCallback(
      (payload: any) => {
        dispatch(appstateActions.setOrientation(getOrientaion(payload)));
      },
      [dispatch]
    ),
  };
};

export default useAppStateService;
