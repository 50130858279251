import React from 'react';
import { View, ActivityIndicator, StyleSheet, ViewStyle } from 'react-native';

type LoadingProps = {
  isLoading?: boolean;
  color?: string;
  style?: ViewStyle;
  size?: number;
};

const Loading = (props: LoadingProps) => {
  if (!props.isLoading) {
    return null;
  }

  return (
    <View style={[styles.container, props.style]}>
      <ActivityIndicator
        animating
        size={props.size || 'large'}
        color={props.color || 'black'}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 2,
    paddingTop: 20,
    alignItems: 'center',
  },
});

export default Loading;
