import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: colors.black,
    },
    v_body: {
      flex: 1,
      zIndex: 2,
      paddingHorizontal: 40,
      justifyContent: 'center',
    },
    v_height: { height: 60 },

    // LOGIN COMPONENT
    v_login: {
      marginTop: 150,
      minWidth: 420,
      maxWidth: 420,
      borderWidth: 2,
      borderRadius: 25,
      paddingVertical: 30,
      paddingHorizontal: 30,
      backgroundColor: colors.black5,
    },
    t_login: { fontFamily: 'DMSans-Bold', color: 'white', fontSize: 30 },
    i_login_bg: { width: '100%', height: '100%', position: 'absolute' },
    i_logo: { width: '35%', height: 200 },
    v_login_middle: {
      zIndex: 2,
      width: '100%',
      height: '100%',
    },
    v_girl: {
      bottom: 0,
      right: 0,
      width: '60.46875%',
      height: '93.88889%',
      position: 'absolute',
    },
    i_girl: { width: '100%', height: '100%' },
    signInCenter: { marginTop: 30 },
    v_field: { marginTop: 20 },
    t_support: {
      marginTop: 30,
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      textAlign: 'center',
      color: colors.text01,
    },
    v_forgotpassword: {
      color: 'white',
      fontSize: 14,
      marginTop: 20,
      fontFamily: 'DMSans-Bold',
    },
    t_recover: { color: colors.blue },
    mt20: { height: 20 },
    i_mask1: {
      width: 120,
      height: 120,
      top: 0,
      left: 0,
      position: 'absolute',
      borderTopLeftRadius: 25,
    },
    i_mask2: {
      width: 120,
      height: 120,
      right: 0,
      bottom: 0,
      position: 'absolute',
      borderBottomRightRadius: 25,
    },

    // ERROR
    t_error: {
      fontSize: 13,
      color: colors.red1,
      textAlign: 'center',
      fontFamily: 'DMSans',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      v_login: {
        width: '40%',
      },
    },
    [DEVICE_SIZES.SM]: {
      v_body: {
        paddingHorizontal: '17%',
      },
      v_login: {
        marginTop: 40,
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 30,
      },
      v_login_middle: { paddingHorizontal: 100 },
      v_girl: { width: 300, height: 300, zIndex: 3 },
      t_support: { left: 100 },
    },
    [DEVICE_SIZES.XS]: {
      v_body: {
        paddingHorizontal: 20,
      },
      v_login: {
        marginTop: 40,
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 30,
      },
      v_login_middle: { paddingHorizontal: 20 },
      v_girl: { width: 200, height: 200, zIndex: 3 },
      t_support: { left: 20 },
    },
  }
);

export default useStyles;
