import React, { useRef, useCallback } from 'react';
import {
  View,
  Text,
  Modal,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from 'react-native';
import { colors } from 'theme';

const MENU_HEIGHT = 130;

const MenuDropdown = (props: any) => {
  const flatlist = useRef(null);
  const windowHeight = useWindowDimensions().height;
  const stylePosition = React.useMemo(() => {
    const { w, x, y, h } = props.measures;
    const pos_upward = y + MENU_HEIGHT;
    const isGreaterThanHeight = pos_upward > windowHeight;
    const positionStyle = {
      height: MENU_HEIGHT,
      width: w,
      left: x,
      top: isGreaterThanHeight ? y - (MENU_HEIGHT - h) : y + h,
    };

    return positionStyle;
  }, [props.measures, windowHeight]);

  const RenderItem = useCallback(
    ({ item, index }: any) => {
      let newRow = item;

      if (item && item.name && item.name.common) {
        newRow = item.name.common;
      } else if (item && item.name) {
        newRow = item.name;
      } else {
        newRow = item;
      }

      const active = props.value === newRow;

      return (
        <TouchableOpacity
          key={`${index}`}
          onPress={() => {
            props.onSelected(item);
            props.onClose();
          }}
          style={[styles.v_item, active && styles.v_item_active]}
        >
          <Text style={styles.t_item}>{newRow}</Text>
        </TouchableOpacity>
      );
    },
    [props.value]
  );

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={'none'}
      onRequestClose={props.onClose}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <TouchableWithoutFeedback
        accessible={props.accessible}
        onPress={props.onClose}
      >
        <View style={styles.modal}>
          <View style={[styles.dropdown, stylePosition]}>
            <FlatList
              ref={flatlist}
              data={props.options}
              getItemLayout={(item, index) => ({
                length: 42,
                offset: 42 * index,
                index,
              })}
              // eslint-disable-next-line react/no-unstable-nested-components
              ItemSeparatorComponent={() => (
                <View style={styles.itemSeparator} />
              )}
              keyExtractor={(_, index) => `${index}`}
              renderItem={RenderItem}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: { flexGrow: 1 },
  dropdown: {
    width: 400,
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: 'white',
    justifyContent: 'center',
    shadowColor: 'gray',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.6,
    shadowRadius: 10,
  },
  itemSeparator: {
    height: 1,
    marginHorizontal: 10,
    backgroundColor: colors.translucent1,
  },
  v_item: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  v_item_active: {
    backgroundColor: colors.translucent1,
  },
  t_item: {
    flex: 1,
    fontSize: 15,
    fontWeight: '500',
    color: colors.black,
    fontFamily: 'Roboto',
  },
});

export default MenuDropdown;
