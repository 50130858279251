import React, { useContext, useState } from 'react';
import {
  selectWinNumber,
  selectWinNumberStatus,
  selectedHoverItem,
} from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import { Zero as Cell } from '@src/utils/mockdata/Table';
import WinningMark from '../third-column/winning-mark';
import { View, Pressable, Text } from 'react-native';
import { BoardContext } from '../../provider/board';
import { useBetService } from '@src/store/hooks';
import ChipToken from '../mask-chip/chips';
import useStyles from '../styles.css';

const Zero = () => {
  const styles = useStyles();
  const [selfFocus, setSelFocus] = useState(false);
  const { onHoverItem, onPlaceBet } = useBetService();
  const hoverItem = useAppSelector(selectedHoverItem);
  const { box_width, box_height } = useContext(BoardContext);
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem({});
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  const isFocus = React.useMemo(() => {
    if (hoverItem.value && Cell.value) {
      return hoverItem.value === Cell.value;
    }

    if (hoverItem.valueSplit) {
      return hoverItem.valueSplit.includes(Cell.value);
    }

    return false;
  }, [hoverItem, Cell]);

  return (
    <View
      style={[
        styles.v_zero,
        {
          width: box_width,
          height: (box_height + box_height / 2) * 3,
        },
      ]}
    >
      <Pressable
        style={[
          styles.v_zero_center,
          (isFocus || selfFocus) && styles.active_style,
        ]}
        onPress={() => onPlaceBet(Cell)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <Text
            allowFontScaling
            numberOfLines={1}
            selectable={false}
            style={[styles.zero_text, { fontSize: 0.55 * box_width }]}
          >
            0
          </Text>
          <ChipToken cell={Cell} BoxSize={box_height} />
          {showWinStatus && winNumber === 0 && (
            <WinningMark boxHeight={box_width} />
          )}
        </View>
      </Pressable>
    </View>
  );
};

export default Zero;
