/* eslint-disable react-native/no-inline-styles */
import React, { useContext } from 'react';
import { selectUserBalance, selectUserFullname } from '@root/src/store/slices';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import Icon from '@expo/vector-icons/MaterialIcons';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';

const DrawerHeader = () => {
  const styles = useStyles();
  const fullname = useAppSelector(selectUserFullname);
  const balance = useAppSelector(selectUserBalance);

  return (
    <View style={[styles.drawer_user_frame_container]}>
      <Icon name="person" size={40} color={colors.offwhite2} />
      <View style={styles.v_details}>
        <Text style={styles.user_fullname}>{fullname}</Text>
        <Text style={styles.edit_profile}>
          Balance: <Text style={{ fontFamily: 'DMSans-Bold' }}>{balance}</Text>
        </Text>
      </View>
    </View>
  );
};

export default DrawerHeader;
