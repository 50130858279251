import React, { createContext } from 'react';
import { multiply, subtract } from '@root/src/utils/computation-helper';
import { useWindowDimensions } from 'react-native';

const offset = 145;

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  base_height: number;
  canvas_width: number;
  canvas_height: number;
  box_width: number;
  box_height: number;
  spaces: number;
}

export const RacetrackContext = createContext<ContextValue>({} as ContextValue);

const RacetrackProvider = ({ children }: ProviderProps) => {
  const { width, height } = useWindowDimensions();
  const value = React.useMemo(() => {
    const base_height = multiply(subtract(height, offset), 0.9);
    const canvas_width = width * 0.63;
    const canvas_height = canvas_width * 0.37;
    const box_width = canvas_width * 0.05481 - 0.5;
    const box_height = canvas_height * 0.2139 - 0.5;
    const spaces = canvas_width * 0.0047;

    return {
      base_height,
      canvas_width,
      canvas_height,
      box_width,
      box_height,
      spaces,
    };
  }, [width, height]);

  return (
    <RacetrackContext.Provider value={value}>
      {children}
    </RacetrackContext.Provider>
  );
};

export default RacetrackProvider;
