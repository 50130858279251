import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedTotalBet } from '@src/store/slices';
import colors from '@assets/colors';

function BetTotal() {
  const totalbet = useAppSelector(selectedTotalBet);

  return (
    <View style={styles.container}>
      <Text selectable={false} style={styles.total_bet}>
        BET:{' '}
      </Text>
      <Text selectable={false} style={[styles.total_bet, styles.bet_color]}>
        {totalbet}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 35,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  total_bet: {
    fontSize: 25,
    color: colors.blue,
    fontFamily: 'Roboto',
  },
  bet_color: {
    fontSize: 34,
    color: colors.white,
  },
});

export default BetTotal;
