import React, { createContext } from 'react';
import { useWindowDimensions } from 'react-native';
import useMetrics from '@src/utils/metrics';
interface BoardProviderProps {
  children: React.ReactElement;
}

interface BoardContextValue {
  box_width: number;
  box_height: number;
}

export const BoardContext = createContext<BoardContextValue>(
  {} as BoardContextValue
);

const BoardProvider = ({ children }: BoardProviderProps) => {
  const { width } = useWindowDimensions();
  const { horizontalScale } = useMetrics();
  const parent_width = width * 0.4;
  const minWidth = parent_width > 400 ? parent_width : 400;
  const canvas_height = horizontalScale(50);
  const box_height = canvas_height / 6.5;
  const canvas_width = minWidth * 0.85;
  const box_width = canvas_width / 14;

  return (
    <BoardContext.Provider value={{ box_width, box_height }}>
      {children}
    </BoardContext.Provider>
  );
};

export default BoardProvider;
