import { SagaIterator } from '@redux-saga/core';
import { call, delay, put, takeEvery } from 'redux-saga/effects';

// Types
import * as Types from '../types';

// API
import * as API from '@src/utils/api';

// Slice
import { betActions } from '../slices/bet.slice';
import { userActions } from '../slices/user.slice';
import { socketActions } from '../slices/socket.slice';
import { rouletteActions } from '../slices/roulette.slice';
import { authActions } from '../slices';
import _ from 'lodash';

let Socket: any = null;

function* hadleTableDetails(action: {
  type: typeof rouletteActions.fetchingTableDetails;
  payload: Types.TableId;
}): SagaIterator {
  try {
    // yield call(testEvent, 10);
    // yield put(
    //   rouletteActions.updateGameStatus({
    //     event: 'newGame',
    //     state: { lastNumber: 10 },
    //   })
    // );
    const tableDetails = yield call(
      API.rouletteTableItem,
      action.payload.tableId,
      action.payload.token
    );

    const lastNumbers = yield call(
      API.lastNumbers,
      action.payload.tableId,
      action.payload.token
    );

    yield put(rouletteActions.fetchLastNumberSuccess(lastNumbers?.data ?? {}));
    yield put(betActions.setMinMaxBet(tableDetails.data));
    yield put(rouletteActions.fetchTableDetailsSuccess(tableDetails.data));

    // yield call(testEvent, 10);
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(rouletteActions.fetchTableDetailsFailed({ message }));
    // yield call(testEvent, 10);

    yield delay(1000);
    yield put(rouletteActions.fetchTableDetailsFailed({}));
  }
}

function* testEvent(lastNumber = 10): SagaIterator {
  try {
    // Make an API call to refresh the token
    yield put(betActions.onDeleteAllBet());
    yield put(
      rouletteActions.updateGameStatus({
        event: 'newGame',
        state: { lastNumber },
      })
    );

    // Delay for 1 hour (in milliseconds) before triggering the token refresh again
    yield delay(10000);
    yield put(
      rouletteActions.updateGameStatus({
        event: 'spin',
        state: { lastNumber },
      })
    );

    yield delay(3000);
    yield put(
      rouletteActions.updateGameStatus({
        event: 'waitingNumber',
        state: { lastNumber },
      })
    );

    yield delay(4000);
    const random = _.random(0, 36);
    yield put(
      rouletteActions.updateGameStatus({
        event: 'win',
        state: { lastNumber: random },
      })
    );

    yield delay(5000);

    // Restart the token refresh process by calling the same worker saga recursively
    yield call(testEvent, random);
  } catch (error) {}
}

function* handleLogout(): SagaIterator {
  try {
    Socket && Socket.close();
    yield put(socketActions.socketDisconnected({ message: 'logout' }));
  } catch (e) {}
}

function* handleSocketMessage(action: any): SagaIterator {
  try {
    if (action.payload.Event === 'win') {
      const summary = yield call(API.userSummary, undefined);
      const balance = summary.data[0].playerCredit;
      yield put(userActions.updateUserBalance(balance));
    } else if (action.payload.Event === 'Spin') {
      yield put(betActions.onSetError('Final bet'));
    } else if (action.payload.Event === 'waitingNumber') {
      yield put(betActions.onSetError('No more bets'));
    } else if (action.payload.Event === 'newGame') {
      yield put(betActions.onSetError('Start betting'));
      yield put(betActions.onDeleteAllBet());
    }
  } catch (e) {}
}

function* handleSubmitBet(action: {
  type: typeof betActions.saveBets;
  payload: Types.BetSave;
}): SagaIterator {
  try {
    const tableDetails = yield call(
      API.betSave,
      action.payload,
      action.payload.token
    );

    console.log('tableDetails success', tableDetails);

    const summary = yield call(API.userSummary, action.payload.token);
    const balance = summary.data[0].playerCredit;

    yield put(userActions.updateUserBalance(balance));
  } catch (error: any) {
    console.log('handleSubmitBet error ', error);
  }
}

// Watcher Saga
function* rouletteWatcherSaga(): SagaIterator {
  yield takeEvery(rouletteActions.fetchingTableDetails.type, hadleTableDetails);
  yield takeEvery(rouletteActions.updateGameStatus.type, handleSocketMessage);
  yield takeEvery(betActions.saveBets.type, handleSubmitBet);
  yield takeEvery(authActions.logout.type, handleLogout);
}

export default rouletteWatcherSaga;
