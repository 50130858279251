import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedHoverItem } from '@src/store/slices';
import { useBetService } from '@src/store/hooks';
import { BoardContext } from '../provider';
import ChipToken from '../mask-chip/chips';
import useStyles from './styles.css';
import colors from '@assets/colors';

const RowItem = ({ item }: any) => {
  const styles = useStyles();
  const [selfFocus, setSelFocus] = useState(false);
  const hoverItem = useAppSelector(selectedHoverItem);
  const { onHoverItem, onPlaceBet } = useBetService();
  const { box_width, box_height } = useContext(BoardContext);

  const onHoverIn = () => {
    onHoverItem(item);
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  const isFocus = React.useMemo(() => {
    if (hoverItem.value && item.value) {
      return hoverItem.value === item.value;
    }

    if (hoverItem.valueSplit) {
      return hoverItem.valueSplit.includes(item.value);
    }

    return false;
  }, [hoverItem, item]);

  return (
    <View
      style={[
        styles.v_item_container,
        {
          width: box_width,
          height: box_height + box_height / 2,
        },
      ]}
    >
      <Pressable
        style={[styles.btn_item, (isFocus || selfFocus) && styles.active_style]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <View
            style={{
              width: box_width / 1.5,
              height: box_width / 1.5,
              borderRadius: box_width / 1.5 / 2,
              backgroundColor: colors.gray1,
            }}
          />
          <ChipToken cell={item} BoxSize={box_width * 1.5} />
        </View>
      </Pressable>
    </View>
  );
};

export default RowItem;
