import React, { useState } from 'react';
import { View, Text, Image, Pressable } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedBetAmount } from '@src/store/slices';
import { useBetService } from '@src/store/hooks';
import useStyles from './styles.css';

const ChipItem = ({ item }: any) => {
  const styles = useStyles();
  const { onBetAmount } = useBetService();
  const [hover, setHover] = useState(false);
  const betAmount = useAppSelector(selectedBetAmount);
  const isFocus = betAmount === item.amount;

  const onPress = () => {
    onBetAmount(item.amount);
  };

  return (
    <Pressable
      style={[
        styles.btn_token,
        isFocus && styles.btn_active,
        hover && styles.btn_active,
      ]}
      onPress={onPress}
      onPressIn={() => setHover(true)}
      onPressOut={() => setHover(false)}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <View style={[styles.v_token]}>
        <Image style={styles.i_chip} source={item.icon} resizeMode="stretch" />
        <View style={styles.v_bet_container}>
          <Text selectable={false} style={[styles.t_bet_amount]}>
            {item.amount}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default React.memo(ChipItem);
