import * as Linking from 'expo-linking';
import { getStateFromPath } from '@react-navigation/native';

const prefix = Linking.createURL('/');
const config: any = {
  screens: {
    SignIn: 'sign-in',
    SignUp: 'sign-up',
    Home: 'home',
    Roulette: 'roulette',
  },
};
const linking: any = {
  prefixes: [prefix],
  config,
  async getInitialURL() {
    return await Linking.getInitialURL();
  },
  subscribe(listener: any) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);
    const subscription = Linking.addEventListener('url', onReceiveURL);

    return () => subscription.remove();
  },
  getStateFromPath: (path: string) => {
    return getStateFromPath(path, config);
  },
};

export default linking;
