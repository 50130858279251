import React from 'react';
import { View, FlatList } from 'react-native';
import { ThirdColumnData } from '../../mockdata/table';
import useStyles from '../styles.css';
import ThirdItem from './ThirdItem';
import MaskChip from '../mask-chip';

const ThirdColumn = () => {
  const styles = useStyles();

  return (
    <View style={styles.zIndex2}>
      <FlatList
        numColumns={12}
        scrollEnabled={false}
        data={ThirdColumnData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => {
          return <ThirdItem key={index.toString()} item={item} />;
        }}
      />
      <MaskChip />
    </View>
  );
};

export default ThirdColumn;
