import React from 'react';
import { pickNearest } from '@root/src/utils/mockdata/Racetrack';
import { useBetService } from '@root/src/store/hooks';
import { HoverContext } from '../hover-provider';
import { Text, Group, Path } from 'react-konva';
import { RacetrackContext } from '../provider';
import colors from '@assets/colors';
import Token from '../token';

const baseStroke = 2;
const baseTokenSize = 35;

const Items = (props: any) => {
  const { onPlaceBet } = useBetService();
  const { box_height } = React.useContext(RacetrackContext);
  const { hover, onSetHover } = React.useContext(HoverContext);

  const value = React.useMemo(() => {
    const widthScale = (props.box_width - baseStroke) / props.baseWidth;
    const heightScale = (props.box_height - baseStroke) / props.baseHeight;
    const sc = Math.min(widthScale, heightScale);
    const fwidth = props.baseWidth * sc;
    const fheight = props.baseHeight * sc;
    const tokenScl = box_height / 2 / baseTokenSize;
    const tX = fwidth * props.tokenX;
    const tY = fheight * props.tokenY;
    const tSize = baseTokenSize * tokenScl;

    return {
      scale: sc,
      final_width: fwidth,
      final_height: fheight,
      tokenX: tX,
      tokenY: tY,
      tokenSize: tSize,
    };
  }, [props, box_height]);

  const onPress = () => {
    const result = pickNearest(props.value, 2);
    onPlaceBet(result);
  };

  const isFocus = React.useMemo(() => {
    return hover.valueSplit ? hover.valueSplit.includes(props.value) : false;
  }, [hover]);

  return (
    <Group
      x={props?.x ?? 0}
      y={props?.y ?? 0}
      width={value.final_width}
      height={value.final_height}
      onTap={onPress}
      onClick={onPress}
      onTouchStart={() => {
        onSetHover({ key: props.value });
      }}
      onTouchMove={() => {
        onSetHover({});
      }}
      onTouchEnd={() => {
        onSetHover({});
      }}
      onMouseEnter={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        onSetHover({ key: props.value });
      }}
      onMouseLeave={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        onSetHover({});
      }}
    >
      <Path
        scaleX={value.scale}
        scaleY={value.scale}
        data={props.path}
        strokeWidth={baseStroke}
        fill={props.fill}
        stroke={isFocus ? colors.white : colors.brown2}
      />
      <Text
        x={props?.textX ?? 0}
        y={props?.textY ?? 0}
        fontSize={box_height * 0.34}
        width={value.final_width}
        height={value.final_height}
        text={props.label}
        fill={'white'}
        align="center"
        fontFamily="DMSans-Bold"
        verticalAlign="middle"
      />
      <Token
        x={value.tokenX}
        y={value.tokenY}
        size={value.tokenSize}
        cellkey={props.value}
      />
    </Group>
  );
};

export default Items;
