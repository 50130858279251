import { ValueType } from '../screens/routette/types';

export const add = (a: any, b: any) => {
  return a + b;
};

export const subtract = (a: any, b: any) => {
  return a - b;
};

export const multiply = (a: any, b: any) => {
  return a * b;
};

export const divide = (a: any, b: any) => {
  return a / b;
};

export const getTotalBet = (
  item: any,
  betamount: number,
  totalbet: number,
  currentbalance: number
) => {
  let total: number = 0;
  if (['searies58', 'orphelins', 'series023', 'spiel'].includes(item.key)) {
    item.output.forEach((value: any) => {
      let bet = value.type === ValueType.QUAD_SPLIT ? betamount * 2 : betamount;
      total += bet;
    });
  } else if (item.key === 'racetrackno') {
    item.output.forEach(() => {
      let bet = betamount;
      total += bet;
    });
  } else {
    total += betamount;
  }

  // console.log('total', total, totalbet);

  return add(totalbet, total) > currentbalance;
};
