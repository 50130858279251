import React, { useState } from 'react';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import { selectedCamera } from '@root/src/store/slices';
import * as Flashphoner from '@flashphoner/websdk';
import Loading from '@src/components/loading';
import Config from '@root/src/utils/Config';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import './style.css';

const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
const Camera1 =
  'rtsp://root:nimda123@10.205.144.13/axis-media/media.amp?resolution=1280x720';
const Camera2 =
  'rtsp://root:nimda123@10.205.144.12/axis-media/media.amp?resolution=1280x720';

const VideoStreamer = (props: any) => {
  const styles = useStyles();
  const camera = useAppSelector(selectedCamera);
  const [session, setSession] = useState<any>(null);
  const [playStatus, setPlayStatus] = useState<any>('');

  const isFailed = React.useMemo(() => {
    return (
      session &&
      session?.status() === SESSION_STATUS.ESTABLISHED &&
      props.active &&
      playStatus === STREAM_STATUS.FAILED
    );
  }, [session, playStatus, props.active]);

  const connect = () => {
    Flashphoner.createSession({ urlServer: Config.API_STREAM_URL })
      .on(SESSION_STATUS.ESTABLISHED, (result: any) => {
        setSession(result);
      })
      .on(SESSION_STATUS.DISCONNECTED, () => {
        setSession(null);
        connect();
      })
      .on(SESSION_STATUS.FAILED, () => {
        setSession(null);
        connect();
      });
  };

  const playStream = () => {
    session
      .createStream({
        name: camera === 1 ? Camera1 : Camera2,
        display: document.getElementById(props.id),
        cacheLocalResources: true,
        receiveVideo: true,
        receiveAudio: true,
        unmutePlayOnStart: false,
      })
      .on(STREAM_STATUS.PENDING, () => {
        setPlayStatus(STREAM_STATUS.PENDING);
      })
      .on(STREAM_STATUS.PLAYING, () => {
        setPlayStatus(STREAM_STATUS.PLAYING);
      })
      .on(STREAM_STATUS.STOPPED, () => {
        setPlayStatus(STREAM_STATUS.STOPPED);
      })
      .on(STREAM_STATUS.FAILED, () => {
        setPlayStatus(STREAM_STATUS.FAILED);
      })
      .on(STREAM_STATUS.PAUSED, () => {
        setPlayStatus(STREAM_STATUS.PAUSED);
      })
      .on(STREAM_STATUS.NOT_ENOUGH_BANDWIDTH, () => {
        setPlayStatus(STREAM_STATUS.NOT_ENOUGH_BANDWIDTH);
      })
      .on(STREAM_STATUS.NEW, () => {
        setPlayStatus(STREAM_STATUS.NEW);
      })
      .on(STREAM_STATUS.PLAYBACK_PROBLEM, () => {
        setPlayStatus(STREAM_STATUS.PLAYBACK_PROBLEM);
      })
      .play();
  };

  const onRefreshStreaming = React.useCallback(() => {
    session && session.disconnect();
  }, [session]);

  React.useEffect(() => {
    try {
      Flashphoner.init({});
      connect();
    } catch (e) {}
  }, []);

  React.useEffect(() => {
    onRefreshStreaming();
  }, [camera]);

  React.useEffect(() => {
    if (session && session?.status() === SESSION_STATUS.ESTABLISHED) {
      setPlayStatus(STREAM_STATUS.PENDING);
      playStream();
    }
  }, [session]);

  return (
    <View style={[styles.container, props.style]}>
      <div id={props.id} className="display" />
      {isFailed && (
        <Text onPress={onRefreshStreaming} style={styles.t_failed}>
          Connection failed.
        </Text>
      )}
      <Loading
        color="white"
        size={props.size}
        style={styles.loading_style}
        isLoading={session === null || playStatus === STREAM_STATUS.PENDING}
      />
    </View>
  );
};

export default React.memo(VideoStreamer);
