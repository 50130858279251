import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import { RacetrackContext } from '../provider/racetrack';
import LayerBottom from './layer-bottom';
import LayerMiddle from './layer-middle';
import { Stage } from 'react-konva';
import LayerTop from './layer-top';
import LayerLeft from './layer-left';
import LayerRight from './layer-right';

const Racetrack = () => {
  const styles = useStyles();
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);

  return (
    <View
      style={[styles.container, { width: canvas_width, height: canvas_height }]}
    >
      <Stage width={canvas_width} height={canvas_height}>
        <LayerLeft />
        <LayerTop />
        <LayerMiddle />
        <LayerBottom />
        <LayerRight />
      </Stage>
    </View>
  );
};

export default Racetrack;
