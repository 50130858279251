import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  rouletteActions,
  selectedAuthToken,
  selectLastNumbers,
  selectTableDetails,
  selectSartBetting,
  selectFinalBetting,
  selectStopBetting,
  selectWinNumber,
  selectWinNumberStatus,
  selectTableDetailsLoad,
} from '../slices';

// Types
import * as Types from '../types';

export type RouletteServiceOperators = {
  isLoading: boolean;
  startBetting: boolean;
  finalBetting: boolean;
  stopBetting: boolean;
  winNumber: number;
  showWinNumber: boolean;
  tableDetails: Types.TableItem;
  lastNumbers: [];
  fetchingTableDetails: () => void;
  updateGameStatus: (message: any) => void;
};

export const useRouletteService = (): Readonly<RouletteServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);
  const tableId = '3';

  return {
    isLoading: useAppSelector(selectTableDetailsLoad),
    startBetting: useAppSelector(selectSartBetting),
    finalBetting: useAppSelector(selectFinalBetting),
    stopBetting: useAppSelector(selectStopBetting),
    winNumber: useAppSelector(selectWinNumber),
    showWinNumber: useAppSelector(selectWinNumberStatus),
    tableDetails: useAppSelector(selectTableDetails),
    lastNumbers: useAppSelector(selectLastNumbers),
    fetchingTableDetails: useCallback(() => {
      dispatch(
        rouletteActions.fetchingTableDetails({
          tableId,
          token,
        } as Types.TableId)
      );
    }, [dispatch]),
    updateGameStatus: useCallback(
      (message: any) => {
        dispatch(rouletteActions.updateGameStatus(message));
      },
      [dispatch]
    ),
  };
};

export default useRouletteService;
