// DUCKS pattern
import type { RootState } from '@src/store';
import { createSlice } from '@reduxjs/toolkit';

export interface LangState {
  lang: string;
}

const initialState: LangState = {
  lang: 'English',
};

// Slice
export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.lang = action.payload;
    },
  },
});

// Actions
export const languageActions = {
  setLanguage: languageSlice.actions.setLanguage,
};

// Selectors
export const selectedLangauge = (state: RootState) => state.language.lang;

// Reducer
export default languageSlice.reducer;
