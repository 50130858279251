import React, { useContext, useState } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import WinningMark from '../first-column/winning-mark';
import { View, Pressable, Text } from 'react-native';
import { useBetService } from '@src/store/hooks';
import { BoardContext } from '../provider';
import ChipToken from '../mask-chip/chips';
import useStyles from './styles.css';

const SecondItem = ({ item }: any) => {
  const styles = useStyles();
  const [selfFocus, setSelFocus] = useState(false);
  const { onHoverItem, onPlaceBet } = useBetService();
  const { box_width, box_height } = useContext(BoardContext);
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem(item);
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  return (
    <View
      style={[
        styles.v_item_container,
        {
          width: box_width * 4,
          height: box_height,
        },
      ]}
    >
      <Pressable
        style={[styles.btn_item, selfFocus && styles.active_style]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <Text
            allowFontScaling
            numberOfLines={1}
            selectable={false}
            style={[styles.text, { fontSize: 0.47 * box_height }]}
          >
            {item.placeholder}
          </Text>
          <ChipToken cell={item} BoxSize={box_height} />
        </View>
      </Pressable>
      {showWinStatus && item.valueSplit.includes(winNumber) && (
        <WinningMark
          {...{
            animateStyle: {
              width: box_width * 4,
              height: box_height,
            },
          }}
        />
      )}
    </View>
  );
};

export default SecondItem;
