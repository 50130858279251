import React from 'react';
import { View, ScrollView, Text } from 'react-native';
import { useRouletteService } from '@src/store/hooks';
import HoverProvider from './hover-provider';
import useStyles from './styles.css';
import BetOption from './bet-option';
import Tables from './tables';

function RightPanel() {
  const styles = useStyles();
  const { stopBetting } = useRouletteService();
  return (
    <HoverProvider>
      <View style={styles.container}>
        <ScrollView style={styles.scrollview}>
          <Tables />
          <BetOption />
          {stopBetting && (
            <View style={styles.v_table_close}>
              <Text style={styles.t_table_close}>No more bets</Text>
            </View>
          )}
        </ScrollView>
      </View>
    </HoverProvider>
  );
}

export default RightPanel;
