import React from 'react';
import { LayerBottomData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../provider';
import { Layer } from 'react-konva';
import Items from './items';

const LayerBottom = () => {
  const layerRef = React.useRef<any>();
  const { canvas_width, canvas_height, box_height } =
    React.useContext(RacetrackContext);

  React.useEffect(() => {
    layerRef.current.to({
      x: canvas_width * 0.17837,
      y: canvas_height * 0.78609,
      duration: 0.4,
    });
  }, [canvas_width, canvas_height, box_height]);

  return (
    <Layer ref={layerRef}>
      {LayerBottomData.map((item, index) => {
        return <Items key={index.toString()} {...{ item, index }} />;
      })}
    </Layer>
  );
};

export default LayerBottom;
