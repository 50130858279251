import React from 'react';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  Easing,
  withRepeat,
} from 'react-native-reanimated';
import useStyles from './styles.css';

const WinningMark = (props: any) => {
  const styles = useStyles();
  const animate = useSharedValue(0);

  React.useEffect(() => {
    animate.value = withRepeat(
      withTiming(1, { duration: 600, easing: Easing.linear }),
      -1,
      false
    );
  }, []);

  const opacityStyle = useAnimatedStyle(() => {
    return {
      opacity: animate.value,
    };
  }, []);

  return (
    <Animated.View
      pointerEvents={'none'}
      style={[
        styles.v_mask,
        props.isFirst && styles.borderTopLeft,
        props.isLast && styles.borderBottomLeft,
        props.animateStyle,
        opacityStyle,
      ]}
    />
  );
};

export default React.memo(WinningMark);
