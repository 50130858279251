import React from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from '@expo/vector-icons/MaterialIcons';
import useStyles from './styles.css';
import colors from '@assets/colors';

interface IconButtonProps {
  name: keyof typeof Icon.glyphMap;
  onPress: () => void;
}

const IconButton = ({ name, onPress }: IconButtonProps) => {
  const styles = useStyles();

  return (
    <TouchableOpacity onPress={onPress} style={styles.btnStyle}>
      <Icon name={name} size={20} color={colors.white} />
    </TouchableOpacity>
  );
};

export default IconButton;
