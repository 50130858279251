// DUCKS pattern
import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@src/store';
import * as Types from '../types';

export interface UserState {
  loading: boolean;
  details: Types.UserDetails;
  error: Types.Error;
  sessionExpired: boolean;
}

const initialState: UserState = {
  loading: false,
  sessionExpired: false,
  details: {},
} as UserState;

// Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchingUserDetails: (state) => {
      state.sessionExpired = false;
      state.loading = true;
    },
    fetchUserDetailsSuccess: (state, action) => {
      state.details = action.payload;
      state.loading = false;
    },
    fetchUserDetailsFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    sessionExpired: (state) => {
      state.sessionExpired = true;
      state.loading = false;
    },
    updateUserBalance: (state, action) => {
      state.details = { ...state.details, balance: action.payload };
    },
    resetError: () => {
      return initialState;
    },
  },
});

// Actions
export const userActions = {
  fetchingUserDetails: createAction(
    `${userSlice.name}/fetchingUserDetails`,
    (params: Types.Auth) => ({
      payload: params,
    })
  ),
  fetchUserDetailsSuccess: userSlice.actions.fetchUserDetailsSuccess,
  fetchUserDetailsFailed: userSlice.actions.fetchUserDetailsFailed,
  sessionExpired: userSlice.actions.sessionExpired,
  updateUserBalance: userSlice.actions.updateUserBalance,
  resetError: userSlice.actions.resetError,
};

// Selectors
export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUserDetails = (state: RootState) => state.user.details;
export const selectUserBalance = createSelector(
  (state: RootState) => state.user?.details ?? {},
  (details) => details?.balance ?? 0
);
export const selectUserFullname = createSelector(
  (state: RootState) => state.user.details,
  (user) => `${user.firstName || ''} ${user.lastName || ''}`.trim()
);
export const selectUserID = createSelector(
  (state: RootState) => state.user.details,
  (user) => ({
    playerID: user.userID,
    playerSessionID: user.playerSessionID,
  })
);

export const selectUserFailed = (state: RootState) => state.user.error;
export const selectSessionExpired = (state: RootState) =>
  state.user.sessionExpired || false;

// Reducer
export default userSlice.reducer;
