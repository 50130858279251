import React from 'react';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useAuthService } from '@src/store/hooks';
import { useTranslation } from 'react-i18next';
import colors from '@assets/colors';

const Submit = (props: any) => {
  const { onPress } = props;
  const styles = useStyles();
  const { t } = useTranslation();
  const { failed, isLoading } = useAuthService();

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={onPress}
      disabled={isLoading}
      style={[styles.container]}
    >
      {isLoading ? (
        <ActivityIndicator size="small" animating color={'black'} />
      ) : (
        <Text style={[styles.labelStyle]}>{t('login.submit')}</Text>
      )}
    </TouchableOpacity>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 40,
      width: 100,
      marginTop: 20,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blue,
    },
    imageStyle: {
      width: '100%',
      height: '100%',
      borderRadius: 5,
      position: 'absolute',
    },
    loadStyle: { alignItems: 'center', justifyContent: 'center' },
    labelStyle: {
      zIndex: 2,
      fontSize: 15,
      color: colors.black,
      fontFamily: 'DMSans-Bold',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        minWidth: '100%',
        maxWidth: '100%',
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        minWidth: '100%',
        maxWidth: '100%',
      },
    },
  }
);

export default Submit;
