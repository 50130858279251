import React from 'react';
import { RouletteContext } from '../../RouletteProvider';
import { IconButton } from '@src/components/button';
import useStyles from './styles.css';
import { View, Image } from 'react-native';
import images from '@assets/images';
import { useAppStateService } from '@root/src/store/hooks';

const Middle = () => {
  const styles = useStyles();
  const { isLandscape } = useAppStateService();
  const { isRecetrack, onChangeTable } = React.useContext(RouletteContext);

  return (
    <View style={styles.container}>
      <IconButton
        onPress={onChangeTable}
        style={[
          styles.btn_table,
          isLandscape && {
            width: 50,
            height: 50,
            transform: [{ rotate: '-90deg' }],
          },
        ]}
      >
        <Image
          style={[styles.i_table]}
          source={isRecetrack ? images.ic_racetrack : images.ic_table}
          resizeMode="contain"
        />
      </IconButton>
    </View>
  );
};

export default Middle;
