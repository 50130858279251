import React, { useContext } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import colors from '@assets/colors';
import images from '@assets/images';

import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { TableContext } from '../provider';
const AnimatedImage = Animated.createAnimatedComponent(Image);

const Diamond = ({ isBlack }: any) => {
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const screenSize = useContext(TableContext);

  React.useEffect(() => {
    boxWidth.value = withTiming(screenSize.box_width, { duration: 500 });
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      width: boxWidth.value,
      height: boxHeight.value * 2,
      transform: [{ rotate: '270deg' }],
    };
  }, []);

  return (
    <View style={styles.container}>
      <AnimatedImage
        style={animateStyle}
        source={isBlack ? images.diamond_black : images.diamond_red}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: 'center', justifyContent: 'center' },
  diamondNarrow: {
    // transform: [{ rotate: '270deg' }],
  },
  diamondNarrowTop: {
    width: 0,
    height: 0,
    borderTopWidth: 25,
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderLeftWidth: 25,
    borderRightColor: 'transparent',
    borderRightWidth: 25,
    borderBottomColor: 'red',
    borderBottomWidth: 35,
  },
  diamondNarrowBottom: {
    width: 0,
    height: 0,
    borderTopWidth: 35,
    borderTopColor: 'red',
    borderLeftColor: 'transparent',
    borderLeftWidth: 25,
    borderRightColor: 'transparent',
    borderRightWidth: 25,
    borderBottomColor: 'transparent',
    borderBottomWidth: 25,
  },
  v_chip_container: {
    zIndex: 1,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  i_chip: { width: '100%', height: '100%' },
  v_bet_container: { position: 'absolute', zIndex: 1 },
  t_bet_amount: {
    color: colors.white,
    fontFamily: 'DMSans-Bold',
  },
});

export default Diamond;
