import React from 'react';
import LeftPanelProvider from './provider';
import MainScreen from './main-screen';

const LeftPanel = () => {
  return (
    <LeftPanelProvider>
      <MainScreen />
    </LeftPanelProvider>
  );
};

export default React.memo(LeftPanel);
