import React from 'react';
import { ChipProvider } from './ChipProvider';
import ChipScreen from './ChipScreen';

const ChipBoard = () => {
  return (
    <ChipProvider>
      <ChipScreen />
    </ChipProvider>
  );
};

export default ChipBoard;
