import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      top: 10,
      width: 100,
      height: 70,
      zIndex: 100,
      position: 'absolute',
      backgroundColor: colors.black,
    },
    video: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    btn_active: {
      zIndex: 100,
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    v_bar: {
      height: 50,
      width: '100%',
      bottom: 0,
      flexDirection: 'row',
      position: 'absolute',
      alignItems: 'center',
    },
    linearStyle: {
      width: '100%',
      height: '100%',
    },
    btn_cam: {
      left: 15,
      width: 30,
      height: 30,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_camera: {
      zIndex: 2,
      position: 'absolute',
      fontFamily: 'Roboto-Bold',
      marginRight: 4,
      fontSize: 11,
      color: 'black',
    },
    btn_fullscreen: { position: 'absolute', right: 15 },

    loading_style: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_failed: {
      fontFamily: 'Roboto',
      position: 'absolute',
      fontSize: 20,
      color: 'white',
      textAlign: 'center',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
