import React from 'react';
import { RouletteContext } from '../../RouletteProvider';
import { View } from 'react-native';
import HoverProvider from './hover-provider';
import RacetrackProvider from './provider';
import SideBar from './sidebar-action';
import useStyles from './styles.css';
import Canvas from './canvas';

const Racetrack = () => {
  const styles = useStyles();
  const { isRecetrack } = React.useContext(RouletteContext);

  return (
    <RacetrackProvider>
      <HoverProvider>
        <View style={[styles.container, !isRecetrack && styles.disable]}>
          <SideBar />
          <View style={styles.v_canvas}>
            <Canvas />
          </View>
        </View>
      </HoverProvider>
    </RacetrackProvider>
  );
};

export default Racetrack;
