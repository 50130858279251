import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import TableView from './tableview';
import TableLandscape from './tableview-landscape';
import RacetrackLandscape from './racetrack-landscape';
import Racetrack from './racetrack';
import ChipBoard from '../chipboard';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectStopBetting } from '@src/store/slices';
import { useAppStateService } from '@root/src/store/hooks';

const BoardMain = () => {
  const styles = useStyles();
  const { isLandscape } = useAppStateService();
  const stopBetting = useAppSelector(selectStopBetting);

  if (isLandscape) {
    return (
      <View style={styles.landscape_container}>
        <View
          pointerEvents={stopBetting ? 'box-only' : 'auto'}
          style={styles.v_landscape}
        >
          <RacetrackLandscape />
          <TableLandscape />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View
        pointerEvents={stopBetting ? 'box-only' : 'auto'}
        style={styles.v_left}
      >
        <Racetrack />
        <TableView />
      </View>
      <View style={styles.v_right} />
      <ChipBoard />
    </View>
  );
};

export default BoardMain;
