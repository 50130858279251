import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StatusBar } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      top: StatusBar.currentHeight,
      height: 80,
      zIndex: 20,
      width: '100%',
      position: 'absolute',
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 40,
      justifyContent: 'space-between',
    },
    v_logo: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 30,
    },
    i_logo: { width: 160, height: 55, marginTop: 2 },
    v_languange: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    t_choose_lang: {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: colors.white,
      marginRight: 20,
    },
    v_selector: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 40,
      borderWidth: 1,
      borderRadius: 20,
      paddingVertical: 3,
      paddingHorizontal: 10,
      borderColor: colors.blue,
      justifyContent: 'center',
    },
    t_language: {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: 'white',
      marginRight: 20,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      i_logo: {
        width: 200,
        height: 200,
      },
    },
    [DEVICE_SIZES.XS]: {
      i_logo: {
        width: 200,
        height: 200,
      },
    },
  }
);

export default useStyles;
