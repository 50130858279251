import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
    },
    v_canvas: {
      width: '100%',
      height: '100%',
      paddingRight: '10%',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    disable: { display: 'none' },
    activeStyle: { backgroundColor: colors.transparent },
  },
  {
    [DEVICE_SIZES.MD]: {
      v_canvas: {
        paddingRight: 0,
      },
    },
    [DEVICE_SIZES.SM]: {
      v_canvas: {
        paddingRight: '10%',
      },
    },
    [DEVICE_SIZES.XS]: {
      v_canvas: {
        paddingRight: '10%',
      },
    },
  }
);

export default useStyles;
