import React from 'react';
import { IconButton } from '@src/components/button';
import SettingsPopup from './settings-popup';
import useStyles from './styles.css';
import { View } from 'react-native';
import { LeftPanelContext } from '../provider';

const Options = (props: any) => {
  const styles = useStyles();
  const [muted, setMuted] = React.useState(true);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const { onRefreshStreaming } = React.useContext(LeftPanelContext);

  const onFullScreen = () => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => console.log('Document Exited from Full screen mode'))
        .catch((err) => console.error(err));
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const onChangeAudio = () => {
    if (muted) {
      props?.stream?.unmuteRemoteAudio();
      setMuted(false);
    } else {
      props?.stream?.muteRemoteAudio();
      setMuted(true);
    }
  };

  const onFullscreenChange = () => {
    setFullscreen(Boolean(document.fullscreenElement));
  };

  React.useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  return (
    <View style={[styles.container]}>
      <IconButton
        size={16}
        name={'refresh'}
        onPress={onRefreshStreaming}
        style={styles.btnStyle}
      />
      <IconButton
        size={14}
        onPress={onChangeAudio}
        style={styles.btnStyle}
        name={muted ? 'volume-off' : 'volume-down'}
      />
      <IconButton
        size={14}
        name={'settings'}
        style={styles.btnStyle}
        onPress={() => setShowSettings(!showSettings)}
      />
      <IconButton
        size={16}
        onPress={onFullScreen}
        name={fullscreen ? 'fullscreen-exit' : 'fullscreen'}
        style={styles.btnStyle}
      />
      <SettingsPopup
        visible={showSettings}
        onClose={() => setShowSettings(false)}
      />
    </View>
  );
};

export default React.memo(Options);
