import React from 'react';
import {
  View,
  Pressable,
  StyleSheet,
  Text,
  ActivityIndicator,
} from 'react-native';
import { colors } from '@assets/index';
import { Button01Types } from './types';

const Button = (props: Button01Types) => {
  if (props.isLoading) {
    return (
      <View style={[styles.container, props.style]}>
        <ActivityIndicator
          size="small"
          color={props.color || 'black'}
          animating={props.isLoading}
        />
      </View>
    );
  }

  return (
    <Pressable
      onPress={props.onPress}
      disabled={props.disabled}
      style={[styles.container, props.style]}
    >
      <Text selectable={false} style={[styles.labelStyle, props.labelStyle]}>
        {props.label}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 48,
    borderRadius: 8,
    paddingHorizontal: 28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blue,
  },
  labelStyle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.white,
  },
});

export default Button;
