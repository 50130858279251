import React, { createContext } from 'react';
import { useWindowDimensions } from 'react-native';
interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  canvas_width: number;
  canvas_height: number;
  box_width: number;
  box_height: number;
  spaces: number;
}

export const RacetrackContext = createContext<ContextValue>({} as ContextValue);

const RacetrackProvider = ({ children }: ProviderProps) => {
  const { width } = useWindowDimensions();
  const parent_width = width * 0.4;
  const minWidth = parent_width > 400 ? parent_width : 400;
  const canvas_width = minWidth * 0.85;
  const canvas_height = canvas_width * 0.37;
  const box_width = canvas_width * 0.05481 - 0.5;
  const box_height = canvas_height * 0.2139 - 0.5;
  const spaces = canvas_width * 0.0047;

  return (
    <RacetrackContext.Provider
      value={{ canvas_width, canvas_height, box_width, box_height, spaces }}
    >
      {children}
    </RacetrackContext.Provider>
  );
};

export default RacetrackProvider;
