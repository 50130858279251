// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@src/store';

export interface InitialStateValue {
  CONNECTED: boolean;
  CONNECTING: boolean;
  DISCONNECTED: boolean;
  RECONNECTING: boolean;
  CONNECT_ERROR: any;
  RECONNECT_ERROR: any;
  RECONNECTING_ATTEMPT: string;
}

const initialState: InitialStateValue = {
  CONNECTED: false,
  CONNECTING: false,
  DISCONNECTED: false,
  RECONNECTING: false,
  CONNECT_ERROR: {},
  RECONNECT_ERROR: {},
  RECONNECTING_ATTEMPT: '',
};

// Slice
export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    socketConnecting: (state) => {
      state.CONNECTING = true;
    },
    socketConnected: (state) => {
      state.CONNECTED = true;
      state.DISCONNECTED = false;
      state.RECONNECTING = false;
    },
    socketDisconnected: (state, action) => {
      state.CONNECTED = false;
      state.DISCONNECTED = true;
      state.RECONNECTING = false;
      state.CONNECT_ERROR = action.payload;
    },
    socketReconnecting: (state) => {
      state.CONNECTED = false;
      state.DISCONNECTED = false;
      state.RECONNECTING = true;
      state.CONNECT_ERROR = {};
    },
    socketError: (state, action) => {
      state.CONNECTED = false;
      state.DISCONNECTED = true;
      state.RECONNECTING = false;
      state.CONNECT_ERROR = action.payload;
    },
    socketReconnectingError: (state, action) => {
      state.CONNECTED = false;
      state.DISCONNECTED = false;
      state.RECONNECTING = false;
      state.RECONNECT_ERROR = action.payload;
    },
  },
});

// Actions
export const socketActions = {
  socketConnecting: socketSlice.actions.socketConnecting,
  socketConnected: socketSlice.actions.socketConnected,
  socketDisconnected: socketSlice.actions.socketDisconnected,
  socketReconnecting: socketSlice.actions.socketReconnecting,
  socketError: socketSlice.actions.socketError,
  socketReconnectingError: socketSlice.actions.socketReconnectingError,
};

// Selectors
export const selectConnected = (state: RootState) => state.socket.CONNECTED;
export const selectDisconnected = (state: RootState) =>
  state.socket.DISCONNECTED;
export const selectReconnecting = (state: RootState) =>
  state.socket.RECONNECTING;
export const selectConnectError = createSelector(
  (state: RootState) => state.socket.CONNECT_ERROR,
  (state) => state.message || state.error || 'something went wrong'
);
export const selectReconnectError = createSelector(
  (state: RootState) => state.socket.RECONNECT_ERROR,
  (state) => state.message || state.error || 'something went wrong'
);

// Reducer
export default socketSlice.reducer;
