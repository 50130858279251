import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 75,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingHorizontal: 25,
      backgroundColor: colors.black1,
    },
    v_logo: {
      width: 150,
      height: '100%',
      alignItems: 'center',
    },
    i_logo: { width: 120, height: 50 },
    v_middle: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    v_menu: {
      display: 'none',
    },
    v_table_wrapper: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      justifyContent: 'flex-start',
    },
    v_table_details: { flexDirection: 'row', paddingHorizontal: 20 },
    t_table_label: {
      fontFamily: 'Poppins',
      fontSize: 18,
      color: colors.white,
    },
    t_table_value: {
      fontFamily: 'Poppins',
      fontSize: 18,
      color: colors.blue,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        justifyContent: 'space-between',
      },
      v_middle: {
        display: 'none',
      },
      v_menu: {
        display: 'flex',
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        justifyContent: 'space-between',
      },
      v_middle: {
        display: 'none',
      },
      v_menu: {
        display: 'flex',
      },
    },
  }
);

export default useStyles;
