/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Pressable, TouchableOpacity, Text } from 'react-native';
import { useAppDispatch, useAppSelector } from '@root/src/store/hooks/hooks';
import { rouletteActions, selectedCamera } from '@root/src/store/slices';
import { LinearGradient } from 'expo-linear-gradient';
import Icon from '@expo/vector-icons/MaterialIcons';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { VideoContext } from './provider';
import useStyles from './styles.css';
import './style.css';

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const ActionButton = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const timeout = React.useRef<any>();
  const animateopacity = useSharedValue(0);
  const camera = useAppSelector(selectedCamera);
  const { focus, setFocus, active, setActive, fullscreen, setFullScreen } =
    React.useContext(VideoContext);

  const onSwitchCamera = React.useCallback(() => {
    dispatch(rouletteActions.switchCamera());
  }, [dispatch]);

  const animateOpacity = useAnimatedStyle(() => {
    return {
      flex: 1,
      opacity: animateopacity.value,
    };
  }, []);

  React.useEffect(() => {
    animateopacity.value = withTiming(focus ? 1 : 0, { duration: 400 });
    if (focus) {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = null;
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current);
        setFocus(false);
      }, 3000);
    }
  }, [focus]);

  return (
    <AnimatedButton
      pointerEvents={'auto'}
      style={animateOpacity}
      onPress={() => setFocus(!focus)}
    >
      <View
        pointerEvents={focus ? 'auto' : 'none'}
        style={[styles.v_bar, { top: 0 }]}
      >
        <LinearGradient
          style={[styles.linearStyle]}
          colors={['rgba(0,0,0,0.4)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']}
        />
        <TouchableOpacity
          onPress={() => {
            setActive(!active);
            setFocus(false);
          }}
          style={styles.btn_fullscreen}
        >
          <Icon name="expand-more" size={30} color="white" />
        </TouchableOpacity>
      </View>

      <View pointerEvents={focus ? 'auto' : 'none'} style={styles.v_bar}>
        <LinearGradient
          style={[styles.linearStyle, { transform: [{ rotate: '180deg' }] }]}
          colors={['rgba(0,0,0,0.4)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']}
        />
        <TouchableOpacity onPress={onSwitchCamera} style={styles.btn_cam}>
          <Icon name="videocam" size={30} color="white" />
          <Text style={styles.t_camera}>{camera}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setFullScreen(!fullscreen);
            setFocus(false);
          }}
          style={styles.btn_fullscreen}
        >
          <Icon
            name={fullscreen ? 'fullscreen-exit' : 'fullscreen'}
            size={30}
            color="white"
          />
        </TouchableOpacity>
      </View>
    </AnimatedButton>
  );
};

export default ActionButton;
