// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@src/store';
import _ from 'lodash';
import * as Types from '../types';

interface RouletteState {
  loading: boolean;
  error: Types.Error;
  table: [];
  lastNumbers: [];
  tableItem: Types.TableItem;
  game_status: Types.EventType;
  socket_response: Types.SocketMessage;
  winNumber: number;
  betHistory: [];
  camera: number;
}

const initialState: RouletteState = {
  loading: false,
  table: [],
  lastNumbers: [],
  error: {} as Types.Error,
  tableItem: {} as Types.TableItem,
  game_status: 0 as Types.EventType,
  camera: 1,
  socket_response: {},
} as RouletteState;

// Slice
export const rouletteSlice = createSlice({
  name: 'roulette',
  initialState,
  reducers: {
    fetchTable: (state) => {
      state.loading = true;
    },
    fetchingTableDetails: (state) => {
      state.loading = true;
    },
    fetchTableDetailsSuccess: (state, action) => {
      state.tableItem = action.payload;
      state.error = {} as Types.Error;
      state.loading = false;
      if (!_.isEmpty(action.payload)) {
        state.game_status = Number(action.payload.currentState);

        state.socket_response = {
          Client: {
            Id: action.payload.wheelID,
            Session: action.payload.sessionID,
            ReportNumber: Number(action.payload.sessionID),
          },
          State: {
            Session: action.payload.roundNo,
          },
        };
      }
    },
    fetchTableDetailsFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchLastNumberSuccess: (state, action) => {
      state.lastNumbers = action.payload;
    },
    updateGameStatus: (state, action) => {
      if (action.payload.Event === 'newGame') {
        state.game_status = Types.EventType.NEW_GAME;
      } else if (action.payload.Event === 'spin') {
        state.game_status = Types.EventType.SPIN;
      } else if (action.payload.Event === 'waitingNumber') {
        state.game_status = Types.EventType.WAITING;
      } else if (action.payload.Event === 'win') {
        state.game_status = Types.EventType.WIN;
        let newNumber: any = [
          {
            winNumber: action.payload?.State?.LastNumber,
            tableName: null,
            postedBy: '1',
            tableID: 1,
          },
        ];
        state.lastNumbers = newNumber.concat(state.lastNumbers);
      }
      state.socket_response = action.payload;
      state.winNumber = action.payload?.State?.LastNumber;
    },
    switchCamera: (state) => {
      state.camera = state.camera === 1 ? 2 : 1;
    },
    resetError: () => {
      return initialState;
    },
  },
});

// Actions
export const rouletteActions = {
  requestConnection: createAction(
    `${rouletteSlice.name}/requestConnection`,
    (params: Types.TableId) => ({
      payload: params,
    })
  ),
  fetchTable: rouletteSlice.actions.fetchTable,
  fetchingTableDetails: createAction(
    `${rouletteSlice.name}/fetchingTableDetails`,
    (params: Types.TableId) => ({
      payload: params,
    })
  ),
  fetchTableDetailsSuccess: rouletteSlice.actions.fetchTableDetailsSuccess,
  fetchTableDetailsFailed: rouletteSlice.actions.fetchTableDetailsFailed,
  fetchLastNumberSuccess: rouletteSlice.actions.fetchLastNumberSuccess,
  resetError: rouletteSlice.actions.resetError,
  updateGameStatus: rouletteSlice.actions.updateGameStatus,
  switchCamera: rouletteSlice.actions.switchCamera,
};

// Selectors
export const selectTableDetails = (state: RootState) =>
  state.roulette.tableItem;
export const selectLastNumbers = (state: RootState) =>
  state.roulette.lastNumbers;
export const selectTableDetailsLoad = (state: RootState) =>
  state.roulette.loading;
export const selectedCamera = (state: RootState) => state.roulette.camera;
export const selectedSocketResponse = (state: RootState) =>
  state.roulette.socket_response;
export const selectSessionID = createSelector(
  (state: RootState) => state.roulette.socket_response,
  (state) => state?.Client?.Session ?? ''
);
export const selectSartBetting = createSelector(
  (state: RootState) => state.roulette.game_status,
  (state) => state === Types.EventType.NEW_GAME
);
export const selectFinalBetting = createSelector(
  (state: RootState) => state.roulette.game_status,
  (state) => state === Types.EventType.SPIN
);
export const selectStopBetting = createSelector(
  (state: RootState) => state.roulette.game_status,
  (state) => [0, 3, 4, 6].includes(state)
);
export const selectWinNumber = (state: RootState) => state.roulette.winNumber;
export const selectWinNumberStatus = createSelector(
  (state: RootState) => state.roulette.game_status,
  (state) => state === Types.EventType.WIN
);

// Reducer
export default rouletteSlice.reducer;
