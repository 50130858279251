/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Text, View } from 'react-native';
import { useAppStateService, useBetService } from '@src/store/hooks';
import { useAppSelector } from '@src/store/hooks/hooks';
import { IconButton } from '@src/components/button';
import {
  selectedChipMask,
  selectedChipTotal,
  selectStopBetting,
} from '@src/store/slices';
import { ChipContext } from '../ChipProvider';
import SelectedChip from './SelectedChip';
import useStyles from './styles.css';
import _ from 'lodash';

const MiddleOptions = () => {
  const styles = useStyles();
  const { isLandscape } = useAppStateService();
  const chipmask = useAppSelector(selectedChipMask);
  const chiptotal = useAppSelector(selectedChipTotal);
  const stopBetting = useAppSelector(selectStopBetting);
  const { onChangeShowChips } = React.useContext(ChipContext);
  const { onUndoBet, onDoubleBet, onDeleteAllBet } = useBetService();

  const onUndo = () => {
    const lastEntry = _.last(chipmask);
    onUndoBet(lastEntry);
  };

  const onSubmit = () => {
    const betArray: any = [];
    chiptotal.forEach((value: any) => {
      betArray.push({
        key: value.item.key,
        type: value.item.type,
        value: value.item.value || value.item.valueSplit,
        betAmountTotal: value.total,
      });
    });
  };

  return (
    <View
      style={[
        styles.container,
        { display: stopBetting ? 'none' : 'flex' },
        isLandscape && { flexDirection: 'row' },
      ]}
    >
      <IconButton onPress={onUndo} name={'redo'} style={styles.btnStyle} />
      <IconButton onPress={onDoubleBet} style={styles.btnStyle}>
        <Text selectable={false} style={styles.ab_t_x2}>
          x2
        </Text>
      </IconButton>
      <SelectedChip onPress={onChangeShowChips} />
      <IconButton
        onPress={onSubmit}
        name={'autorenew'}
        style={styles.btnStyle}
      />
      <IconButton
        onPress={onDeleteAllBet}
        name={'close'}
        color={'red'}
        style={styles.btnStyle}
      />
    </View>
  );
};

export default MiddleOptions;
