import React, { memo, useState } from 'react';
import {
  View,
  Text,
  TextInput,
  Keyboard,
  TextStyle,
  TextInputProps,
  TouchableOpacity,
} from 'react-native';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import useStyles from './styles.css';
import { colors } from 'theme';
import _ from 'lodash';

interface InputProps extends TextInputProps {
  icon?: string;
  error?: string;
  error2?: string;
  inputStyle?: TextStyle;
  borderStyle?: TextStyle;
  placeholder?: string;
  label?: string;
  labelStyle?: TextStyle;
  hasPassword?: boolean;
  errorStyle?: TextStyle;
}

const Input = (props: InputProps) => {
  const styles = useStyles();
  const [isFocus, setFocus] = useState(false);
  const { onChangeText, error, hasPassword } = props;
  const [showPass, setShowPass] = useState(!!hasPassword);
  const errLabelStyle = error ? { color: colors.red } : {};
  const errBorderStyle: any = error && {
    borderColor: colors.red,
  };
  return (
    <View style={[styles.marginTop20, props.style]}>
      <View style={styles.v_label}>
        <Text style={[styles.label, errLabelStyle]}>{props.label}</Text>
        {_.isEmpty(error) ? null : (
          <Text style={[styles.txtError]}>{error}</Text>
        )}
      </View>
      <View pointerEvents={'box-none'} style={[styles.border, errBorderStyle]}>
        <TextInput
          style={[styles.input]}
          returnKeyType="done"
          autoComplete="off"
          autoCorrect={false}
          value={props.value}
          secureTextEntry={showPass}
          numberOfLines={1}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          keyboardType={props.keyboardType}
          placeholder={isFocus ? '' : props.placeholder}
          placeholderTextColor={colors.grayPrimary}
          onSubmitEditing={Keyboard.dismiss}
          onChangeText={onChangeText}
        />
        {hasPassword && (
          <TouchableOpacity
            onPress={() => setShowPass(!showPass)}
            style={styles.btn_icon}
          >
            <Icon
              name={showPass ? 'eye-off' : 'eye'}
              color={colors.grayPrimary}
              size={24}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default memo(Input);
