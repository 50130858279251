import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '40%',
      height: '100%',
      minWidth: 400,
      backgroundColor: colors.black2,
    },
    scrollview: { height: 500 },

    // TABLES
    table_container: {
      paddingTop: 20,
      paddingBottom: 20,
      // height: '60%',
      width: '100%',
      minWidth: 300,
      minHeight: 250,
      alignItems: 'center',
      borderBottomWidth: 2,
      borderBottomColor: colors.offwhite,
    },

    // BET OPTIONS
    bo_container: {
      width: '100%',
      minHeight: 300,
      paddingBottom: 30,
      alignItems: 'center',
    },
    bo_t_betoption: {
      fontSize: 24,
      marginTop: 20,
      color: colors.white,
      fontFamily: 'Poppins',
    },
    i_mask: {
      position: 'absolute',
      width: 120,
      height: 120,
      bottom: 0,
      right: 0,
    },

    v_table_close: {
      backgroundColor: colors.translucent2,
      position: 'absolute',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_table_close: {
      fontFamily: 'Roboto-Medium',
      fontSize: 18,
      color: 'white',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
