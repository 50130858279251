import { Platform } from 'react-native';

export default {
  colorPrimary: '#1E1E1E',
  border: '#999999',
  border1: '#E9E9E9',
  border2: '#CCCCCC',
  border3: '#C3CADC',
  border4: '#D4D4D4',
  border5: '#dadce0',
  black: 'black',
  black1: '#080808',
  black2: 'rgba(30, 30, 30, 1)',
  black3: '#212221',
  black4: 'rgba(0, 0, 0,0.8)',
  black5: '#222222',
  blue: '#1073F3',
  blue1: 'rgba(16, 115, 243, 0.2)',
  brown: 'brown',
  brown1: '#e0bd87',
  brown2: 'rgba(200, 161, 62, 0.3)',
  brown3: 'rgba(200, 161, 62, 0.9)',
  green: 'green',
  green1: '#035413',
  green2: '#1B7051',
  grayPrimary: '#A3A3A3',
  gray1: '#757575',
  orange: 'orange',
  orange1: '#FF9F00',
  paleMarigold: '#FFBA45',
  red: 'red',
  red1: 'rgba(250, 5, 5,0.8)',
  skyblue: 'skyblue',
  skyblue1: 'rgba(47,104,137,0.80)',
  skyblue2: 'rgba(40,128,178,0.80)',
  white: 'white',
  offwhite: 'rgba(255,255,255,0.1)',
  offwhite2: 'rgba(255,255,255,0.2)',
  transparent: 'transparent',
  translucent1: 'rgba(0, 0, 0, 0.08)',
  translucent2: 'rgba(0, 0, 0, 0.4)',
  placeholder: Platform.OS === 'ios' ? '#C7C7CD' : '#a8a8a8',
  btnBG: '#1E9CB9',
  text01: '#7B7B7B',
  yellow: 'yellow',
};
