import React, { useState, useCallback, createContext } from 'react';
import { getWebSocketMessage } from '@root/src/utils/socket-helper';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Reconnecting from '@src/components/popups/reconnecting';
import { useRouletteService, useSaveBetService } from '@root/src/store/hooks';
import PulseLoading from '@root/src/components/loading';
import Config from '@root/src/utils/Config';
import { StyleSheet } from 'react-native';
import { colors } from '@root/assets';
import { useAppDispatch, useAppSelector } from '@root/src/store/hooks/hooks';
import {
  rouletteActions,
  selectTableDetailsLoad,
} from '@root/src/store/slices';

interface ContextValue {}

const SocketContext = createContext<ContextValue>({} as ContextValue);

interface BoardProviderProps {
  children: React.ReactElement;
}

const SocketProvider = ({ children }: BoardProviderProps) => {
  const dispatch = useAppDispatch();

  const didUnmount = React.useRef<any>(false);
  const { user, onSaveBets } = useSaveBetService();
  const isLoading = useAppSelector(selectTableDetailsLoad);

  const { lastMessage, readyState } = useWebSocket(Config.API_WEBSOCKET_URL, {
    queryParams: {
      source: 'FE_WebApp',
      tableid: 3,
      playerid: user.playerID,
    },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  console.log('connectionStatus', connectionStatus);
  console.log('lastMessage', lastMessage);

  React.useEffect(() => {
    if (lastMessage?.data) {
      const message = getWebSocketMessage(lastMessage?.data);
      console.log('messsage', message);

      if (message.Event) {
        dispatch(rouletteActions.updateGameStatus(message));
      }

      if (message.Event === 'waitingNumber') {
        onSaveBets({
          rouletteSessionID: message.Client.Session,
          roundNo: String(message.State.Session),
        });
      }
    }

    return () => {
      didUnmount.current = true;
    };
  }, [lastMessage]);

  const value = {};

  return (
    <SocketContext.Provider value={value}>
      {children}
      <PulseLoading
        style={styles.pulse}
        isLoading={isLoading}
        color={colors.white}
      />
      {/* <Reconnecting visible={connectionStatus === 'Connecting'} /> */}
    </SocketContext.Provider>
  );
};

const styles = StyleSheet.create({
  pulse: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.black,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export { SocketProvider, SocketContext };
