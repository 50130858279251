/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Pressable, useWindowDimensions } from 'react-native';
import Animated, {
  withTiming,
  interpolate,
  Extrapolation,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useAppStateService } from '@root/src/store/hooks';
import { RouletteContext } from '../RouletteProvider';
import ActionButton from './action-button';
import { VideoContext } from './provider';
import useStyles from './styles.css';
import Video from './video';
import './style.css';

const AnimatedButton = Animated.createAnimatedComponent(Pressable);
const BOX_HEIGHT = 40;

const VPlayer = () => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const { isLandscape } = useAppStateService();
  const { width, height } = useWindowDimensions();
  const { cameraview } = React.useContext(RouletteContext);
  const { active, setActive, fullscreen, setFocus } =
    React.useContext(VideoContext);

  const { transX_start, transX, transY_start, transY, big_width, big_height } =
    React.useMemo(() => {
      const tY_start = isLandscape ? height - 140 : 0;
      const tX_start = isLandscape ? 20 : 10;
      if (fullscreen) {
        const bheight = width / 2;
        const transy = height / 2 - bheight;

        return {
          transX_start: isLandscape ? 20 : 10,
          transX: 0,
          transY_start: tY_start,
          transY: isLandscape ? 0 : transy,
          big_width: width,
          big_height: isLandscape ? height : bheight,
        };
      } else {
        const width80percent = width * (isLandscape ? 0.6 : 0.8);
        const bheight = width80percent / 2;
        const transx = (width - width80percent) / 2;
        const transy = height / 2 - (isLandscape ? bheight / 2 : bheight) - 10;

        return {
          transX_start: tX_start,
          transX: transx,
          transY: transy,
          transY_start: tY_start,
          big_width: width80percent,
          big_height: bheight,
        };
      }
    }, [fullscreen, width, height, isLandscape]);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [transX_start, transX],
      Extrapolation.CLAMP
    );

    const translateY = interpolate(
      animated.value,
      [0, 1],
      [transY_start, transY],
      Extrapolation.CLAMP
    );

    const width_size = interpolate(
      animated.value,
      [0, 1],
      [BOX_HEIGHT * 1.5, big_width],
      Extrapolation.CLAMP
    );

    const height_size = interpolate(
      animated.value,
      [0, 1],
      [BOX_HEIGHT, big_height],
      Extrapolation.CLAMP
    );

    return {
      width: width_size,
      height: height_size,
      transform: [{ translateX }, { translateY }],
    };
  }, [transX_start, transY_start, big_width, big_height]);

  React.useEffect(() => {
    animated.value = withTiming(active ? 1 : 0, { duration: 400 });
  }, [active]);

  return (
    <Animated.View
      style={[
        styles.container,
        animateStyle,
        { display: cameraview ? 'flex' : 'none' },
      ]}
    >
      <Video
        style={styles.video}
        id="smallvideo"
        size={active ? 25 : 10}
        active={active}
      />
      {active && <ActionButton />}
      {!active && (
        <AnimatedButton
          onPress={() => {
            setActive(!active);
            setFocus(false);
          }}
          style={styles.btn_active}
        />
      )}
    </Animated.View>
  );
};

export default VPlayer;
