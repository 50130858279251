import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { BOTTOM_HEIGHT, COUNTER_HEIGHT } from '../../bottom/styles.css';
import { TOP_HEIGHT } from './win-records/styles.css';
import { colors } from '@root/assets';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    v_videoscreen: {
      width: '100%',
      height: '30%',
      backgroundColor: colors.black,
    },
    v_fullscreen: { width: '100%', height: '100%', alignItems: 'center' },
    displaynone: { display: 'none' },
    absolute: { position: 'absolute' },
    v_maskscreen: {
      flex: 1,
    },
    bottom_height: { height: BOTTOM_HEIGHT + COUNTER_HEIGHT },
    container_v2: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 2,
    },

    v_bigscreen: { flex: 1 },
    top_height: { height: TOP_HEIGHT },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      // container: {
      //   transform: [{ rotate: '90deg' }],
      // },
    },
    [DEVICE_SIZES.XS]: {
      // container: {
      //   transform: [{ rotate: '90deg' }],
      // },
    },
  }
);

export default useStyles;
