import React from 'react';
import { ConvertedChips } from '@src/utils/mockdata/Chips';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedChipTotal } from '@src/store/slices';
import { RacetrackContext } from '../../provider';
import LocalImage from './Image';

function checkAndExtractMatches(arraykeys: any, chips: any) {
  let allIncluded = true;
  let sum = 0;

  for (let item of arraykeys) {
    if (chips.has(item)) {
      if (!item.includes('single')) {
        let value = chips.get(item).total;
        sum += value;
      }
    } else {
      allIncluded = false;
    }
  }

  return {
    allIncluded: allIncluded,
    sum,
  };
}

const Token = ({ x, y, cell }: any) => {
  const chiptotal = useAppSelector(selectedChipTotal);
  const { box_width } = React.useContext(RacetrackContext);

  const arraykeys = React.useMemo(() => {
    return cell.map((obj: any) => obj.key);
  }, [cell]);

  const { amount, source } = React.useMemo(() => {
    let computed = 0;

    const result = checkAndExtractMatches(arraykeys, chiptotal);
    let chip_source = ConvertedChips(computed);

    if (result.allIncluded) {
      chip_source = ConvertedChips(result.sum);

      return { amount: result.sum, source: chip_source };
    }

    return { amount: computed, source: chip_source };
  }, [chiptotal, arraykeys]);

  if (amount <= 0) {
    return null;
  }

  const RECT_H_DIV = box_width;

  return (
    <LocalImage
      amount={amount}
      src={source}
      x={x}
      y={y}
      fontSize={box_width * 0.4}
      width={RECT_H_DIV}
      height={RECT_H_DIV}
    />
  );
};

export default React.memo(Token);
