import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '@assets/colors';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useAppDispatch, useAppSelector } from '@root/src/store/hooks/hooks';
import { betActions, selectedErrorMessage } from '@root/src/store/slices';
import _ from 'lodash';

const ErrorCenter = () => {
  const animated = useSharedValue(0);
  const dispatch = useAppDispatch();
  const timeref = React.useRef<any>();
  const error = useAppSelector(selectedErrorMessage);

  const onClose = () => {
    animated.value = withTiming(0, { duration: 500 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      dispatch(betActions.onSetError(''));
    }, 600);
  };

  const animateStyle = useAnimatedStyle(() => {
    const opacity = animated.value;

    return {
      opacity,
    };
  }, []);

  React.useEffect(() => {
    if (error) {
      animated.value = 1;
      timeref.current && clearTimeout(timeref.current);
      timeref.current = setTimeout(() => {
        timeref.current && clearTimeout(timeref.current);
        onClose();
      }, 2000);
    }
  }, [error]);

  if (_.isEmpty(error)) {
    return null;
  }

  return (
    <View pointerEvents="box-none" style={styles.container}>
      <Animated.View style={[styles.v_modal, animateStyle]}>
        <Text style={styles.t_expired}>{error || 'Insufficient Balance'}</Text>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 9999,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
  },
  v_modal: {
    minHeight: 50,
    borderRadius: 8,
    paddingHorizontal: 25,
    paddingVertical: 10,
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    backgroundColor: colors.colorPrimary,
    shadowColor: 'gray',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.4,
    shadowRadius: 30,
  },
  t_expired: {
    fontFamily: 'DMSans-Medium',
    fontSize: 18,
    color: colors.white,
    textAlign: 'center',
  },
});

export default ErrorCenter;
