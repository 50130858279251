import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnStyle: {
      width: 50,
      height: 50,
      borderRadius: 50 / 2,
      alignItems: 'center',
      borderWidth: 1,
      borderColor: colors.blue,
      justifyContent: 'center',
      marginVertical: 3,
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      btnStyle: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
      },
    },
    [DEVICE_SIZES.SM]: {
      btnStyle: {
        width: 45,
        height: 45,
        borderRadius: 45 / 2,
      },
    },
    [DEVICE_SIZES.XS]: {
      btnStyle: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
      },
    },
  }
);

export default useStyles;
