export type Post = {
  id?: string;
  title: string;
  body: string;
};

export type LoginInput = {
  username: string;
  password: string;
};

export type Auth = {
  token: string;
};

export type Error = {
  message: string;
};

export type UserDetails = {
  companyID: string;
  playerID: string;
  playerSessionID: string;
  roleID: string;
  userID: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isActived: string;
  balance: number;
};

export type TableId = {
  tableId: string;
} & Auth;

export type BetSave = {
  tableId?: string;
  rouletteSessionID?: string;
  roundNo?: string;
  playerID?: string;
  playerSessionID?: string;
  postedBy?: string;
  isPlayerRequest?: boolean;
  betArray?: any[];
} & Auth;

export interface TableItem {
  tableID: string;
  tableName: string;
  wheelID: string;
  minBet: string;
  maxBet: string;
  roundNo: string;
  image: string;
  companyID: number;
  companyName: string;
  isActive: boolean;
  camera: {
    streamServer: string;
    streamName: string;
  };
}

export enum EventType {
  NEW_GAME = 1, // START BETTING
  SPIN = 2, // FINAL BETTING
  WAITING = 3, // STOP BETTING
  WIN = 4, // SHOW WIN
}

export interface SocketMessage {
  Client?: {
    Id?: string;
    Session?: string;
    ReportNumber?: number;
  };
  Event?: string;
  State?: {
    State?: number;
    Session?: number;
    LastNumber?: number;
    RoundNo?: number;
    Error?: number;
    Rpm?: number;
    WheelDirection?: number;
  };
}
