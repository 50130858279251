import React from 'react';
import { RacetrackContext } from './provider';
import LayerBottom from './layer-bottom';
import LayerMiddle from './layer-middle';
import { Stage } from 'react-konva';
import LayerTop from './layer-top';
import LayerLeft from './layer-left';
import LayerRight from './layer-right';

const Racetrack = () => {
  const stageRef = React.useRef<any>();
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);

  React.useEffect(() => {
    stageRef.current?.to({
      x: 1,
      y: canvas_width + 10,
      width: canvas_height + 5,
      height: canvas_width + 10,
      duration: 0.4,
    });
  }, [canvas_width, canvas_height]);

  return (
    <Stage ref={stageRef} rotation={-90}>
      <LayerLeft />
      <LayerTop />
      <LayerMiddle />
      <LayerBottom />
      <LayerRight />
    </Stage>
  );
};

export default Racetrack;
