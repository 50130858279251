import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    v_drawer_container: {
      zIndex: 5,
      height: '100%',
      minWidth: 316,
      overFlowX: 'hidden',
      position: 'absolute',
    },
    v_drawer_translate: {
      zIndex: 2,
      width: '100%',
      height: '100%',
      backgroundColor: colors.colorPrimary,
    },
    linear_style: {
      flex: 1,
    },
    btn_invisible: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: colors.translucent1,
    },

    // DRAWER HEADER
    drawer_user_frame_container: {
      width: '100%',
      flexDirection: 'row',
      paddingVertical: 10,
      alignItems: 'center',
      paddingHorizontal: 10,
      justifyContent: 'flex-start',
    },
    v_details: { alignItems: 'center', marginLeft: 10 },
    user_fullname: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.white,
      fontFamily: 'DMSans-Bold',
    },
    edit_profile: {
      fontSize: 11,
      lineHeight: 20,
      color: colors.blue,
      fontFamily: 'DMSans',
    },
    divider: {
      width: '100%',
      height: 1,
      opacity: 0.1,
      backgroundColor: colors.white,
    },
    icon_notif: {
      top: 0,
      left: -3,
      position: 'relative',
      backgroundColor: colors.green,
      height: 9,
      width: 9,
      borderRadius: 10,
    },
    ti_style: { marginTop: 0, width: '100%', height: '100%' },
    ti_search: {
      flex: 1,
      borderRadius: 8,
      backgroundColor: 'white',
    },
    ti_border_style: { flex: 1, backgroundColor: colors.white },
    btn_search: {
      height: 38,
      paddingVertical: 12.5,
      paddingHorizontal: 28,
      borderRadius: 8,
    },

    // DRAWER LIST
    drawer_tab_container: {
      flexWrap: 'wrap',
      paddingVertical: 15,
      flexDirection: 'row',
      paddingHorizontal: 15,
      alignItems: 'center',
    },
    drawer_admintab_container: {
      paddingVertical: 5,
      flexDirection: 'row',
      paddingHorizontal: 26,
    },
    activeBG: {
      backgroundColor: colors.offwhite2,
    },
    drawer_tab_label: {
      flex: 1,
      fontSize: 20,
      lineHeight: 24,
      marginLeft: 10,
      color: colors.white,
      fontFamily: 'DMSans-Medium',
    },
    drawer_icons: {
      height: 20,
      width: 20,
      marginRight: 18,
      tintColor: colors.black,
    },
    drawer_mobile_notif: {
      flex: 1,
      flexDirection: 'row',
      // flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: 25,
      paddingHorizontal: 25,
      marginBottom: 32,
    },
    drawer_mobile_textnotif: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 21,
      color: colors.black,
      marginLeft: 12,
      textDecorationLine: 'underline',
    },
    drawer_mobile_search_container: {
      flex: 1,
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 26,
      paddingHorizontal: 18,
    },
    drawer_mobile_btn_search: {
      width: '40%',
      height: 41,
      position: 'absolute',
      right: 18,
      top: 8,
    },
    v_margintop: { marginTop: 25 },
    v_height: { height: 20 },

    // COPYRIGHT
    drawer_content_footer: {
      paddingVertical: 20,
    },
    copyRightStyle: {
      fontSize: 13,
      fontFamily: 'Roboto',
      alignSelf: 'center',
      color: colors.white,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_drawer_container: {
        minWidth: 260,
      },
      btn_search: {
        paddingHorizontal: 18,
        borderRadius: 8,
      },
      drawer_tab_label: {
        fontSize: 14,
        lineHeight: 16,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 16,
      },
    },
    [DEVICE_SIZES.MD]: {
      v_drawer_container: {
        minWidth: 230,
      },
      btn_search: {
        paddingVertical: 10,
        paddingHorizontal: 18,
        borderRadius: 8,
      },
      drawer_tab_label: {
        fontSize: 12,
        lineHeight: 14,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 15,
      },
      user_fullname: {
        fontSize: 12,
      },
      edit_profile: {
        fontSize: 9,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.SM]: {
      v_margintop: {
        marginTop: 15,
      },
      v_drawer_container: {
        zIndex: 5,
        width: '100%',
        position: 'absolute',
      },
      v_drawer_translate: {
        height: '100%',
        width: '50%',
        transform: [{ translateX: -500 }],
      },
      drawer_mobile_textnotif: {
        fontSize: 14,
      },
      btn_search: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
      },
      drawer_tab_label: {
        fontSize: 12,
        lineHeight: 14,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 10,
      },
      user_fullname: {
        fontSize: 12,
      },
      edit_profile: {
        fontSize: 9,
        lineHeight: 11,
      },
      linear_style: { backgroundColor: colors.red },
    },
    [DEVICE_SIZES.XS]: {
      v_margintop: {
        marginTop: 15,
      },
      v_drawer_container: {
        zIndex: 5,
        width: '100%',
        position: 'absolute',
      },
      v_drawer_translate: {
        height: '100%',
        width: '70%',
        transform: [{ translateX: -500 }],
      },
      drawer_mobile_textnotif: {
        fontSize: 12,
        lineHeight: 16,
      },
      btn_search: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: 8,
      },
      drawer_tab_label: {
        fontSize: 12,
        lineHeight: 14,
      },
      drawer_icons: {
        height: 12,
        width: 12,
        marginRight: 10,
      },
      user_fullname: {
        fontSize: 12,
      },
      edit_profile: {
        fontSize: 9,
        lineHeight: 11,
      },
      btn_invisible: {
        width: '100%',
      },
    },
  }
);

export default useStyles;
