import React from 'react';
import { useBetService } from '@root/src/store/hooks';
import { HoverContext } from '../hover-provider';
import { Group, Path } from 'react-konva';
import colors from '@assets/colors';
import Token from './token';

const baseStroke = 2;

const Items = (props: any) => {
  const { onPlaceBet } = useBetService();
  const { onSetHover } = React.useContext(HoverContext);

  const { scale, final_width, final_height, textScale, tokenX, tokenY } =
    React.useMemo(() => {
      const widthScale = (props.box_width - baseStroke) / props.baseWidth;
      const heightScale = (props.box_height - baseStroke) / props.baseHeight;
      const twidthScale = props.box_width / 2 / props.textWidth;
      const theightScale = props.box_height / 2 / props.textHeight;
      const sc = Math.min(widthScale, heightScale);
      const ts = Math.min(twidthScale, theightScale);
      const fwidth = props.baseWidth * sc;
      const fheight = props.baseHeight * sc;
      const tX = (fwidth - props.tokenX) / 2;
      const tY = (fheight - props.tokenY) / 2;

      return {
        scale: sc,
        final_width: fwidth,
        final_height: fheight,
        textScale: ts,
        tokenX: tX,
        tokenY: tY,
      };
    }, [props]);

  const onPress = () => {
    onPlaceBet(props.item);
  };

  return (
    <Group
      x={props.x}
      width={final_width}
      height={final_height}
      onTap={onPress}
      onClick={onPress}
      onTouchStart={() => {
        onSetHover(props.item);
      }}
      onTouchMove={() => {
        onSetHover({});
      }}
      onTouchEnd={() => {
        onSetHover({});
      }}
      onMouseEnter={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        onSetHover(props.item);
      }}
      onMouseLeave={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        onSetHover({});
      }}
    >
      <Path
        data={props.path}
        strokeWidth={baseStroke}
        fill={colors.black4}
        stroke={colors.brown2}
        scaleX={scale}
        scaleY={scale}
      />
      {props.textPath && (
        <Path
          data={props.textPath}
          fill={colors.white}
          x={props?.textX ?? 0}
          y={props?.textY ?? 0}
          scaleX={textScale}
          scaleY={textScale}
        />
      )}
      <Token x={tokenX} y={tokenY} cell={props.item.output} />
    </Group>
  );
};

export default Items;
