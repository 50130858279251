import React, { useState, useEffect } from 'react';
import { Image, Text, View } from 'react-native';
import { useAuthService } from '@src/store/hooks';
import { useTranslation } from 'react-i18next';
import Input from './input';
import useStyles from './styles.css';
import Submit from './submit';
import _ from 'lodash';
import images from '@assets/images';
import ErrorView from './error';

interface ErrorProps {
  username: string;
  password: string;
}

const Login = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { onSignin } = useAuthService();
  const errorRef = React.useRef<any>();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<ErrorProps>({} as ErrorProps);

  const onSubmit = () => {
    let err = {} as ErrorProps;
    if (!username) {
      err.username = 'Username is required';
    }
    if (!password) {
      err.password = 'Password is required';
    }

    setError(err);

    if (_.isEmpty(err)) {
      onSignin({ username, password });
    }
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [username, password]);

  return (
    <View style={styles.v_login}>
      <Image
        style={styles.i_mask1}
        source={images.mask_bg1}
        resizeMode="contain"
      />
      <Text style={styles.t_login}>Login</Text>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <Input
          value={username}
          error={error.username}
          label={`${t('login.uname')}`}
          onChangeText={setUsername}
        />
        <Input
          hasPassword
          value={password}
          error={error.password}
          label={`${t('login.pword')}`}
          onChangeText={setPassword}
        />
        <Text style={styles.v_forgotpassword}>
          Lost password? <Text style={styles.t_recover}>Recover it!</Text>
        </Text>
        <Submit onPress={onSubmit} />
        <View style={styles.mt20} />
      </View>
      <Text style={styles.t_support}>{t('login.powerby')}</Text>
      <Image
        style={styles.i_mask2}
        source={images.mask_bg2}
        resizeMode="contain"
      />
    </View>
  );
};

export default Login;
