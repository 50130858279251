import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { languageActions, selectedLangauge } from '../slices/language.slice';
import { useTranslation } from 'react-i18next';

export type LangServiceOperators = {
  language: string;
  updateLangugage: (payload: string) => void;
};

export const useLanguageService = (): Readonly<LangServiceOperators> => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  return {
    language: useAppSelector(selectedLangauge),
    updateLangugage: useCallback(
      (payload: any) => {
        i18n.changeLanguage(payload.id);
        dispatch(languageActions.setLanguage(payload.name));
      },
      [dispatch]
    ),
  };
};

export default useLanguageService;
