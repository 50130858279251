import React, { useEffect, useRef, useState } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { useLanguageService } from '@src/store/hooks';
import Icon from '@expo/vector-icons/MaterialIcons';
import { Language } from '@src/utils/collection';
import MenuDropdown from '@src/components/menu';
import { useTranslation } from 'react-i18next';
import { colors, images } from 'theme';
import useStyles from './styles.css';
import screenSize from '@src/utils/screensize-helper';

const Header = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const btnRef = useRef<any>(null);
  const { isMobile } = screenSize();
  const [isFocus, setFocus] = useState(false);
  const [measure, setMesasure] = useState({});
  const { language, updateLangugage } = useLanguageService();

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current?.measure(
        (
          fx: any,
          fy: any,
          width: number,
          height: any,
          px: number,
          py: number
        ) => {
          setMesasure({
            x: px,
            y: py,
            w: width,
            h: height,
          });
        }
      );
    }
  }, [btnRef.current, isFocus]);

  if (isMobile) {
    return (
      <View style={styles.v_logo}>
        <Image
          style={styles.i_logo}
          source={images.ctg_logo}
          resizeMode="contain"
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Image
        style={styles.i_logo}
        resizeMode="contain"
        source={images.logo_header}
      />
      <View style={styles.v_languange}>
        <Text style={styles.t_choose_lang}>{t('login.choose')}</Text>
        <TouchableOpacity
          ref={btnRef}
          activeOpacity={1}
          style={styles.v_selector}
          onPress={() => setFocus(true)}
        >
          <Text style={styles.t_language}>{language}</Text>
          <Icon name="expand-more" size={18} color={colors.blue} />
          <MenuDropdown
            visible={isFocus}
            measures={measure}
            options={Language}
            onSelected={updateLangugage}
            onClose={() => setFocus(false)}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Header;
