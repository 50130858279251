import React from 'react';
import { TouchableOpacity } from 'react-native';
import useStyles from './styles.css';

const HiddenButton = ({ visible, onPress }: any) => {
  const styles = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={1}
      style={styles.v_hidden_button}
    />
  );
};

export default HiddenButton;
