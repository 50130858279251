import React, { useContext, useMemo } from 'react';
import { Item } from '@src/screens/routette/types';
import { useBetService } from '@src/store/hooks';
import { BoardContext } from '../provider';
import { Pressable } from 'react-native';
import useStyles from './styles.css';
import ChipToken from './chips';
import { numColumns } from '.';

type ItemProps = {
  item: Item;
  index: number;
};

const Items = ({ item, index }: ItemProps) => {
  const styles = useStyles();
  const { onPlaceBet, onHoverItem } = useBetService();
  const { box_width, box_height } = useContext(BoardContext);
  const sizeStyle = useMemo(() => {
    const row = Math.floor(index / numColumns) + 1;
    const height = row % 2 === 0 ? box_height / 2 : box_height;

    return {
      width: box_width / 2,
      height,
      borderRadius: height,
    };
  }, [index, box_width, box_height]);

  return (
    <Pressable
      style={[styles.v_item_container, sizeStyle]}
      onPress={() => onPlaceBet(item)}
      onPressIn={() => onHoverItem(item)}
      onPressOut={() => onHoverItem({})}
      onHoverIn={() => onHoverItem(item)}
      onHoverOut={() => onHoverItem({})}
    >
      <ChipToken cell={item} BoxSize={box_width} />
    </Pressable>
  );
};

export default Items;
