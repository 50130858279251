import React from 'react';
import { LayerBottomData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../../provider/racetrack';
import { Layer } from 'react-konva';
import Items from './items';

const LayerBottom = () => {
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);

  const value = React.useMemo(() => {
    const pos_x = canvas_width * 0.17837;
    const pos_y = canvas_height * 0.78609;

    return { pos_x, pos_y };
  }, [canvas_width, canvas_height]);

  return (
    <Layer x={value.pos_x} y={value.pos_y}>
      {LayerBottomData.map((item, index) => {
        return <Items key={index.toString()} {...{ item, index }} />;
      })}
    </Layer>
  );
};

export default LayerBottom;
