import React from 'react';
import { ViewStyle, Pressable } from 'react-native';
import Icon from '@expo/vector-icons/MaterialIcons';
import useStyles from './styles.css';
import colors from '@assets/colors';

interface IconButtonProps {
  children?: React.ReactElement;
  style?: ViewStyle;
  name?: keyof typeof Icon.glyphMap;
  color?: string;
  onPress: () => void;
}

const IconButton = ({
  children,
  style,
  name,
  color,
  onPress,
}: IconButtonProps) => {
  const styles = useStyles();
  const [hover, setHover] = React.useState(false);

  return (
    <Pressable
      onPress={onPress}
      style={[styles.btnStyle, style, hover && styles.btn_active]}
      onPressIn={() => setHover(true)}
      onPressOut={() => setHover(false)}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      {children || <Icon name={name} size={20} color={color || colors.white} />}
    </Pressable>
  );
};

export default IconButton;
