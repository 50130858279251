import React from 'react';
import { LayerTopData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../../provider/racetrack';
import { Layer } from 'react-konva';
import Items from './items';

const LayerTop = () => {
  const { canvas_width } = React.useContext(RacetrackContext);
  const LEFT_X = canvas_width * 0.17837;

  return (
    <Layer x={LEFT_X} y={0}>
      {LayerTopData.map((item, index) => {
        return <Items key={index.toString()} {...{ item, index }} />;
      })}
    </Layer>
  );
};

export default LayerTop;
