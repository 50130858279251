import React from 'react';
import { useBetService } from '@root/src/store/hooks';
import { HoverContext } from '../hover-provider';
import { Group, Path } from 'react-konva';
import colors from '@assets/colors';
import Token from './token';

const baseStroke = 2;

const Items = (props: any) => {
  const pathRef = React.useRef<any>();
  const groupRef = React.useRef<any>();
  const textRef = React.useRef<any>();
  const textPath = React.useRef<any>();
  const { onPlaceBet } = useBetService();
  const { onSetHover } = React.useContext(HoverContext);

  const { scale, final_width, final_height, textScale, tokenX, tokenY } =
    React.useMemo(() => {
      const widthScale = (props.box_width - baseStroke) / props.baseWidth;
      const heightScale = (props.box_height - baseStroke) / props.baseHeight;
      const twidthScale = props.box_width / 2 / props.textWidth;
      const theightScale = props.box_height / 2 / props.textHeight;
      const sc = Math.min(widthScale, heightScale);
      const ts = Math.min(twidthScale, theightScale);
      const fwidth = props.baseWidth * sc;
      const fheight = props.baseHeight * sc;
      const tX = (fwidth - props.tokenX) / 2;
      const tY = (fheight - props.tokenY) / 2;

      return {
        scale: sc,
        final_width: fwidth,
        final_height: fheight,
        textScale: ts,
        tokenX: tX,
        tokenY: tY,
      };
    }, [props]);

  const onPress = () => {
    onPlaceBet(props.item);
  };

  React.useEffect(() => {
    groupRef.current?.to({
      x: props.x,
      width: final_width,
      height: final_height,
      duration: 0.4,
    });
    pathRef.current?.to({
      scaleX: scale,
      scaleY: scale,
      duration: 0.4,
    });
    textPath.current?.to({
      x: props?.textX ?? 0,
      y: props?.textY ?? 0,
      scaleX: textScale,
      scaleY: textScale,
      duration: 0.4,
    });
    textRef.current?.to({
      x: props?.textX ?? 0,
      y: props?.textY ?? 0,
      fontSize: props.box_height * 0.15,
      width: props.box_width,
      height: props.label === 'Spiel' ? 0 : props.box_height,
      duration: 0.4,
    });
  }, [scale, final_width, final_height]);

  return (
    <Group
      ref={groupRef}
      onTap={onPress}
      onTouchStart={() => {
        onSetHover(props.item);
      }}
      onTouchMove={() => {
        onSetHover({});
      }}
      onTouchEnd={() => {
        onSetHover({});
      }}
      onMouseEnter={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        onSetHover(props.item);
      }}
      onMouseLeave={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        onSetHover({});
      }}
    >
      <Path
        ref={pathRef}
        data={props.path}
        strokeWidth={baseStroke}
        fill={colors.black4}
        stroke={colors.brown2}
      />
      {props.textPath && (
        <Path ref={textPath} data={props.textPath} fill={colors.white} />
      )}
      <Token x={tokenX} y={tokenY} cell={props.item.output} />
    </Group>
  );
};

export default Items;
