import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    flex1: {
      flex: 1,
      zIndex: 3,
    },
    v_chips: { width: '100%', height: 55, paddingHorizontal: 20 },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
