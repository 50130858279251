import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  userActions,
  selectedAuthToken,
  selectSessionExpired,
  selectUserFailed,
} from '../slices';

// Types
import * as Types from '../types';

export type ServiceOperators = {
  isSessionExpired: boolean;
  failed: Types.Error;
  onFetchUserDetails: () => void;
};

export const useUserService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectedAuthToken);

  return {
    isSessionExpired: useAppSelector(selectSessionExpired),
    failed: useAppSelector(selectUserFailed),
    onFetchUserDetails: useCallback(() => {
      dispatch(userActions.fetchingUserDetails({ token }));
    }, [token, dispatch]),
  };
};

export default useUserService;
