import React, { useState, createContext, useCallback } from 'react';

interface ContextValue {
  isRecetrack: boolean;
  cameraview: boolean;
  onChangeTable: () => void;
  onCameraView: () => void;
}

const RouletteContext = createContext<ContextValue>({} as ContextValue);

interface BoardProviderProps {
  children: React.ReactElement;
}

const RouletteProvider = ({ children }: BoardProviderProps) => {
  const [isRecetrack, setTable] = useState(false);
  const [cameraview, setCameraView] = useState(false);

  const onChangeTable = useCallback(() => {
    setTable(!isRecetrack);
  }, [isRecetrack]);

  const onCameraView = useCallback(() => {
    setCameraView(!cameraview);
  }, [cameraview]);

  const value = {
    isRecetrack,
    cameraview,
    onChangeTable,
    onCameraView,
  };

  return (
    <RouletteContext.Provider value={value}>
      {children}
    </RouletteContext.Provider>
  );
};

export { RouletteProvider, RouletteContext };
