import { combineReducers } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
enableMapSet();

import authSlice from './slices/auth.slice';
import userSlice from './slices/user.slice';
import betSlice from './slices/bet.slice';
import socketlice from './slices/socket.slice';
import rouletteSlice from './slices/roulette.slice';
import languageSlice from './slices/language.slice';
import appstateSlice from './slices/appstate.slice';

const reducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  bet: betSlice,
  socket: socketlice,
  roulette: rouletteSlice,
  language: languageSlice,
  appstate: appstateSlice,
});

export default reducer;
