/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import { selectedOrientation } from '@root/src/store/slices';
import HiddenButton from './chipcoins/HiddenButton';
import { View, StyleSheet } from 'react-native';
import { ChipContext } from './ChipProvider';
import ChipCoins from './chipcoins';
import Settings from './settings';
import Options from './options';
import Middle from './middle';

const ChipScreen = () => {
  const isLandscape = useAppSelector(selectedOrientation);
  const { showChips, onChangeShowChips } = React.useContext(ChipContext);
  const opacity = showChips ? 0 : 1;

  if (isLandscape) {
    return (
      <View style={styles.v_landscape}>
        <Settings />
        <View style={styles.v_flex1}>
          <Middle />
        </View>
        <Options />
      </View>
    );
  }

  return (
    <>
      <HiddenButton visible={showChips} onPress={onChangeShowChips} />
      <View
        pointerEvents="auto"
        style={[styles.v_right_corner, isLandscape && { flexDirection: 'row' }]}
      >
        <View style={[styles.v_flex1, { opacity }]}>
          <Settings />
          <View style={styles.v_flex1}>
            <Middle />
          </View>
          <Options />
        </View>
        <ChipCoins />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    ...StyleSheet.absoluteFillObject,
  },
  v_flex1: { flex: 1 },
  v_right_corner: {
    right: 0,
    zIndex: 10,
    width: '16%',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
  },
  v_landscape: { flexDirection: 'row', width: '100%', height: 60 },
});

export default ChipScreen;
