import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedHoverItem } from '@src/store/slices';
import { useBetService } from '@src/store/hooks';
import ChipToken from '../mask-chip/chips';
import useStyles from './styles.css';
import colors from '@assets/colors';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { TableContext } from '../provider';

const RowItem = ({ item }: any) => {
  const styles = useStyles();
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const screenSize = useContext(TableContext);
  const [selfFocus, setSelFocus] = useState(false);
  const hoverItem = useAppSelector(selectedHoverItem);
  const { onHoverItem, onPlaceBet } = useBetService();

  const onHoverIn = () => {
    onHoverItem(item);
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  React.useEffect(() => {
    boxWidth.value = withTiming(
      screenSize.box_width + screenSize.box_width / 2,
      { duration: 500 }
    );
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const isFocus = React.useMemo(() => {
    if (hoverItem.value && item.value) {
      return hoverItem.value === item.value;
    }

    if (hoverItem.valueSplit) {
      return hoverItem.valueSplit.includes(item.value);
    }

    return false;
  }, [hoverItem, item]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      width: boxWidth.value,
      height: boxHeight.value,
    };
  }, []);

  const circleStyle = useAnimatedStyle(() => {
    const CircleSize = boxHeight.value / 1.5;

    return {
      width: CircleSize,
      height: CircleSize,
      borderRadius: CircleSize / 2,
      backgroundColor: colors.gray1,
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.v_item_container,
        (isFocus || selfFocus) && styles.active_style,
        animateStyle,
      ]}
    >
      <Pressable
        style={[styles.btn_item]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <Animated.View style={circleStyle} />
          <ChipToken cell={item} boxSize={screenSize.box_width} />
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default RowItem;
