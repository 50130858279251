import React from 'react';
import { View, SafeAreaView } from 'react-native';
import { useRouletteService } from '@src/store/hooks';
import WinNumber from '@src/components/popups/win-number';
import ScreenSize from '@src/utils/screensize-helper';
import { DrawerProvider } from './drawer-provider';
import { SocketProvider } from './socket-provider';
import WinRecords from './bottom/win-records';
import MobileScreen from './mobile-screen';
import DrawerComponent from './drawer';
import useStyles from './styles.css';
import WebScreen from './web-screen';
import Header from './header';

const Roulette = () => {
  const styles = useStyles();
  const { isMobile } = ScreenSize();
  const { fetchingTableDetails } = useRouletteService();

  React.useEffect(() => {
    fetchingTableDetails();
  }, []);

  return (
    <SocketProvider>
      <DrawerProvider>
        <View style={styles.container}>
          <View style={styles.container}>
            <Header />
            <View style={styles.flex1}>
              {isMobile ? <MobileScreen /> : <WebScreen />}
              <WinRecords />
            </View>
          </View>
          <WinNumber />
          <DrawerComponent />
          <SafeAreaView />
        </View>
      </DrawerProvider>
    </SocketProvider>
  );
};

export default React.memo(Roulette);
