import React from 'react';
import { useAuthService } from '@src/store/hooks';
import useStyles from '../styles.css';
import { Text } from 'react-native';

const Error = React.forwardRef((_, ref: any) => {
  const styles = useStyles();
  const { failed } = useAuthService();
  const [message, setMessage] = React.useState('');

  React.useImperativeHandle(ref, () => ({
    onClear: () => setMessage(''),
  }));

  React.useEffect(() => {
    if (failed.message) {
      setMessage(failed.message);
    }
  }, [failed.message]);

  if (!message) {
    return null;
  }

  return <Text style={styles.t_error}>{message}</Text>;
});

export default React.memo(Error);
