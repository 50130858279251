import { SetState } from 'immer/dist/internal';
import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

interface ContextValue {
  focus: boolean;
  active: boolean;
  fullscreen: boolean;
  setFocus: Dispatch<SetStateAction<boolean>>;
  setActive: Dispatch<SetStateAction<boolean>>;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  // onChangeFocus: () => void;
  // onChangeActive: () => void;
  // onChangeFScreen: () => void;
}

const VideoContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const VideoProvider = ({ children }: ProviderProps) => {
  const [focus, setFocus] = useState(false);
  const [active, setActive] = useState(true);
  const [fullscreen, setFullScreen] = useState(false);

  const value = {
    focus,
    setFocus,
    active,
    setActive,
    fullscreen,
    setFullScreen,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export { VideoProvider, VideoContext };
