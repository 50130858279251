/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { View, FlatList, Text } from 'react-native';
import { useRouletteService } from '@src/store/hooks';
import useStyles from './styles.css';
import Item from './Item';

const WinRecords = () => {
  const styles = useStyles();
  const { lastNumbers } = useRouletteService();

  return (
    <View style={[styles.container]}>
      <Text style={styles.t_lastnumber}>Last numbers: </Text>
      <View style={styles.v_wrapper}>
        <FlatList
          horizontal
          data={lastNumbers}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(_, index) => index.toString()}
          ItemSeparatorComponent={() => <View style={{ width: 5 }} />}
          renderItem={({ item, index }) => {
            const isActive = index === 0;
            return (
              <Item key={index.toString()} item={item} isActive={isActive} />
            );
          }}
        />
      </View>
    </View>
  );
};

export default WinRecords;
