import { ValueType } from '@src/screens/routette/types';

const FirstColumnData = [
  {
    key: '1-18number1-2-3-4-5-6-7-8-9-10-11-12-13-14-15-16-17-18',
    type: ValueType.NUMBERS_1_18,
    placeholder: '1-18',
    valueSplit: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  },
  {
    key: 'even2-4-6-8-10-12-14-16-18-20-22-24-26-28-30-32-34-36',
    type: ValueType.EVEN,
    placeholder: 'Even',
    valueSplit: [
      2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
    ],
  },
  {
    key: 'red1-3-5-7-9-12-14-16-18-19-21-23-25-27-30-32-34-36',
    type: ValueType.RED,
    valueSplit: [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
    ],
  },
  {
    key: 'black2-4-6-8-10-11-13-15-17-20-22-24-26-28-29-31-33-35',
    type: ValueType.BLACK,
    valueSplit: [
      2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
    ],
  },
  {
    key: 'odd1-3-5-7-9-11-13-15-17-19-21-23-25-27-29-31-33-35',
    type: ValueType.ODD,
    placeholder: 'Odd',
    valueSplit: [
      1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35,
    ],
  },
  {
    key: '19-36number19-20-21-22-23-24-25-26-27-28-29-30-31-32-33-34-35-36',
    type: ValueType.NUMBERS_19_36,
    placeholder: '19-36',
    valueSplit: [
      19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
    ],
  },
];

const SecondColumnData = [
  {
    key: '1st121-2-3-4-5-6-7-8-9-10-11-12',
    type: ValueType.NUMBERS_1_12,
    placeholder: '1st12',
    valueSplit: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  {
    key: '2st1213-14-15-16-17-18-19-20-21-22-23-24',
    type: ValueType.NUMBERS_2_12,
    placeholder: '2st12',
    valueSplit: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  },
  {
    key: '3st1225-26-27-28-29-30-31-32-33-34-35-36',
    type: ValueType.NUMBERS_3_12,
    placeholder: '3st12',
    valueSplit: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  },
];

const ThirdColumnData = [
  {
    key: 'single3',
    type: ValueType.NUMBER,
    value: 3,
  },
  {
    key: 'single6',
    type: ValueType.NUMBER,
    value: 6,
  },
  {
    key: 'single9',
    type: ValueType.NUMBER,
    value: 9,
  },
  {
    key: 'single12',
    type: ValueType.NUMBER,
    value: 12,
  },
  {
    key: 'single15',
    type: ValueType.NUMBER,
    value: 15,
  },
  {
    key: 'single18',
    type: ValueType.NUMBER,
    value: 18,
  },
  {
    key: 'single21',
    type: ValueType.NUMBER,
    value: 21,
  },
  {
    key: 'single24',
    type: ValueType.NUMBER,
    value: 24,
  },
  {
    key: 'single27',
    type: ValueType.NUMBER,
    value: 27,
  },
  {
    key: 'single30',
    type: ValueType.NUMBER,
    value: 30,
  },
  {
    key: 'single33',
    type: ValueType.NUMBER,
    value: 33,
  },
  {
    key: 'single36',
    type: ValueType.NUMBER,
    value: 36,
  },
  {
    key: 'single2',
    type: ValueType.NUMBER,
    value: 2,
  },
  {
    key: 'single5',
    type: ValueType.NUMBER,
    value: 5,
  },
  {
    key: 'single8',
    type: ValueType.NUMBER,
    value: 8,
  },
  {
    key: 'single11',
    type: ValueType.NUMBER,
    value: 11,
  },
  {
    key: 'single14',
    type: ValueType.NUMBER,
    value: 14,
  },
  {
    key: 'single17',
    type: ValueType.NUMBER,
    value: 17,
  },
  {
    key: 'single20',
    type: ValueType.NUMBER,
    value: 20,
  },
  {
    key: 'single23',
    type: ValueType.NUMBER,
    value: 23,
  },
  {
    key: 'single26',
    type: ValueType.NUMBER,
    value: 26,
  },
  {
    key: 'single29',
    type: ValueType.NUMBER,
    value: 29,
  },
  {
    key: 'single32',
    type: ValueType.NUMBER,
    value: 32,
  },
  {
    key: 'single35',
    type: ValueType.NUMBER,
    value: 35,
  },
  {
    key: 'single1',
    type: ValueType.NUMBER,
    value: 1,
  },
  {
    key: 'single4',
    type: ValueType.NUMBER,
    value: 4,
  },
  {
    key: 'single7',
    type: ValueType.NUMBER,
    value: 7,
  },
  {
    key: 'single10',
    type: ValueType.NUMBER,
    value: 10,
  },
  {
    key: 'single13',
    type: ValueType.NUMBER,
    value: 13,
  },
  {
    key: 'single16',
    type: ValueType.NUMBER,
    value: 16,
  },
  {
    key: 'single19',
    type: ValueType.NUMBER,
    value: 19,
  },
  {
    key: 'single22',
    type: ValueType.NUMBER,
    value: 22,
  },
  {
    key: 'single25',
    type: ValueType.NUMBER,
    value: 25,
  },
  {
    key: 'single28',
    type: ValueType.NUMBER,
    value: 28,
  },
  {
    key: 'single31',
    type: ValueType.NUMBER,
    value: 31,
  },
  {
    key: 'single34',
    type: ValueType.NUMBER,
    value: 34,
  },
];

const StreetTwelve = [
  {
    key: '12straight3-6-9-12-15-18-21-24-27-30-33-36',
    type: ValueType.STREET_3_12,
    placeholder: '2to1',
    valueSplit: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
  },
  {
    key: '12straight2-5-8-11-14-17-20-23-26-29-32-35',
    type: ValueType.STREET_2_12,
    placeholder: '2to1',
    valueSplit: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
  },
  {
    key: '12straight1-4-7-10-13-16-19-22-25-28-31-34',
    type: ValueType.STREET_1_12,
    placeholder: '2to1',
    valueSplit: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  },
];

const MaskChipData = [
  {
    key: 'doublesplit0-3',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [0, 3],
  },
  {
    key: 'single3',
    type: ValueType.NUMBER,
    value: 3,
  },
  {
    key: 'doublesplit3-6',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [3, 6],
  },
  {
    key: 'single6',
    type: ValueType.NUMBER,
    value: 6,
  },
  {
    key: 'doublesplit6-9',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [6, 9],
  },
  {
    key: 'single9',
    type: ValueType.NUMBER,
    value: 9,
  },
  {
    key: 'doublesplit9-12',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [9, 12],
  },
  {
    key: 'single12',
    type: ValueType.NUMBER,
    value: 12,
  },
  {
    key: 'doublesplit12-15',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [12, 15],
  },
  {
    key: 'single15',
    type: ValueType.NUMBER,
    value: 15,
  },
  {
    key: 'doublesplit15-18',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [15, 18],
  },
  {
    key: 'single18',
    type: ValueType.NUMBER,
    value: 18,
  },
  {
    key: 'doublesplit18-21',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [18, 21],
  },
  {
    key: 'single21',
    type: ValueType.NUMBER,
    value: 21,
  },
  {
    key: 'doublesplit21-24',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [21, 24],
  },
  {
    key: 'single24',
    type: ValueType.NUMBER,
    value: 24,
  },
  {
    key: 'doublesplit24-27',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [24, 27],
  },
  {
    key: 'single27',
    type: ValueType.NUMBER,
    value: 27,
  },
  {
    key: 'doublesplit27-30',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [27, 30],
  },
  {
    key: 'single30',
    type: ValueType.NUMBER,
    value: 30,
  },
  {
    key: 'doublesplit30-33',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [30, 33],
  },
  {
    key: 'single33',
    type: ValueType.NUMBER,
    value: 33,
  },
  {
    key: 'doublesplit33-36',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [33, 36],
  },
  {
    key: 'single36',
    type: ValueType.NUMBER,
    value: 36,
  },

  {
    key: 'quadsplit0-3-2',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [0, 3, 2],
  },
  {
    key: 'doublesplit3-2',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [3, 2],
  },
  {
    key: 'quadsplit3-6-2-5',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [3, 6, 2, 5],
  },
  {
    key: 'doublesplit6-5',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [6, 5],
  },
  {
    key: 'quadsplit6-9-5-8',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [6, 9, 5, 8],
  },
  {
    key: 'doublesplit9-8',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [9, 8],
  },
  {
    key: 'quadsplit9-12-8-11',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [9, 12, 8, 11],
  },
  {
    key: 'doublesplit12-11',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [12, 11],
  },
  {
    key: 'quadsplit12-15-11-14',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [12, 15, 11, 14],
  },
  {
    key: 'doublesplit15-14',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [15, 14],
  },
  {
    key: 'quadsplit15-18-14-17',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [15, 18, 14, 17],
  },
  {
    key: 'doublesplit18-17',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [18, 17],
  },
  {
    key: 'quadsplit18-21-17-20',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [18, 21, 17, 20],
  },
  {
    key: 'doublesplit21-20',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [21, 20],
  },
  {
    key: 'quadsplit21-24-20-23',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [21, 24, 20, 23],
  },
  {
    key: 'doublesplit24-23',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [24, 23],
  },
  {
    key: 'quadsplit24-27-23-26',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [24, 27, 23, 26],
  },
  {
    key: 'doublesplit27-26',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [27, 26],
  },
  {
    key: 'quadsplit27-30-26-29',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [27, 30, 26, 29],
  },
  {
    key: 'doublesplit30-29',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [30, 29],
  },
  {
    key: 'quadsplit30-33-29-32',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [30, 33, 29, 32],
  },
  {
    key: 'doublesplit33-32',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [33, 32],
  },
  {
    key: 'quadsplit33-36-32-35',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [33, 36, 32, 35],
  },
  {
    key: 'doublesplit36-35',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [36, 35],
  },
  {
    key: 'doublesplit0-2',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [0, 2],
  },
  {
    key: 'single2',
    type: ValueType.NUMBER,
    value: 2,
  },
  {
    key: 'doublesplit2-5',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [2, 5],
  },
  {
    key: 'single5',
    type: ValueType.NUMBER,
    value: 5,
  },
  {
    key: 'doublesplit5-8',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [5, 8],
  },
  {
    key: 'single8',
    type: ValueType.NUMBER,
    value: 8,
  },
  {
    key: 'doublesplit8-11',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [8, 11],
  },
  {
    key: 'single11',
    type: ValueType.NUMBER,
    value: 11,
  },
  {
    key: 'doublesplit11-14',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [11, 14],
  },
  {
    key: 'single14',
    type: ValueType.NUMBER,
    value: 14,
  },
  {
    key: 'doublesplit14-17',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [14, 17],
  },
  {
    key: 'single17',
    type: ValueType.NUMBER,
    value: 17,
  },
  {
    key: 'doublesplit17-20',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [17, 20],
  },
  {
    key: 'single20',
    type: ValueType.NUMBER,
    value: 20,
  },
  {
    key: 'doublesplit20-23',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [20, 23],
  },
  {
    key: 'single23',
    type: ValueType.NUMBER,
    value: 23,
  },
  {
    key: 'doublesplit23-26',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [23, 26],
  },
  {
    key: 'single26',
    type: ValueType.NUMBER,
    value: 26,
  },
  {
    key: 'doublesplit26-29',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [26, 29],
  },
  {
    key: 'single29',
    type: ValueType.NUMBER,
    value: 29,
  },
  {
    key: 'doublesplit29-32',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [29, 32],
  },
  {
    key: 'single32',
    type: ValueType.NUMBER,
    value: 32,
  },
  {
    key: 'doublesplit32-35',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [32, 35],
  },
  {
    key: 'single35',
    type: ValueType.NUMBER,
    value: 35,
  },
  {
    key: 'quadsplit0-2-1',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [0, 2, 1],
  },
  {
    key: 'doublesplit2-1',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [2, 1],
  },
  {
    key: 'quadsplit2-5-1-4',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [2, 5, 1, 4],
  },
  {
    key: 'doublesplit5-4',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [5, 4],
  },
  {
    key: 'quadsplit5-8-4-7',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [5, 8, 4, 7],
  },
  {
    key: 'doublesplit8-7',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [8, 7],
  },
  {
    key: 'quadsplit8-11-7-10',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [8, 11, 7, 10],
  },
  {
    key: 'doublesplit11-10',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [11, 10],
  },
  {
    key: 'quadsplit11-14-10-13',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [11, 14, 10, 13],
  },
  {
    key: 'doublesplit14-13',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [14, 13],
  },
  {
    key: 'quadsplit14-17-13-16',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [14, 17, 13, 16],
  },
  {
    key: 'doublesplit17-16',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [17, 16],
  },
  {
    key: 'quadsplit17-20-16-19',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [17, 20, 16, 19],
  },
  {
    key: 'doublesplit20-19',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [20, 19],
  },
  {
    key: 'quadsplit20-23-19-22',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [20, 23, 19, 22],
  },
  {
    key: 'doublesplit23-22',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [23, 22],
  },
  {
    key: 'quadsplit23-26-22-25',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [23, 26, 22, 25],
  },
  {
    key: 'doublesplit26-25',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [26, 25],
  },
  {
    key: 'quadsplit26-29-25-28',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [26, 29, 25, 28],
  },
  {
    key: 'doublesplit29-28',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [29, 28],
  },
  {
    key: 'quadsplit29-32-28-31',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [29, 32, 28, 31],
  },
  {
    key: 'doublesplit32-31',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [32, 31],
  },
  {
    key: 'quadsplit32-35-31-34',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [32, 35, 31, 34],
  },
  {
    key: 'doublesplit35-34',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [35, 34],
  },
  {
    key: 'doublesplit0-1',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [0, 1],
  },
  {
    key: 'single1',
    type: ValueType.NUMBER,
    value: 1,
  },
  {
    key: 'doublesplit1-4',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [1, 4],
  },
  {
    key: 'single4',
    type: ValueType.NUMBER,
    value: 4,
  },
  {
    key: 'doublesplit4-7',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [4, 7],
  },
  {
    key: 'single7',
    type: ValueType.NUMBER,
    value: 7,
  },
  {
    key: 'doublesplit7-10',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [7, 10],
  },
  {
    key: 'single10',
    type: ValueType.NUMBER,
    value: 10,
  },
  {
    key: 'doublesplit10-13',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [10, 13],
  },
  {
    key: 'single13',
    type: ValueType.NUMBER,
    value: 13,
  },
  {
    key: 'doublesplit13-16',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [13, 16],
  },
  {
    key: 'single16',
    type: ValueType.NUMBER,
    value: 16,
  },
  {
    key: 'doublesplit16-19',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [16, 19],
  },
  {
    key: 'single19',
    type: ValueType.NUMBER,
    value: 19,
  },
  {
    key: 'doublesplit19-22',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [19, 22],
  },
  {
    key: 'single22',
    type: ValueType.NUMBER,
    value: 22,
  },
  {
    key: 'doublesplit22-25',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [22, 25],
  },
  {
    key: 'single25',
    type: ValueType.NUMBER,
    value: 25,
  },
  {
    key: 'doublesplit25-28',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [25, 28],
  },
  {
    key: 'single28',
    type: ValueType.NUMBER,
    value: 28,
  },
  {
    key: 'doublesplit28-31',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [28, 31],
  },
  {
    key: 'single31',
    type: ValueType.NUMBER,
    value: 31,
  },
  {
    key: 'doublesplit31-34',
    type: ValueType.DOUBLE_SPLIT,
    valueSplit: [31, 34],
  },
  {
    key: 'single34',
    type: ValueType.NUMBER,
    value: 34,
  },
  {
    key: 'quadsplit0-3-2-1',
    type: ValueType.QUAD_SPLIT,
    valueSplit: [0, 3, 2, 1],
  },
  {
    key: '3straight3-2-1',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [3, 2, 1],
  },
  {
    key: '6straight3-2-1-6-5-4',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [3, 2, 1, 6, 5, 4],
  },
  {
    key: '3straight6-5-4',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [6, 5, 4],
  },
  {
    key: '6straight6-5-4-9-8-7',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [6, 5, 4, 9, 8, 7],
  },
  {
    key: '3straight9-8-7',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [9, 8, 7],
  },
  {
    key: '6straight9-8-7-12-11-10',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [9, 8, 7, 12, 11, 10],
  },
  {
    key: '3straight12-11-10',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [12, 11, 10],
  },
  {
    key: '6straight12-11-10-13-14-15',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [12, 11, 10, 13, 14, 15],
  },
  {
    key: '3straight15-14-13',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [15, 14, 13],
  },
  {
    key: '6straight13-14-15-16-17-18',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [13, 14, 15, 16, 17, 18],
  },
  {
    key: '3straight18-17-16',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [18, 17, 16],
  },
  {
    key: '6straight16-17-18-19-20-21',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [16, 17, 18, 19, 20, 21],
  },
  {
    key: '3straight21-20-19',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [21, 20, 19],
  },
  {
    key: '6straight19-20-21-22-23-24',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [19, 20, 21, 22, 23, 24],
  },
  {
    key: '3straight24-23-22',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [24, 23, 22],
  },
  {
    key: '6straight22-23-24-25-26-27',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [22, 23, 24, 25, 26, 27],
  },
  {
    key: '3straight27-26-25',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [27, 26, 25],
  },
  {
    key: '6straight25-26-27-28-29-30',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [25, 26, 27, 28, 29, 30],
  },
  {
    key: '3straight30-29-28',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [30, 29, 28],
  },
  {
    key: '6straight28-29-30-31-32-33',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [28, 29, 30, 31, 32, 33],
  },
  {
    key: '3straight33-32-31',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [33, 32, 31],
  },
  {
    key: '6straight31-32-33-34-35-36',
    type: ValueType.NUMBERS_1_6,
    valueSplit: [31, 32, 33, 34, 35, 36],
  },
  {
    key: '3straight36-35-34',
    type: ValueType.NUMBERS_1_3,
    valueSplit: [36, 35, 34],
  },
];

const Black = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];

const Red = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];

const Zero = { key: 'single0', type: ValueType.NUMBER, value: 0 };

export {
  MaskChipData,
  Black,
  Red,
  Zero,
  FirstColumnData,
  SecondColumnData,
  ThirdColumnData,
  StreetTwelve,
};
