import React, { useState, useCallback, createContext } from 'react';
import ErrorCenter from '@root/src/components/popups/error-center';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useAppStateService } from '@root/src/store/hooks';
import Loading from '@root/src/components/loading';

interface ContextValue {
  isDrawerVisible: boolean;
  onDrawerStatus: () => void;
  onShowErrorMessage: (value: string) => void;
}

const DrawerContext = createContext<ContextValue>({} as ContextValue);

interface BoardProviderProps {
  children: React.ReactElement;
}

const DrawerProvider = ({ children }: BoardProviderProps) => {
  const [isDrawerVisible, setDrawerStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const { updateOrientaion } = useAppStateService();
  const [showError, setShowError] = useState<string | null>(null);

  const onDrawerStatus = useCallback(() => {
    setDrawerStatus(!isDrawerVisible);
  }, [isDrawerVisible]);

  const onShowErrorMessage = useCallback(
    (error: string) => {
      setShowError(error);
    },
    [showError]
  );

  const getCurrentOrientation = async () => {
    try {
      const result: any = await ScreenOrientation.getOrientationAsync();

      updateOrientaion(result);
      setLoading(false);
    } catch (e) {}
  };

  React.useEffect(() => {
    // set initial orientation
    ScreenOrientation.getOrientationAsync().then((info: any) => {
      updateOrientaion(info.orientation);
    });

    getCurrentOrientation();

    // subscribe to future changes
    const subscription = ScreenOrientation.addOrientationChangeListener(
      (evt: any) => {
        updateOrientaion(evt.orientationInfo.orientation);
      }
    );

    return () => {
      ScreenOrientation.removeOrientationChangeListener(subscription);
    };
  }, []);

  const value = {
    isDrawerVisible,
    onDrawerStatus,
    onShowErrorMessage,
  };

  return (
    <DrawerContext.Provider value={value}>
      {loading ? <Loading isLoading /> : children}
      <ErrorCenter />
    </DrawerContext.Provider>
  );
};

export { DrawerProvider, DrawerContext };
