import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 2,
      paddingTop: 50,
      alignItems: 'center',
      backgroundColor: colors.translucent2,
      ...StyleSheet.absoluteFillObject,
    },
    v_modal: {
      width: 500,
      minHeight: 60,
      paddingTop: 20,
      borderRadius: 10,
      paddingBottom: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },
    t_expired: {
      fontFamily: 'DMSans-Bold',
      fontSize: 22,
      color: colors.black1,
      textAlign: 'center',
    },
    btn_okay: {
      marginTop: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.blue,
    },
    btn_label: {
      fontSize: 13,
      fontFamily: 'DMSans',
      textAlign: 'center',
      color: colors.white,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        paddingHorizontal: 30,
      },
      v_modal: {
        width: '100%',
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        paddingHorizontal: 30,
      },
      v_modal: {
        width: '100%',
      },
    },
  }
);

export default useStyles;
