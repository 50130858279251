import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },

    v_item_container: { borderWidth: 1, borderColor: 'transparent' },
    btn_item: {
      flex: 1,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.brown2,
    },
    active_style: { borderWidth: 2, borderColor: colors.white },
    radiusBottomLeft: { borderBottomLeftRadius: 7 },
    radiusBottomRight: { borderBottomRightRadius: 7 },

    // ITEM
    v_wrap_text: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    bg_black: { backgroundColor: colors.black4 },
    text: {
      fontSize: 12,
      color: colors.white,
      fontFamily: 'Roboto-Bold',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
