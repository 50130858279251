import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useRouletteService } from '@src/store/hooks';
import colors from '@assets/colors';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

const WinNumber = (props: any) => {
  const animated = useSharedValue(0);
  const { winNumber, showWinNumber } = useRouletteService();

  const onClose = () => {
    animated.value = withTiming(0, { duration: 500 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
    }, 600);
  };

  const animateStyle = useAnimatedStyle(() => {
    const opacity = animated.value;

    return {
      opacity,
    };
  }, [props.message]);

  React.useEffect(() => {
    if (showWinNumber) {
      animated.value = withTiming(1, { duration: 500 });
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        onClose();
      }, 4500);
    }
  }, [showWinNumber]);

  if (!showWinNumber) {
    return null;
  }

  return (
    <View pointerEvents="box-none" style={styles.container}>
      <Animated.View style={[styles.v_modal, animateStyle]}>
        <Text style={styles.t_expired}>{winNumber}</Text>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 3,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'flex-start',
  },
  v_modal: {
    minHeight: 50,
    borderRadius: 8,
    top: '20%',
    paddingHorizontal: 25,
    paddingVertical: 15,
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    backgroundColor: colors.red1,
    borderWidth: 3,
    borderColor: 'gold',
  },
  t_expired: {
    fontFamily: 'DMSans-Medium',
    fontSize: 18,
    color: colors.white,
    textAlign: 'center',
  },
});

export default WinNumber;
