import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnStyle: {
      width: 50,
      height: 50,
      borderWidth: 1,
      marginVertical: 5,
      marginHorizontal: 5,
      borderRadius: 50 / 2,
      alignItems: 'center',
      borderColor: colors.blue,
      justifyContent: 'center',
      backgroundColor: colors.transparent,
    },
    ab_t_x2: { fontFamily: 'Roboto', fontSize: 20, color: colors.white },
    btn_chip: {
      width: 55,
      height: 55,
      marginHorizontal: 5,
      borderRadius: 55 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 5,
      backgroundColor: 'white',
    },
    i_chip: {
      zIndex: 1,
      width: '100%',
      height: '100%',
    },
    t_selected: {
      zIndex: 5,
      fontSize: 25,
      color: colors.white,
      position: 'absolute',
      fontFamily: 'DMSans-Bold',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      btnStyle: {
        width: 40,
        height: 40,
        marginHorizontal: 5,
        borderRadius: 40 / 2,
      },
      btn_chip: {
        width: 45,
        height: 45,
        marginHorizontal: 5,
        borderRadius: 45 / 2,
      },
    },
    [DEVICE_SIZES.SM]: {
      btnStyle: {
        width: 45,
        height: 45,
        borderRadius: 45 / 2,
      },
      btn_chip: {
        width: 50,
        height: 50,
        borderRadius: 50 / 2,
      },
      t_selected: {
        fontSize: 20,
      },
    },
    [DEVICE_SIZES.XS]: {
      btnStyle: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
      },
      btn_chip: {
        width: 45,
        height: 45,
        borderRadius: 45 / 2,
      },
      t_selected: {
        fontSize: 17,
      },
    },
  }
);

export default useStyles;
