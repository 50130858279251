import React from 'react';
import { pickNearest } from '@root/src/utils/mockdata/Racetrack';
import { useBetService } from '@root/src/store/hooks';
import { HoverContext } from '../hover-provider';
import { Rect, Text, Group } from 'react-konva';
import { RacetrackContext } from '../provider';
import { Black } from '../../mockdata/table';
import colors from '@assets/colors';
import Token from '../token';

const Items = ({ item, index }: any) => {
  const rectRef = React.useRef<any>();
  const groupRef = React.useRef<any>();
  const textRef = React.useRef<any>();
  const { onPlaceBet } = useBetService();
  const isBlack = Black.includes(item.value);
  const { level, hover, onSetHover } = React.useContext(HoverContext);
  const { box_width, box_height, spaces } = React.useContext(RacetrackContext);

  const onPress = () => {
    const result = pickNearest(item.key, level);
    onPlaceBet(result);
  };

  const isFocus = React.useMemo(() => {
    if (hover.valueSplit) {
      return hover.valueSplit.includes(item.key);
    }

    return false;
  }, [hover]);

  const token = React.useMemo(() => {
    const tokenSize = box_height / 2;
    const tokenX = (box_width - tokenSize) / 2;
    const tokenY = (box_height - tokenSize) / 2;

    return { tokenSize, tokenX, tokenY };
  }, [box_width, box_height]);

  React.useEffect(() => {
    groupRef.current.to({
      x: index * (box_width + spaces),
      width: box_width,
      height: box_height,
      duration: 0.4,
    });
    rectRef.current.to({
      width: box_width,
      height: box_height,
      duration: 0.4,
    });
    textRef.current.to({
      width: box_width,
      height: box_height,
      fontSize: box_height * 0.34,
      duration: 0.4,
    });
  }, [box_width, box_height]);

  return (
    <Group
      ref={groupRef}
      onTap={onPress}
      onTouchStart={() => {
        onSetHover({ key: item.key });
      }}
      onTouchMove={() => {
        onSetHover({});
      }}
      onTouchEnd={() => {
        onSetHover({});
      }}
      onMouseEnter={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        onSetHover({ key: item.key });
      }}
      onMouseLeave={(e: any) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        onSetHover({});
      }}
    >
      <Rect
        ref={rectRef}
        strokeWidth={1.5}
        fill={isBlack ? colors.black4 : colors.red1}
        stroke={isFocus ? colors.white : colors.brown2}
      />
      <Text
        ref={textRef}
        text={`${item.value}`}
        fill={'white'}
        align="center"
        fontFamily="DMSans-Bold"
        verticalAlign="middle"
      />
      <Token
        x={token.tokenX}
        y={token.tokenY}
        size={token.tokenSize}
        cellkey={item.key}
      />
    </Group>
  );
};

export default Items;
