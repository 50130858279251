import * as Font from 'expo-font';

// fonts preloading
export const fontAssets = [
  {
    Roboto: require('./Roboto.ttf'),
  },
  {
    'Roboto-Light': require('./Roboto-Light.ttf'),
  },
  {
    'Roboto-Medium': require('./Roboto-Medium.ttf'),
  },
  {
    'Roboto-Bold': require('./Roboto-Bold.ttf'),
  },
  {
    DMSans: require('./DMSans.ttf'),
  },
  {
    'DMSans-Medium': require('./DMSans-Medium.ttf'),
  },
  {
    'DMSans-Bold': require('./DMSans-Bold.ttf'),
  },
  {
    Montserrat: require('./Montserrat.ttf'),
  },
  {
    'Montserrat-Medium': require('./Montserrat-Medium.ttf'),
  },
  {
    'Montserrat-Bold': require('./Montserrat-Bold.ttf'),
  },
  {
    Lato: require('./Lato.ttf'),
  },
  {
    'Lato-Bold': require('./Lato-Bold.ttf'),
  },
  {
    Poppins: require('./Poppins.ttf'),
  },
  {
    'Poppins-Medium': require('./Poppins-Medium.ttf'),
  },
  {
    'Poppins-Bold': require('./Poppins-Bold.ttf'),
  },
].map((x: any) => Font.loadAsync(x));
