import React from 'react';
import { LeyerRightData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../provider';
import { Layer } from 'react-konva';
import Items from './items';

const LayerRight = () => {
  const layerRef = React.useRef<any>();
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);
  const value = React.useMemo(() => {
    const lwcanvas = canvas_width * 0.175088;
    const f12_width = canvas_width * 0.06386;
    const f12_height = canvas_height * 0.2342;
    const f35_width = canvas_width * 0.08048;
    const f35_height = canvas_height * 0.25834;
    const f0_width = canvas_width * 0.10071;
    const f0_height = canvas_height * 0.34965;
    const f3_width = canvas_width * 0.08742;
    const f3_height = canvas_height * 0.24285;
    const f26_width = canvas_width * 0.0791;
    const f26_height = canvas_height * 0.17723;

    return {
      lwcanvas,
      f12_width,
      f12_height,
      f35_width,
      f35_height,
      f0_width,
      f0_height,
      f3_width,
      f3_height,
      f26_width,
      f26_height,
    };
  }, [canvas_width, canvas_height]);

  React.useEffect(() => {
    layerRef.current?.to({
      x: canvas_width * 0.82502,
      duration: 0.4,
    });
  }, [canvas_width, canvas_height]);

  return (
    <Layer ref={layerRef}>
      <Items
        {...LeyerRightData[0]}
        textX={-3}
        tokenX={0.09433}
        tokenY={0.24528}
        box_width={value.f12_width}
        box_height={value.f12_height}
      />
      <Items
        {...LeyerRightData[1]}
        x={value.lwcanvas * 0.23482}
        y={canvas_height * 0.03285}
        textX={-1}
        textY={1}
        tokenX={0.20341}
        tokenY={0.34793}
        box_width={value.f35_width}
        box_height={value.f35_height}
      />
      <Items
        {...LeyerRightData[2]}
        x={value.lwcanvas * 0.42483}
        y={canvas_height * 0.14438}
        textY={3}
        tokenX={0.2678}
        tokenY={0.31357}
        box_width={value.f3_width}
        box_height={value.f3_height}
      />
      <Items
        {...LeyerRightData[3]}
        x={value.lwcanvas * 0.54818}
        y={canvas_height * 0.31697}
        textY={5}
        tokenX={0.28338}
        tokenY={0.30345}
        box_width={value.f26_width}
        box_height={value.f26_height}
      />
      <Items
        {...LeyerRightData[4]}
        x={value.lwcanvas * 0.42476}
        y={canvas_height * 0.50596}
        textY={-2}
        tokenX={0.37219}
        tokenY={0.24651}
        box_width={value.f0_width}
        box_height={value.f0_height}
      />
      <Items
        {...LeyerRightData[5]}
        x={value.lwcanvas * 0.23482}
        y={canvas_height * 0.70882}
        textX={-1}
        textY={1}
        tokenX={0.20499}
        tokenY={0.28495}
        box_width={value.f35_width}
        box_height={value.f35_height}
      />
      <Items
        {...LeyerRightData[6]}
        y={canvas_height * 0.76977}
        textX={-3}
        tokenX={0.09433}
        tokenY={0.24528}
        box_width={value.f12_width}
        box_height={value.f12_height}
      />
    </Layer>
  );
};

export default LayerRight;
