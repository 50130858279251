import React from 'react';
import { View } from 'react-native';
import { FourthColumnData } from '@src/utils/mockdata/Table';
import ThirdItem from './RowItem';
import useStyles from './styles.css';

const ThirdRow = () => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      {FourthColumnData.map((item, index) => {
        return <ThirdItem key={index.toString()} item={item} />;
      })}
    </View>
  );
};

export default ThirdRow;
