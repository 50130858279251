import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PersistGate } from 'redux-persist/integration/react';
import { BreakpointsProvider } from 'rn-responsive-styles';
import AppNavigator from './navigators';
import { store, persistor } from './store';
import { imageAssets } from '@assets/images';
import { fontAssets } from '@assets/fonts';
import { Provider } from 'react-redux';
import Splash from '@src/components/loading';
import { StatusBar } from 'expo-status-bar';
import AppProvider from './AppProvider';

const App = () => {
  const [loaded, setLoaded] = React.useState(false);
  const handleLoadAssets = async () => {
    try {
      await Promise.all([...imageAssets, ...fontAssets]);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    handleLoadAssets();
  }, []);

  if (!loaded) {
    return <Splash isLoading />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<Splash isLoading />} persistor={persistor}>
        <BreakpointsProvider breakpoints={[1200, 992, 850, 540]}>
          <AppProvider>
            <SafeAreaProvider>
              <StatusBar style="light" />
              <AppNavigator />
            </SafeAreaProvider>
          </AppProvider>
        </BreakpointsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
