import React from 'react';
import { ViewStyle, Pressable } from 'react-native';
import Icon from '@expo/vector-icons/MaterialIcons';
import useStyles from './styles.css';
import colors from '@assets/colors';

interface IconButtonProps {
  children?: React.ReactElement;
  style?: ViewStyle;
  activeStyle?: ViewStyle;
  name?: keyof typeof Icon.glyphMap;
  color?: string;
  activeColor?: string;
  size?: number;
  onPress: () => void;
}

const IconButton = React.forwardRef((props: IconButtonProps, ref: any) => {
  const styles = useStyles();
  const [hover, setHover] = React.useState(false);
  const activeColor = (hover && props.activeColor) || props.color;

  return (
    <Pressable
      ref={ref}
      onPress={props.onPress}
      style={[
        styles.btnStyle,
        props.style,
        hover && (props.activeStyle || styles.btn_active),
      ]}
      onPressIn={() => setHover(true)}
      onPressOut={() => setHover(false)}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      {props.children || (
        <Icon
          name={props.name}
          size={props.size || 20}
          color={activeColor || colors.white}
        />
      )}
    </Pressable>
  );
});

export default React.memo(IconButton);
