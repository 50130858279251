import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';
export const WINNING_HEIGHT = 25;
export const ADDITIONAL_HEIGHT = 15;
export const TOP_HEIGHT = 50;

const SIZE = 35;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '100%',
      height: TOP_HEIGHT,
      paddingHorizontal: 50,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    v_wrapper: {
      flex: 1,
      height: SIZE,
      marginLeft: 20,
    },
    t_lastnumber: { fontFamily: 'Poppins', fontSize: 16, color: colors.white },

    // ITEMS
    v_item: {
      width: SIZE,
      height: SIZE,
      borderWidth: 1,
      alignItems: 'center',
      borderColor: colors.blue1,
      justifyContent: 'center',
    },
    v_item_active: {
      width: SIZE,
      borderColor: colors.blue,
      backgroundColor: colors.black,
    },
    t_number: {
      fontFamily: 'DMSans',
      color: colors.white,
      fontSize: 12,
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      container: {
        paddingHorizontal: 20,
        height: 40,
      },
      t_lastnumber: {
        fontSize: 14,
      },
      v_wrapper: {
        height: 30,
      },
      v_item: {
        width: 30,
        height: 30,
      },
      v_item_active: {
        width: 30,
      },
    },
    [DEVICE_SIZES.SM]: {
      container: {
        paddingHorizontal: 20,
        height: 40,
      },
      t_lastnumber: {
        fontSize: 14,
      },
      v_wrapper: {
        height: 28,
      },
      v_item: {
        width: 28,
        height: 28,
      },
      v_item_active: {
        width: 28,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        paddingHorizontal: 20,
        height: 40,
      },
      t_lastnumber: {
        fontSize: 14,
      },
      v_wrapper: {
        height: 28,
      },
      v_item: {
        width: 28,
        height: 28,
      },
      v_item_active: {
        width: 28,
      },
    },
  }
);

export default useStyles;
