import React, { useContext } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import { View, Pressable } from 'react-native';
import useStyles from './styles.css';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { ValueType } from '@src/screens/routette/types';
import { selectedHoverItem } from '@src/store/slices';
import { Black } from '@src/utils/mockdata/Table';
import { useBetService } from '@src/store/hooks';
import { TableContext } from '../provider';
import WinningMark from './winning-mark';

const ThirdItem = ({ item }: any) => {
  const styles = useStyles();
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const screenSize = useContext(TableContext);
  const isFirst = item.type === ValueType.STREET_1_12;
  const isLast = item.type === ValueType.STREET_3_12;
  const hoverItem = useAppSelector(selectedHoverItem);
  const { onHoverItem, onPlaceBet } = useBetService();
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem(item);
  };

  const onHoverOut = () => {
    onHoverItem({});
  };
  React.useEffect(() => {
    boxWidth.value = withTiming(
      screenSize.box_width + screenSize.box_width / 2,
      { duration: 500 }
    );
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const isEven = React.useMemo(() => {
    return Black.includes(item.value);
  }, [item]);

  const isFocus = React.useMemo(() => {
    if (hoverItem.value && item.value) {
      return hoverItem.value === item.value;
    }

    if (hoverItem.valueSplit) {
      return hoverItem.valueSplit.includes(item.value);
    }

    return false;
  }, [hoverItem, item]);

  const animateStyle = useAnimatedStyle(() => {
    return {
      width: boxWidth.value,
      height: boxHeight.value,
    };
  }, []);

  const textStyle = useAnimatedStyle(() => {
    return {
      fontSize: 0.55 * boxHeight.value,
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.v_item_container,
        isFocus && styles.active_style,
        isFirst && styles.radiusBottomLeft,
        isLast && styles.radiusBottomRight,
        animateStyle,
      ]}
    >
      <Pressable
        style={[
          styles.btn_item,
          isEven && styles.bg_black,
          isFirst && styles.radiusBottomLeft,
          isLast && styles.radiusBottomRight,
        ]}
        onPress={() => onPlaceBet(item)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View pointerEvents="none" style={styles.v_wrap_text}>
          <Animated.Text
            allowFontScaling
            numberOfLines={1}
            selectable={false}
            accessible={false}
            style={[styles.text, textStyle]}
          >
            {item.placeholder || item.value}
          </Animated.Text>
          {showWinStatus && winNumber === item.value && (
            <WinningMark boxHeight={boxHeight} />
          )}
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default ThirdItem;
