import React, { useState, useCallback } from 'react';
import { Text, View, Pressable, ScrollView } from 'react-native';
import { useAuthService } from '@root/src/store/hooks';
import Icon from '@expo/vector-icons/MaterialIcons';
import useStyles from './styles.css';

const drawerList = [
  {
    name: 'Home',
    icon: 'home',
  },
  {
    name: 'History',
    icon: 'history',
  },
  {
    name: 'Settings',
    icon: 'settings',
  },
  {
    name: 'Logout',
    icon: 'logout',
  },
];

const DrawerList = () => {
  const styles = useStyles();
  const { onLogout } = useAuthService();
  const [hover, setHover] = useState(
    new Array<boolean>(drawerList.length).fill(false)
  );

  const onPress = useCallback((value: string) => {
    switch (value) {
      case 'Logout':
        onLogout();
        break;
    }
  }, []);

  const handleHover = (eventName: string, idx: number) => {
    const isHover = eventName === 'hoverIn';
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  };

  return (
    <ScrollView>
      <View style={styles.v_margintop}>
        {drawerList.map((item: any, index: number) => {
          const isActive = hover[index];
          const activeBG = isActive && styles.activeBG;

          return (
            <Pressable
              key={`tabs${index}`}
              onPress={() => onPress(item.name)}
              onPressIn={() => handleHover('hoverIn', index)}
              onPressOut={() => handleHover('hoverOut', index)}
              onHoverIn={() => handleHover('hoverIn', index)}
              onHoverOut={() => handleHover('hoverOut', index)}
              style={[styles.drawer_tab_container, activeBG]}
            >
              <Icon name={item.icon} size={25} color="white" />
              <Text style={[styles.drawer_tab_label]}>{item.name}</Text>
              <Icon name="chevron-right" size={20} color="white" />
            </Pressable>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default DrawerList;
