import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      flexDirection: 'row',
    },
    landscape_container: {
      flexDirection: 'column',
    },
    v_left: {
      width: '84%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // backgroundColor: 'white',
    },
    v_landscape: {
      flexGrow: 1,
      minHeight: 200,
      marginTop: 10,
      marginBottom: 10,
      alignItems: 'center',
      justifyContent: 'flex-end',
      // backgroundColor: 'white',
    },
    v_right: { width: '16%', height: '100%' },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
