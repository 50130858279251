import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 20,
      top: 10,
      right: 10,
      position: 'absolute',
      alignItems: 'center',
      alignSelf: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    btnStyle: {
      width: 25,
      height: 25,
      marginHorizontal: 3,
      borderRadius: 25 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.black2,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      btnStyle: {
        width: 60,
        height: 60,
        borderRadius: 60 / 2,
      },
    },
    [DEVICE_SIZES.XS]: {
      btnStyle: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
      },
    },
  }
);

export default useStyles;
