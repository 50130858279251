import React from 'react';
import { View } from 'react-native';
import { useAppStateService } from '@root/src/store/hooks';
import { RouletteProvider } from './RouletteProvider';
import VideoPlayer from './videoplayer';
import useStyles from './styles.css';
import ChipBoard from './chipboard';
import Balances from '../bottom';
import Board from './board';
import Top from './top';

const MobileScreen = () => {
  const styles = useStyles();
  const { isLandscape } = useAppStateService();

  return (
    <RouletteProvider>
      <View style={[styles.flex1]}>
        <Top />
        <View style={styles.flex1}>
          <Board />
        </View>
        {isLandscape && (
          <View style={styles.v_chips}>
            <ChipBoard />
          </View>
        )}
        <Balances />
        <VideoPlayer />
      </View>
    </RouletteProvider>
  );
};

export default MobileScreen;
