import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import FirstColumn from './first-column';
import SecondColumn from './second-column';
import ThirdColumn from './third-column';
import ThirdRow from './street-twelve';
import Zero from './zero';

const Board = () => {
  const styles = useStyles();

  return (
    <View style={[styles.container]}>
      <Zero />
      <View style={styles.v_body}>
        <ThirdColumn />
        <SecondColumn />
        <FirstColumn />
      </View>
      <ThirdRow />
    </View>
  );
};

export default Board;
