import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

export const CANVAS_WIDTH = 491.67;
export const CANVAS_HEIGHT = 167.22;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    },
    v_body: {
      justifyContent: 'flex-start',
    },
    zIndex2: { zIndex: 2 },

    // ZERO STYLES
    v_zero: {
      right: 0,
      borderWidth: 1,
      borderTopLeftRadius: 7,
      alignSelf: 'flex-start',
      borderBottomLeftRadius: 7,
      borderColor: 'transparent',
    },
    active_style: { borderWidth: 2, borderColor: colors.white },
    v_zero_center: {
      flex: 1,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 7,
      borderColor: colors.brown2,
      backgroundColor: colors.green,
    },
    v_wrap_text: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    zero_text: {
      fontSize: 12,
      color: colors.white,
      fontFamily: 'Roboto',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
