import React from 'react';
import { LayerLeftData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../provider';
import { Layer } from 'react-konva';
import Items from './items';

const LayerLeft = () => {
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);
  const value = React.useMemo(() => {
    const leftw_canvas = canvas_width * 0.172;
    const spaces = canvas_width * 0.015276;
    const f23_width = canvas_width * 0.07854;
    const f23_height = canvas_width * 0.17298;
    const f8_width = canvas_width * 0.085969;
    const f8_height = canvas_width * 0.24092;
    const f30_width = canvas_width * 0.07907;
    const f30_height = canvas_width * 0.26015;
    const f11_width = canvas_width * 0.06386;
    const f11_height = canvas_width * 0.2342;

    return {
      leftw_canvas,
      spaces,
      f23_width,
      f23_height,
      f8_width,
      f8_height,
      f30_width,
      f30_height,
      f11_width,
      f11_height,
    };
  }, [canvas_width, canvas_height]);

  return (
    <Layer>
      <Items
        {...LayerLeftData[0]}
        x={canvas_width * 0.1095}
        textX={3}
        tokenX={0.27709}
        tokenY={0.22756}
        box_width={value.f11_width}
        box_height={value.f11_height}
      />
      <Items
        {...LayerLeftData[1]}
        x={canvas_width * 0.05011}
        y={canvas_height * 0.03701}
        textX={1}
        tokenX={0.27775}
        tokenY={0.28444}
        box_width={value.f30_width}
        box_height={value.f30_height}
      />
      <Items
        {...LayerLeftData[2]}
        x={canvas_width * 0.0127}
        y={canvas_height * 0.15057}
        textX={1}
        textY={3}
        tokenX={0.25461}
        tokenY={0.31176}
        box_width={value.f8_width}
        box_height={value.f8_height}
      />
      <Items
        {...LayerLeftData[3]}
        y={canvas_height * 0.32107}
        textY={3}
        tokenX={0.18776}
        tokenY={0.27509}
        box_width={value.f23_width}
        box_height={value.f23_height}
      />
      <Items
        {...LayerLeftData[4]}
        y={canvas_height * 0.50596}
        textX={1}
        textY={-2}
        tokenX={0.1909}
        tokenY={0.06881}
        box_width={value.f23_width}
        box_height={value.f23_height}
      />
      <Items
        {...LayerLeftData[5]}
        x={canvas_width * 0.0127}
        y={canvas_height * 0.60853}
        textX={1}
        tokenX={0.26175}
        tokenY={0.20292}
        box_width={value.f8_width}
        box_height={value.f8_height}
      />
      <Items
        {...LayerLeftData[6]}
        x={canvas_width * 0.05011}
        y={canvas_height * 0.70282}
        textX={1}
        tokenX={0.30923}
        tokenY={0.2687}
        box_width={value.f30_width}
        box_height={value.f30_height}
      />
      <Items
        {...LayerLeftData[7]}
        x={canvas_width * 0.1095}
        y={canvas_height * 0.76577}
        textX={3}
        textY={2}
        tokenX={0.2747}
        tokenY={0.26524}
        box_width={value.f11_width}
        box_height={value.f11_height}
      />
    </Layer>
  );
};

export default LayerLeft;
