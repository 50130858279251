import React from 'react';
import { VideoProvider } from './provider';
import Player from './player';

const VideoPlayer = () => {
  return (
    <VideoProvider>
      <Player />
    </VideoProvider>
  );
};

export default VideoPlayer;
