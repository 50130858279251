import React from 'react';
import { View, FlatList } from 'react-native';
import { ThirdColumnData } from '@src/utils/mockdata/Table';
import ThirdItem from './ThirdItem';
import MaskChip from '../mask-chip';

const ThirdColumn = () => {
  return (
    <View>
      <FlatList
        numColumns={3}
        scrollEnabled={false}
        data={ThirdColumnData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => {
          return <ThirdItem key={index.toString()} item={item} />;
        }}
      />
      <MaskChip />
    </View>
  );
};

export default ThirdColumn;
