import React from 'react';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppSelector } from '@src/store/hooks/hooks';
import {
  selectedTotalBet,
  selectSessionID,
  selectTableDetails,
  selectUserBalance,
} from '@src/store/slices';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';

const Balances = () => {
  const styles = useStyles();
  const totalbet = useAppSelector(selectedTotalBet);
  const tableDetails = useAppSelector(selectTableDetails);
  const balance = useAppSelector(selectUserBalance);
  const sessionId = useAppSelector(selectSessionID);

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.v_balance}>
          <MaterialIcons
            name="account-balance-wallet"
            size={12}
            color={colors.white}
          />
          <Text style={styles.t_balance}>{balance} $</Text>
        </View>
        <View style={styles.v_balance}>
          <MaterialCommunityIcons
            name="poker-chip"
            size={12}
            color={colors.white}
          />
          <Text style={styles.t_balance}>{totalbet} $</Text>
        </View>
      </View>
      <View>
        <Text style={[styles.t_balance, styles.t_align_right]}>
          #{sessionId}
        </Text>
        <Text style={[styles.t_balance, styles.t_align_right]}>
          Min. bet: {tableDetails.minBet || 0} $
        </Text>
      </View>
    </View>
  );
};

export default Balances;
