import React, { useContext } from 'react';
import { Item } from '@src/screens/routette/types';
import { useBetService } from '@src/store/hooks';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { TableContext } from '../provider';
import { Pressable } from 'react-native';
import useStyles from './styles.css';
import ChipToken from './chips';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

type ItemProps = {
  item: Item;
  isOdd: boolean;
};
const MaskChipItem = ({ item, isOdd }: ItemProps) => {
  const styles = useStyles();
  const screenSize = useContext(TableContext);
  const { onPlaceBet, onHoverItem } = useBetService();

  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);

  React.useEffect(() => {
    boxWidth.value = withTiming(
      isOdd ? screenSize.box_width : screenSize.box_width / 2,
      { duration: 500 }
    );
    boxHeight.value = withTiming(screenSize.box_height / 2, { duration: 500 });
  }, [screenSize, isOdd]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: boxWidth.value,
      height: boxHeight.value,
      borderRadius: boxHeight.value,
    };
  }, []);

  return (
    <AnimatedPressable
      style={[styles.v_item_container, animatedStyle]}
      onPress={() => onPlaceBet(item)}
      onPressIn={() => onHoverItem(item)}
      onPressOut={() => onHoverItem({})}
      onHoverIn={() => onHoverItem(item)}
      onHoverOut={() => onHoverItem({})}
    >
      <ChipToken cell={item} boxSize={screenSize.box_height} />
    </AnimatedPressable>
  );
};

export default MaskChipItem;
