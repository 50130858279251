import React from 'react';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  Easing,
  withRepeat,
} from 'react-native-reanimated';
import useStyles from './styles.css';

const WinningMark = ({ boxHeight }: any) => {
  const styles = useStyles();
  const animate = useSharedValue(0);

  React.useEffect(() => {
    animate.value = withRepeat(
      withTiming(1, { duration: 600, easing: Easing.linear }),
      -1,
      false
    );
  }, []);

  const scaleStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: animate.value }],
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.win_mark,
        {
          width: boxHeight,
          height: boxHeight,
          borderRadius: boxHeight / 2,
        },
        scaleStyle,
      ]}
    />
  );
};

export default React.memo(WinningMark);
