import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { getTotalBet } from '@root/src/utils/computation-helper';
import {
  betActions,
  selectedBetAmount,
  selectedTotalBet,
} from '../slices/bet.slice';

// Types
import { Item } from '@src/screens/routette/types';
import { selectUserBalance } from '../slices';

export type BetServiceOperators = {
  onBetAmount: (params: number) => void;
  onPlaceBet: (params: Item) => void;
  onDoubleBet: () => void;
  onUndoBet: (params: any) => void;
  onDeleteAllBet: () => void;
  onHoverItem: (params: Item) => void;
};

export const useBetService = (): Readonly<BetServiceOperators> => {
  const dispatch = useAppDispatch();
  const balance = useAppSelector(selectUserBalance);
  const totalbet = useAppSelector(selectedTotalBet);
  const betamount = useAppSelector(selectedBetAmount);

  return {
    onBetAmount: useCallback(
      (params: number) => {
        dispatch(betActions.betAmount(params));
      },
      [dispatch]
    ),
    onPlaceBet: useCallback(
      (item: Item) => {
        if (getTotalBet(item, betamount, totalbet, 100)) {
          dispatch(betActions.onSetError('Insuficient Balance'));
        } else {
          dispatch(betActions.onPlaceBet(item));
        }
      },
      [balance, betamount, totalbet, dispatch, getTotalBet]
    ),
    onDoubleBet: useCallback(() => {
      if (totalbet * 2 > balance) {
        dispatch(betActions.onSetError('Insuficient Balance'));
      } else {
        dispatch(betActions.onDoubleBet());
      }
    }, [dispatch, balance, totalbet]),
    onUndoBet: useCallback(
      (params) => {
        dispatch(betActions.onUndoBet(params));
      },
      [dispatch]
    ),
    onDeleteAllBet: useCallback(() => {
      dispatch(betActions.onDeleteAllBet());
    }, [dispatch]),
    onHoverItem: useCallback(
      (item: Item) => {
        dispatch(betActions.onHoverItem(item));
      },
      [dispatch]
    ),
  };
};

export default useBetService;
