import React from 'react';
import { LayerMiddleData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../provider';
import { Layer } from 'react-konva';
import Items from './items';

const LayerMiddle = () => {
  const layerRef = React.useRef<any>();
  const { box_width } = React.useContext(RacetrackContext);
  const { canvas_width, canvas_height } = React.useContext(RacetrackContext);

  const value = React.useMemo(() => {
    const pos_x = canvas_width * 0.08803;
    const orphelins_width = canvas_width * 0.27032;
    const series58_width = canvas_width * 0.24884;
    const series023_width = canvas_width * 0.28163;
    const spiel_width = canvas_width * 0.07958;
    const box_height = canvas_height * 0.48209;
    const orphelins_x = canvas_width * 0.24955 - pos_x;
    const series023_x = canvas_width * 0.5352 - pos_x;
    const spiel_x = canvas_width * 0.83215 - pos_x;

    return {
      pos_x,
      series58_width,
      orphelins_width,
      series023_width,
      spiel_width,
      box_height,
      orphelins_x,
      series023_x,
      spiel_x,
    };
  }, [canvas_width, canvas_height]);

  React.useEffect(() => {
    layerRef.current.to({
      x: canvas_width * 0.08803,
      y: canvas_height * 0.25895,
      duration: 0.4,
    });
  }, [canvas_width, canvas_height]);

  return (
    <Layer ref={layerRef}>
      <Items
        {...LayerMiddleData[0]}
        x={0}
        textX={value.series58_width * 0.21282}
        textY={value.box_height * 0.27281}
        tokenX={box_width * 2}
        tokenY={box_width}
        box_width={value.series58_width}
        box_height={value.box_height}
      />
      <Items
        {...LayerMiddleData[1]}
        x={value.orphelins_x}
        textX={value.orphelins_width * 0.34175}
        textY={value.box_height * 0.38476}
        tokenX={box_width / 2}
        tokenY={box_width}
        box_width={value.orphelins_width}
        box_height={value.box_height}
      />
      <Items
        {...LayerMiddleData[2]}
        x={value.series023_x}
        textX={value.series023_width * 0.2818}
        textY={value.box_height * 0.27281}
        tokenX={box_width / 2}
        tokenY={box_width}
        box_width={value.series023_width}
        box_height={value.box_height}
      />
      <Items
        {...LayerMiddleData[3]}
        x={value.spiel_x}
        textX={value.spiel_width * 0.1156}
        textY={value.box_height * 0.27925}
        tokenX={box_width}
        tokenY={box_width}
        box_width={value.spiel_width}
        box_height={value.box_height}
      />
    </Layer>
  );
};

export default LayerMiddle;
