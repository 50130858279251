import React from 'react';
import { LayerTopData } from '@root/src/utils/mockdata/Racetrack';
import { RacetrackContext } from '../provider';
import { Layer } from 'react-konva';
import Items from './items';

const LayerTop = () => {
  const layerRef = React.useRef<any>();
  const { canvas_width } = React.useContext(RacetrackContext);

  React.useEffect(() => {
    layerRef.current.to({
      x: canvas_width * 0.17837,
      duration: 0.4,
    });
  }, [canvas_width]);

  return (
    <Layer ref={layerRef}>
      {LayerTopData.map((item, index) => {
        return <Items key={index.toString()} {...{ item, index }} />;
      })}
    </Layer>
  );
};

export default LayerTop;
