import React from 'react';
import { IconButton } from '@root/src/components/button';
import { View, StyleSheet } from 'react-native';
import { HoverContext } from './hover-provider';
import { colors } from '@root/assets';
import { Text } from 'react-native';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import { selectStopBetting } from '@root/src/store/slices';

const SideBar = () => {
  const { level, onChangeLevel } = React.useContext(HoverContext);
  const stopBetting = useAppSelector(selectStopBetting);

  const addLevel = () => {
    if (level < 9) {
      onChangeLevel(level + 1);
    }
  };
  const reduceLevel = () => {
    if (level >= 1) {
      onChangeLevel(level - 1);
    }
  };

  return (
    <View style={[styles.container, stopBetting && styles.disable]}>
      <IconButton
        size={60}
        onPress={addLevel}
        color={colors.blue}
        activeColor={colors.blue1}
        name="arrow-drop-up"
        activeStyle={styles.activeStyle}
      />
      <Text style={styles.t_number}>{level}</Text>
      <IconButton
        size={60}
        onPress={reduceLevel}
        color={colors.blue}
        name="arrow-drop-down"
        activeStyle={styles.activeStyle}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 50,
    height: '70%',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeStyle: { backgroundColor: colors.transparent },
  t_number: {
    fontSize: 28,
    color: colors.white,
    fontFamily: 'DMSans-Bold',
  },
  disable: { opacity: 0 },
});

export default SideBar;
