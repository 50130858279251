import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { isMobile as Mobile } from 'react-device-detect';

const screenSize = () => {
  const devices = useDeviceSize();

  const isMobile =
    devices === DEVICE_SIZES.SM || devices === DEVICE_SIZES.XS || Mobile;
  const isTablet = devices === DEVICE_SIZES.MD;

  return { isMobile, isTablet };
};

export default screenSize;
