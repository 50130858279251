import React from 'react';
import { Pressable, Image, Text } from 'react-native';
import { useAppSelector } from '@src/store/hooks/hooks';
import { ChipImages } from '@src/utils/mockdata/Chips';
import { selectedBetAmount } from '@src/store/slices';
import useStyles from './styles.css';

interface IconButtonProps {
  onPress: () => void;
}

const SelectedChip = ({ onPress }: IconButtonProps) => {
  const styles = useStyles();
  const betAmount = useAppSelector(selectedBetAmount);

  return (
    <Pressable onPress={onPress} style={styles.btn_chip}>
      <Image
        style={styles.i_chip}
        source={ChipImages[betAmount]}
        resizeMode="stretch"
      />
      <Text style={styles.t_selected}>{betAmount}</Text>
    </Pressable>
  );
};

export default SelectedChip;
