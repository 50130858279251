/**
 * Environment variables
 */

type ConfigValues = {
  API_BASE_URL: string;
  API_SOCKET_URL: string;
  API_STREAM_URL: string;
  API_WEBSOCKET_URL: string;
  NODE_ENV: string;
  isProd: () => boolean;
  isDev: () => boolean;
  isTest: () => boolean;
};

const Config = {
  API_BASE_URL: process.env.API_BASE_URL,
  API_SOCKET_URL: process.env.API_SOCKET_URL,
  API_STREAM_URL: process.env.API_STREAM_URL,
  API_WEBSOCKET_URL: process.env.API_WEBSOCKET_URL,
  NODE_ENV: process.env.NODE_ENV,

  isProd() {
    return this.NODE_ENV === 'production';
  },
  isDev() {
    return this.NODE_ENV === 'development';
  },
  isTest() {
    return this.NODE_ENV === 'test';
  },
} as ConfigValues;

export default Config;
