import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  authActions,
  selectAuthLoggingIn,
  selectAuthLogInFailed,
} from '../slices/auth.slice';

// Types
import * as Types from '../types';

export type AuthServiceOperators = {
  isLoading: boolean;
  failed: Types.Error;
  onSignin: (params: Types.LoginInput) => void;
  onLogout: () => void;
};

export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    failed: useAppSelector(selectAuthLogInFailed),
    isLoading: useAppSelector(selectAuthLoggingIn),
    onSignin: useCallback(
      (params: Types.LoginInput) => {
        dispatch(authActions.loginRequest(params));
      },
      [dispatch]
    ),
    onLogout: useCallback(() => {
      dispatch(authActions.logout());
    }, [dispatch]),
  };
};

export default useAuthService;
