import React, { createContext, useMemo } from 'react';
import { multiply, subtract } from '@src/utils/computation-helper';
import { useWindowDimensions } from 'react-native';

const offset = 145;

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  box_width: number;
  box_height: number;
}

export const BoardContext = createContext<ContextValue>({} as ContextValue);

const BoardProvider = ({ children }: ProviderProps) => {
  const { width, height } = useWindowDimensions();
  const value = useMemo(() => {
    const canvas_height = multiply(subtract(height, offset), 0.95);
    const canvas_width = width * 0.8;
    const box_height = canvas_height / 6.5;
    const box_width = canvas_width / 14;

    return { box_width, box_height };
  }, [width, height]);

  return (
    <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
  );
};

export default BoardProvider;
