import React, { useContext } from 'react';
import Svg, { Polygon } from 'react-native-svg';
import { BoardContext } from '../provider';
import colors from '@assets/colors';

const Diamond = ({ color = 'red' }: any) => {
  const { box_height } = useContext(BoardContext);
  const BOX_WIDTH = Math.floor(box_height * 2);
  const BOX_HEIGHT = Math.floor(box_height);

  return (
    <Svg
      width={BOX_WIDTH}
      height={BOX_HEIGHT}
      viewBox={`0 0 ${BOX_WIDTH} ${BOX_HEIGHT}`}
    >
      <Polygon
        fill={color}
        strokeWidth="1"
        stroke={colors.white}
        points={`5,${BOX_HEIGHT / 2} ${BOX_WIDTH / 2},5
        ${BOX_WIDTH - 5},${BOX_HEIGHT / 2} ${BOX_WIDTH / 2},${BOX_HEIGHT - 5}`}
      />
    </Svg>
  );
};

export default Diamond;
