import React from 'react';
import { View, Text } from 'react-native';
import useStyles from './styles.css';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import { selectSessionID, selectUserFullname } from '@root/src/store/slices';

const Welcome = () => {
  const styles = useStyles();
  const sessionId = useAppSelector(selectSessionID);
  const fullname = useAppSelector(selectUserFullname);

  return (
    <View style={styles.container}>
      <View style={styles.v_welcome}>
        <Text style={styles.t_welcom}>Welcome: {fullname}</Text>
        <Text style={styles.t_sessionid}>
          Session ID: <Text style={styles.t_sessionid_color}>{sessionId}</Text>
        </Text>
      </View>
    </View>
  );
};

export default Welcome;
