import React, { useContext } from 'react';
import { View, TouchableOpacity, useWindowDimensions } from 'react-native';
import screenSize from '@src/utils/screensize-helper';
import { TranslateX } from '@src/utils/animation';
import DrawerHeader from './drawer-header';
import CopyRightContent from './versions';
import DrawerList from './drawer-list';
import useStyles from './styles.css';
import { DrawerContext } from '../drawer-provider';

const DrawerComponent = () => {
  const styles = useStyles();
  const { isMobile } = screenSize();
  const { width } = useWindowDimensions();
  const layoutWidth = width * 0.8;
  const { isDrawerVisible, onDrawerStatus } = useContext(DrawerContext);

  if (!isMobile) {
    return null;
  }

  return (
    <View pointerEvents="box-none" style={[styles.v_drawer_container]}>
      {isDrawerVisible && (
        <TouchableOpacity
          onPress={onDrawerStatus}
          activeOpacity={1}
          style={styles.btn_invisible}
        />
      )}

      <TranslateX
        visible={isDrawerVisible}
        outputRange={isMobile ? [width, width - layoutWidth] : [0, 0]}
        style={[styles.v_drawer_translate, { width: layoutWidth }]}
      >
        <View style={styles.linear_style}>
          <DrawerHeader />
          <View style={styles.divider} />
          <View style={[styles.linear_style]}>
            <DrawerList />
          </View>
        </View>
        <CopyRightContent />
      </TranslateX>
    </View>
  );
};

export default DrawerComponent;
