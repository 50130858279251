import * as api from './request';
import * as Types from './types';

export const login = (params: Types.LoginValue) =>
  api.callPost('/token/login', params);

export const userDetails = (token: string) => api.callGet('/user/me', token);

export const userSummary = (token: string | undefined) =>
  api.callGet('/user/me/summary', token);

export const rouletteTableList = (token: string) =>
  api.callGet('/roulette/tables', token);

export const rouletteTableItem = (tableId: string, token: string) =>
  api.callGet('/roulette/tables/' + tableId, token);

export const lastNumbers = (tableId: string, token: string) =>
  api.callGet('/roulette/win-numbers?tableId=' + tableId, token);

export const betSave = (params: any, token: string) =>
  api.callPost('/roulette/bet/save_new', params, token);

export const confirmSave = (params: any, token: string) =>
  api.callPut('/roulette/bet/confirm', params, token);
