import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: '100%',
      marginTop: 50,
      paddingHorizontal: 40,
      justifyContent: 'center',
    },
    t_choose_lang: {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: colors.white,
      marginRight: 12,
    },
    v_selector: {
      height: 45,
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: 25,
      paddingHorizontal: 18,
      borderColor: colors.blue,
      justifyContent: 'space-between',
    },
    t_language: {
      fontFamily: 'Poppins',
      fontSize: 16,
      color: 'white',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        paddingHorizontal: '17%',
      },
    },
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
