import colors from '@assets/colors';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    v_item_container: { borderWidth: 1, borderColor: 'transparent' },
    btn_item: {
      flex: 1,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.brown2,
      backgroundColor: colors.black1,
    },
    active_style: { borderWidth: 2, borderColor: colors.white },

    // ITEM
    v_wrap_text: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontSize: 12,
      color: colors.white,
      fontFamily: 'Roboto',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
