import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery, delay } from 'redux-saga/effects';

// Types
import * as Types from '../types';

// API
import * as API from '@src/utils/api';

// Slice
import { authActions } from '../slices/auth.slice';
import { userActions } from '../slices/user.slice';
import { rouletteActions } from '../slices/roulette.slice';

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(API.login, action.payload);

    yield put(authActions.loginSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || 'Somthing went wrong';

    yield put(authActions.loginFailure({ message }));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleUserDetails(action: {
  type: typeof authActions.loginSuccess;
  payload: Types.Auth;
}): SagaIterator {
  try {
    const userdetails = yield call(API.userDetails, action.payload.token);
    const summary = yield call(API.userSummary, action.payload.token);
    const balance = summary.data[0].playerCredit;

    yield put(
      userActions.fetchUserDetailsSuccess({ ...userdetails.data, balance })
    );
  } catch (error: any) {
    console.log('errror', error);
    const message = error.message || error.error || 'Somthing went wrong';

    if (message.includes('Unauthorized')) {
      yield put(userActions.sessionExpired());
    }

    yield put(userActions.fetchUserDetailsFailed({ message }));
  }
}

function* handleLogout(): SagaIterator {
  yield put(userActions.resetError());
  yield put(rouletteActions.resetError());
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(userActions.fetchingUserDetails.type, handleUserDetails);
  yield takeEvery(authActions.logout.type, handleLogout);
}

export default authWatcherSaga;
