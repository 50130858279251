import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      ...StyleSheet.absoluteFillObject,
      zIndex: 5,
      width: '100%',
      // backgroundColor: 'blue',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_separator: { height: 10 },

    // CHIP ITEM
    btn_token: {
      width: 75,
      height: 75,
      borderRadius: 75 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
    },
    btn_active: {
      shadowColor: colors.blue,
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 5.6,
      shadowRadius: 10,
      elevation: 5,
      borderWidth: 2,
      borderColor: colors.blue,
    },
    v_bet_container: { position: 'absolute', zIndex: 1 },
    t_bet_amount: {
      fontSize: 16,
      color: colors.white,
      fontFamily: 'DMSans-Bold',
    },
    i_chip: { width: '100%', height: '100%' },

    // HIDDEN BUTTON
    v_hidden_button: {
      width: '84%',
      height: '100%',
      backgroundColor: 'transparent',
      position: 'absolute',
      left: 0,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      btn_token: {
        width: 65,
        height: 65,
        borderRadius: 65 / 2,
      },
    },
    [DEVICE_SIZES.XS]: {
      btn_token: {
        width: 50,
        height: 50,
        borderRadius: 50 / 2,
      },
    },
  }
);

export default useStyles;
