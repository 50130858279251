export const getWebSocketMessage = (response: string) => {
  try {
    if (response.toString().includes('ROULETTE')) {
      const msgs = response.split('|');
      console.log('msgs', msgs);

      if (msgs[2] === 'SUCCESS') {
        return JSON.parse(msgs[3]);
      }
    }
    // if (response.toString().includes('Source') === false) {
    //   const msgs = response.split('|');
    //   const ResponeData = JSON.parse(msgs[1]);
    //   const Content = JSON.parse(ResponeData.Content);
    //   const message = JSON.parse(Content.data.message);

    //   return message;
    // }

    return {};
  } catch (e: any) {
    return { error: e.message || e.error };
  }
};
