import React, { useMemo } from 'react';
import { ConvertedChips } from '@src/utils/mockdata/Chips';
import { useAppSelector } from '@src/store/hooks/hooks';
import { selectedChipTotal } from '@src/store/slices';
import { Item } from '@src/screens/routette/types';
import { View, Image, Text } from 'react-native';
import useStyles from './styles.css';

type ChipTokenProps = {
  cell: Item;
  BoxSize: number;
};

const ChipToken = ({ cell, BoxSize }: ChipTokenProps) => {
  const styles = useStyles();
  const chiptotal = useAppSelector(selectedChipTotal);

  const { amount, source } = useMemo(() => {
    const currentItemChips = chiptotal.get(cell.key);
    let computed = 0;

    if (currentItemChips !== undefined) {
      if (currentItemChips.total !== 0) {
        computed = currentItemChips.total;
      }
    }

    const chip_source = ConvertedChips(computed);

    return { amount: computed, source: chip_source };
  }, [chiptotal]);

  if (amount <= 0) {
    return null;
  }

  return (
    <View
      style={[
        styles.v_chip_container,
        {
          width: BoxSize / 2,
          height: BoxSize / 2,
          borderRadius: BoxSize,
        },
      ]}
    >
      <Image style={styles.i_chip} source={source} resizeMode="stretch" />
      <View style={[styles.v_bet_container]}>
        <Text
          selectable={false}
          style={[styles.t_bet_amount, { fontSize: BoxSize * 0.3 }]}
        >
          {amount}
        </Text>
      </View>
    </View>
  );
};

export default ChipToken;
