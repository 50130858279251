import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { Platform } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    label: {
      fontFamily: 'DMSans',
      fontSize: 14,
      fontWeight: '400',
      lineHeight: 14,
      color: colors.white,
    },
    v_label: { flexDirection: 'row', justifyContent: 'space-between' },
    marginTop20: { marginTop: 20 },
    border: {
      marginTop: 8,
      minHeight: 42,
      borderWidth: 1,
      borderRadius: 20,
      justifyContent: 'center',
      borderColor: colors.blue,
    },
    input: {
      flex: 1,
      fontSize: 12,
      fontWeight: '400',
      lineHeight: 14,
      borderRadius: 20,
      fontFamily: 'DMSans',
      color: colors.white,
      paddingHorizontal: 16,
    },
    input2: {
      flex: 1,
      fontFamily: 'Roboto',
      fontSize: 14,
      color: colors.black,
      paddingVertical: 0,
      marginLeft: 3,
    },
    txtError: {
      fontWeight: '400',
      lineHeight: 14,
      marginTop: 5,
      fontSize: 13,
      color: colors.red,
      fontStyle: 'italic',
      fontFamily: 'DMSans-Bold',
    },
    btn_icon: { position: 'absolute', width: 24, height: 24, right: 10 },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
