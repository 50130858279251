import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import Icon from '@expo/vector-icons/MaterialIcons';
import CameraOption from './camera-option';
import { colors } from 'theme';
import { LeftPanelContext } from '../provider';

const SettingsPopup = (props: any) => {
  const { camera, onChangeCameraState } = React.useContext(LeftPanelContext);

  if (!props.visible) {
    return null;
  }

  return (
    <View style={[styles.dropdown]}>
      <View style={styles.v_header}>
        <View style={styles.v_title}>
          <Icon name="settings" color="white" size={20} />
          <Text style={styles.t_title}>Settings</Text>
        </View>
        <Pressable onPress={props.onClose}>
          <Icon name="close" color="white" size={20} />
        </Pressable>
      </View>
      <View style={styles.v_body}>
        <Text style={styles.t_cam_option}>Camera Options</Text>
        <View style={styles.v_cam_option}>
          <CameraOption
            onPress={() => onChangeCameraState(1)}
            label="Camera 1"
            active={camera === 1}
          />
          <CameraOption
            onPress={() => onChangeCameraState(2)}
            label="Camera 2"
            active={camera === 2}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dropdown: {
    top: 35,
    minWidth: 250,
    minHeight: 200,
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: colors.black2,
    justifyContent: 'center',
    shadowColor: 'gray',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.6,
    shadowRadius: 10,
  },
  v_header: {
    height: 45,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 7,
    borderBottomWidth: 0.7,
    borderBottomColor: colors.black,
    justifyContent: 'space-between',
  },
  v_title: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  t_title: {
    fontSize: 12,
    color: 'white',
    marginLeft: 5,
    fontFamily: 'DMSans-Bold',
  },
  v_body: { flex: 1, padding: 10, marginTop: 5 },
  t_cam_option: {
    fontSize: 11,
    color: 'white',
    fontFamily: 'DMSans-Bold',
  },
  v_cam_option: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
  },
});

export default SettingsPopup;
