import React from 'react';
import { View } from 'react-native';
import useStyles from './styles.css';
import FirstColumn from './first-column';
import SecondColumn from './second-column';
import ThirdColumn from './third-column';
import ThirdRow from './street-twelve';
import BoardProvider from './provider';
import Zero from './zero';
import { RouletteContext } from '../../RouletteProvider';

const Board = () => {
  const styles = useStyles();
  const { isRecetrack } = React.useContext(RouletteContext);

  return (
    <BoardProvider>
      <View style={[styles.container, isRecetrack && styles.disable]}>
        <Zero />
        <View style={styles.v_body}>
          <ThirdColumn />
          <SecondColumn />
          <FirstColumn />
        </View>
        <ThirdRow />
      </View>
    </BoardProvider>
  );
};

export default Board;
