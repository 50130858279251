/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Chips from '@src/utils/mockdata/Chips';
import { View, FlatList } from 'react-native';
import useStyles from './styles.css';
import ChipItem from './ChipItem';
import { ChipContext } from '../ChipProvider';

const ChipBoard = () => {
  const styles = useStyles();
  const { showChips } = React.useContext(ChipContext);

  if (!showChips) {
    return null;
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={Chips}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        ItemSeparatorComponent={() => <View style={styles.v_separator} />}
        renderItem={({ item, index }) => {
          return <ChipItem key={index.toString()} item={item} />;
        }}
      />
    </View>
  );
};

export default ChipBoard;
