import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginTop: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnStyle: {
      width: 100,
      height: 45,
      alignItems: 'center',
      justifyContent: 'center',
    },

    btn_active: { backgroundColor: colors.offwhite },
    ab_redo: { borderTopLeftRadius: 50, borderBottomLeftRadius: 50 },
    ab_delete: { borderTopRightRadius: 50, borderBottomRightRadius: 50 },
    ab_t_x2: { fontFamily: 'Roboto', fontSize: 20, color: colors.white },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
