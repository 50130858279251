import React from 'react';
import { View, Text, Image } from 'react-native';
import ActionButton from './action-button';
import useStyles from './styles.css';
import ChipCoins from './chipcoins';
import BetTotal from './bet-total';
import images from '@assets/images';

function BetOption() {
  const styles = useStyles();

  return (
    <View style={styles.bo_container}>
      <Image
        style={styles.i_mask}
        source={images.mask_bg2}
        resizeMode="contain"
      />
      <Text style={styles.bo_t_betoption}>Bet options</Text>
      <ChipCoins />
      <ActionButton />
      <BetTotal />
    </View>
  );
}

export default BetOption;
