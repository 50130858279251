import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    },
    t_failed: {
      fontFamily: 'Roboto',
      position: 'absolute',
      fontSize: 20,
      color: 'white',
      textAlign: 'center',
    },
    loading_style: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
  }
);

export default useStyles;
