import React from 'react';
import { Group, Image, Text } from 'react-konva';
import useImage from 'use-image';

const LocalImage = (props: any) => {
  const [image] = useImage(props.src);

  return (
    <Group x={props.x} y={props.y} width={props.width} height={props.height}>
      <Image width={props.width} height={props.height} image={image} />
      <Text
        text={props.amount}
        fontSize={7}
        fill={'white'}
        align="center"
        width={props.width}
        height={props.height}
        verticalAlign="middle"
        fontFamily="DMSans-Bold"
      />
    </Group>
  );
};

export default LocalImage;
