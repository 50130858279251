/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import * as Flashphoner from '@flashphoner/websdk';
import Loading from '@src/components/loading';
import useStyles from './styles.css';
import Options from './options';
import './style.css';
import { LeftPanelContext, SESSION_STATUS } from './provider';

const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
const cam1 =
  'rtsp://root:nimda123@10.205.144.13/axis-media/media.amp?resolution=1280x720';
const cam2 =
  'rtsp://root:nimda123@10.205.144.12/axis-media/media.amp?resolution=1280x720';

const MainScreen = () => {
  const styles = useStyles();
  const [stream, setStream] = useState<any>(null);
  const [playStatus, setPlayStatus] = useState<any>('');
  const { camera, session, onRefreshStreaming } =
    React.useContext(LeftPanelContext);

  const playStream = () => {
    session
      .createStream({
        name: camera === 1 ? cam1 : cam2,
        display: document.getElementById('remoteVideo'),
        cacheLocalResources: true,
        receiveVideo: true,
        receiveAudio: true,
        flashShowFullScreenButton: true,
        unmutePlayOnStart: false,
      })
      .on(STREAM_STATUS.PENDING, (value: any) => {
        setPlayStatus(STREAM_STATUS.PENDING);
      })
      .on(STREAM_STATUS.PLAYING, (value: any) => {
        setPlayStatus(STREAM_STATUS.PLAYING);
        setStream(value);
      })
      .on(STREAM_STATUS.STOPPED, () => {
        setPlayStatus(STREAM_STATUS.STOPPED);
        setStream(null);
      })
      .on(STREAM_STATUS.FAILED, () => {
        setPlayStatus(STREAM_STATUS.FAILED);
        setStream(null);
      })
      .on(STREAM_STATUS.PAUSED, () => {
        setPlayStatus(STREAM_STATUS.PAUSED);
      })
      .on(STREAM_STATUS.NOT_ENOUGH_BANDWIDTH, () => {
        setPlayStatus(STREAM_STATUS.NOT_ENOUGH_BANDWIDTH);
      })
      .on(STREAM_STATUS.NEW, () => {
        setPlayStatus(STREAM_STATUS.NEW);
      })
      .on(STREAM_STATUS.PLAYBACK_PROBLEM, () => {
        setPlayStatus(STREAM_STATUS.PLAYBACK_PROBLEM);
      })
      .play();
  };

  React.useEffect(() => {
    if (session && session?.status() === SESSION_STATUS.ESTABLISHED) {
      setPlayStatus(STREAM_STATUS.PENDING);
      playStream();
    }
  }, [session]);

  return (
    <View style={styles.container}>
      <Options stream={stream} />
      <div id="remoteVideo" className="display" />
      {playStatus === STREAM_STATUS.FAILED && (
        <Text onPress={onRefreshStreaming} style={styles.t_failed}>
          Connection failed - Retry.
        </Text>
      )}
      <Loading
        color="white"
        style={styles.loading_style}
        isLoading={session === null || playStatus === STREAM_STATUS.PENDING}
      />
    </View>
  );
};

export default React.memo(MainScreen);
