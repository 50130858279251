import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

export const BOTTOM_HEIGHT = 45;
export const COUNTER_HEIGHT = 25;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      bottom: 0,
      width: '100%',
      height: BOTTOM_HEIGHT,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      justifyContent: 'space-between',
      // backgroundColor: 'green',
    },
    v_balance: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    t_balance: {
      marginLeft: 5,
      fontSize: 12,
      lineHeight: 18,
      color: colors.white,
      fontFamily: 'Roboto-Medium',
    },
    t_align_right: { textAlign: 'right' },

    // COUNTER
    v_counter: {
      bottom: 0,
      zIndex: 2,
      width: '100%',
      height: COUNTER_HEIGHT,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      justifyContent: 'space-between',
    },
    t_seconds: {
      fontSize: 12,
      color: colors.white,
      fontFamily: 'Roboto-Medium',
    },
    t_seconds_deactivate: { opacity: 0 },
    t_table_id: {
      fontSize: 10,
      color: colors.white,
      fontFamily: 'Roboto-Light',
    },
    v_placeyourbet: {
      left: 0,
      right: 0,
      alignSelf: 'center',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_placeyourbet: {
      fontSize: 12,
      textAlign: 'center',
      color: colors.white,
      fontFamily: 'Roboto-Medium',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
