import React from 'react';
import { useAppStateService, useRouletteService } from '@src/store/hooks';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useAppSelector } from '@root/src/store/hooks/hooks';
import { selectUserBalance } from '@root/src/store/slices';
import screenSize from '@root/src/utils/screensize-helper';
import Icon from '@expo/vector-icons/MaterialIcons';
import { DrawerContext } from '../drawer-provider';
import useStyles from './styles.css';
import images from '@assets/images';
import Welcome from './welcome';

const Header = () => {
  const styles = useStyles();
  const { isMobile } = screenSize();
  const { isLandscape } = useAppStateService();
  const { tableDetails } = useRouletteService();
  const balance = useAppSelector(selectUserBalance);
  const { onDrawerStatus } = React.useContext(DrawerContext);

  if (isLandscape && isMobile) {
    return null;
  }

  return (
    <SafeAreaView style={styles.container}>
      <Image
        style={styles.i_logo}
        source={images.logo_header}
        resizeMode="contain"
      />
      <View style={styles.v_middle}>
        <View style={styles.v_table_wrapper}>
          <View style={styles.v_table_details}>
            <Text style={styles.t_table_label}>Game: </Text>
            <Text style={styles.t_table_value}>1-1</Text>
          </View>
          <View style={styles.v_table_details}>
            <Text style={styles.t_table_label}>Min-Max bet: </Text>
            <Text style={styles.t_table_value}>
              {tableDetails.minBet}-{tableDetails.maxBet}
            </Text>
          </View>
          <View style={styles.v_table_details}>
            <Text style={styles.t_table_label}>Balance: </Text>
            <Text style={styles.t_table_value}>${balance}</Text>
          </View>
        </View>
        <Welcome />
      </View>
      <TouchableOpacity onPress={onDrawerStatus} style={styles.v_menu}>
        <Icon name="menu" size={30} color="white" />
      </TouchableOpacity>
    </SafeAreaView>
  );
};

export default Header;
