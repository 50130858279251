import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    v_welcome: { alignItems: 'flex-end' },
    t_welcom: {
      fontSize: 18,
      color: colors.white,
      fontFamily: 'Poppins',
    },
    t_sessionid: {
      fontSize: 14,
      color: colors.white,
      fontFamily: 'Poppins',
    },
    t_sessionid_color: {
      color: colors.blue,
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
