const Language = [
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'zh_TW',
    name: '漢語中文',
  },
  {
    id: 'zh_CN',
    name: '简体中文',
  },
];

export default Language;
