import React, { useContext, useState } from 'react';
import { selectWinNumber, selectWinNumberStatus } from '@src/store/slices';
import { useAppSelector } from '@src/store/hooks/hooks';
import { Zero as Cell } from '@src/utils/mockdata/Table';
import { selectedHoverItem } from '@src/store/slices';
import { useBetService } from '@src/store/hooks';
import { View, Pressable } from 'react-native';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import ChipToken from './mask-chip/chips';
import { TableContext } from './provider';
import WinningMark from './third-column/winning-mark';
import useStyles from './styles.css';

const Zero = () => {
  const styles = useStyles();
  const boxWidth = useSharedValue(0);
  const boxHeight = useSharedValue(0);
  const [selfFocus, setSelFocus] = useState(false);
  const screenSize = useContext(TableContext);
  const { onHoverItem, onPlaceBet } = useBetService();
  const hoverItem = useAppSelector(selectedHoverItem);
  const winNumber = useAppSelector(selectWinNumber);
  const showWinStatus = useAppSelector(selectWinNumberStatus);

  const onHoverIn = () => {
    onHoverItem({});
    setSelFocus(true);
  };

  const onHoverOut = () => {
    onHoverItem({});
    setSelFocus(false);
  };

  React.useEffect(() => {
    boxWidth.value = withTiming(
      (screenSize.box_width + screenSize.box_width / 2) * 3,
      { duration: 500 }
    );
    boxHeight.value = withTiming(screenSize.box_height, { duration: 500 });
  }, [screenSize]);

  const isFocus = React.useMemo(() => {
    if (hoverItem.value && Cell.value) {
      return hoverItem.value === Cell.value;
    }

    if (hoverItem.valueSplit) {
      return hoverItem.valueSplit.includes(Cell.value);
    }

    return false;
  }, [hoverItem, Cell]);

  const zeroStyle = useAnimatedStyle(() => {
    const width = boxWidth.value;
    const height = boxHeight.value;

    return {
      width,
      height,
    };
  }, []);

  const textStyle = useAnimatedStyle(() => {
    return {
      fontSize: 0.55 * boxHeight.value,
    };
  }, []);

  return (
    <Animated.View
      style={[
        styles.v_zero,
        zeroStyle,
        (isFocus || selfFocus) && styles.active_style,
      ]}
    >
      <Pressable
        style={[styles.v_zero_center]}
        onPress={() => onPlaceBet(Cell)}
        onPressIn={onHoverIn}
        onPressOut={onHoverOut}
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
      >
        <View style={styles.v_wrap_text}>
          <Animated.Text
            allowFontScaling
            numberOfLines={1}
            selectable={false}
            style={[styles.zero_text, textStyle]}
          >
            0
          </Animated.Text>
          <ChipToken cell={Cell} boxSize={screenSize.box_height} />
          {showWinStatus && winNumber === 0 && (
            <WinningMark boxHeight={boxHeight} />
          )}
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default Zero;
