import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 100,
      position: 'absolute',
    },
    v_item_container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_chip_container: {
      zIndex: 1,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
    },
    i_chip: { width: '100%', height: '100%' },
    v_bet_container: {
      position: 'absolute',
      zIndex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_bet_amount: {
      color: colors.white,
      fontFamily: 'DMSans-Bold',
    },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
