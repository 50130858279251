import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';

type CameraOptionProps = {
  label: string;
  active?: boolean;
  onPress: () => void;
};

const CameraOption = (props: CameraOptionProps) => {
  return (
    <Pressable onPress={props.onPress} style={styles.container}>
      <View style={styles.radio}>
        {props.active && <View style={styles.radio_active} />}
      </View>
      <Text style={styles.t_label}>{props.label}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 40,
    minWidth: 90,
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  radio: {
    width: 17,
    height: 17,
    borderWidth: 1,
    borderRadius: 17 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'white',
  },
  radio_active: {
    width: 13,
    height: 13,
    borderRadius: 13 / 2,
    backgroundColor: 'white',
  },
  t_label: {
    marginLeft: 7,
    fontFamily: 'Roboto',
    fontSize: 11,
    color: 'white',
  },
});

export default CameraOption;
